import { useEffect, useState, forwardRef } from "react";
import { connect } from "react-redux";
import ModalShowLike from "./ModalShowLike";

const URL =
  process.env.REACT_APP_MODE_ENV === "development"
    ? process.env.REACT_APP_URL_SERVER_DEV
    : process.env.REACT_APP_URL_SERVER_PROD;

const ShowReactionLike = forwardRef(({ user, ...props }, ref) => {
  const [ShowModalshowLike,setShowModalshowLike] = useState(false)


  // Vérification si `likeFrom` est bien un tableau
  const reactions = Array.isArray(props.message?.likeFrom) ? props.message.likeFrom : [];

  return (
    <div style={{ position: "absolute", bottom: "-5px", display: "flex", alignItems: "center" }}>
      {reactions.length > 0 ? (
        Object.entries(
          reactions.reduce((acc, like) => {
            acc[like.reaction] = (acc[like.reaction] || 0) + 1;
            return acc;
          }, {})
        ).map(([reaction, count], index) => (
          <div key={index} style={{ display: "flex", alignItems: "center", marginRight: "5px" }}>
            <img
            onClick={()=>setShowModalshowLike(true)}
              style={{ height: "15px", cursor: "pointer", marginRight: "3px" }}
              src={`${URL}/images/ico/${reaction}.png`}
              alt={reaction}
            />
            <span style={{ fontSize: "12px", color: "#888" }}>{count}</span>
          </div>
        ))
      ) : (
        <span style={{ fontSize: "12px", color: "#888" }}></span>
      )}
       <ModalShowLike ShowModalshowLike={ShowModalshowLike} setShowModalshowLike={setShowModalshowLike} message={props.message} />
    </div>
   
  );
  
});

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ShowReactionLike);
