import React, { useState } from 'react';
import { Space, Layout, Form, Input, Button ,Row , Col} from 'antd';
import Nav from '../components/pages/layout/Nav';
import { contact } from '../endpoints/contact'

const { Header, Footer, Content } = Layout;
const actualPage = 'contact';

const headerStyle = {
    textAlign: 'center',
    color: '#fff',
    height: 64,
    paddingInline: 10,
    lineHeight: '64px',
    backgroundColor: '#7dbcea',
};

const contentStyle = {
    textAlign: 'center',
    minHeight: '76vh',
    lineHeight: '60px',
    color: '#fff',
    backgroundColor: ' rgb(22, 22, 22)',
};

const footerStyle = {
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#7dbcea',

};

const Contact = () => {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [message , setMessage] = useState(null)

    const handleSubmit = (values) => {
        setLoading(true);
        contact(values)
            .then(resp => {
                setLoading(false);
                setMessage("Merci, votre message a bien été envoyé.")
                form.resetFields(); // Réinitialiser les champs après soumission
                console.log('Données du formulaire :', values);
            })
            .catch(error => {
                setLoading(false);
                if (error.response && error.response.status === 429) {
        
                    setMessage("Vous êtes autorisé à envoyer un seul message. Veuillez réessayer plus tard.")
                    console.error("Trop de requêtes, veuillez réessayer plus tard :", error.response.data);
                } else {
                    console.error("Une erreur s'est produite lors de l'envois du formulaire :", error);
                 
                    console.error('Une erreur s\'est produite lors de l\'envoi du formulaire. Veuillez réessayer plus tard.');
                }
            });
    };
    

    return (
        <Space direction="vertical" style={{ width: '100%', height: '100vh', }} size={[0, 48]}>
            <Layout>
                <Header style={headerStyle}>
                    <Nav pageSelected={actualPage} />
                </Header>
                <Content style={contentStyle}>
                <h1>contact</h1>
                <h3>{message}</h3>
                    <Row>
                   
                        <Col span={24} style={{display:'flex', justifyContent:'center'}}>
                            <Form  form={form} onFinish={handleSubmit} layout="vertical" >
                                <Form.Item
                                    label={<span style={{ color: "white" }}>Nom</span>}
                                    name="name"
                                    rules={[{ required: true, message: 'Veuillez entrer votre nom' }]}
                                >
                                    <Input size="large" />
                                </Form.Item>
                                <Form.Item
                                     label={<span style={{ color: "white" }}>Email</span>}
                                    name="email"
                                    rules={[
                                        { required: true, message: 'Veuillez entrer votre adresse e-mail' },
                                        { type: 'email', message: 'Adresse e-mail invalide' },
                                    ]}
                                >
                                    <Input size="large" />
                                </Form.Item>
                                <Form.Item
                                      label={<span style={{ color: "white" }}>Message</span>}
                                    name="message"
                                    rules={[{ required: true, message: 'Veuillez entrer votre message' }]}
                                >
                                    <Input.TextArea rows={4} />
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" loading={loading}>
                                        Envoyer
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Col>
                       
                    </Row>
                </Content>
                <Footer style={footerStyle}>Footer</Footer>
            </Layout>
        </Space>
    );
};


export default Contact
