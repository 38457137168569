import React, { useContext, useState } from 'react';
import { Modal, Button, Typography, Input } from 'antd'; // Import de Input depuis 'antd'
import { connect } from 'react-redux';
import { SocketContext } from '../../../components/tchat/SocketContext';

const { Text } = Typography;

const URL =
  process.env.REACT_APP_MODE_ENV === 'development'
    ? process.env.REACT_APP_URL_SERVER_DEV
    : process.env.REACT_APP_URL_SERVER_PROD;

const ModalsendMessageToUser = ({ setModalSendMessageUser, modalSendMessageUser, userData, ...props }) => {
  const socket = useContext(SocketContext);
  const [message, setMessage] = useState(''); // Utilisation de useState pour gérer le message

  const handleOk = () => {
    setModalSendMessageUser(false);
  };

  const handleCancel = () => {
    setModalSendMessageUser(false);
  };

  const handlerMessage = () => {
    socket.emit("sysop_send_message_user", message,userData.socketId);
  };

  return (
    <Modal
      width={600}
      visible={modalSendMessageUser} // Utilisation de 'visible' au lieu de 'open' pour Modal
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Annuler
        </Button>,
        <Button key="send" type="primary" onClick={handlerMessage}>
          Envoyer
        </Button>,
      ]}
    >
      <Input.TextArea // Utilisation de Input.TextArea pour un champ de texte multiligne
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder={`Écrire un message à ${userData.username}`}
        autoSize={{ minRows: 3, maxRows: 6 }}
      />
    </Modal>
  );
};

const mapStateToProps = ({ user, message }) => ({ user, message });
export default connect(mapStateToProps)(ModalsendMessageToUser);
