import React, { useContext, useEffect, useState } from 'react';
import { Modal, Button, Typography, Table } from 'antd'; // Import de Input depuis 'antd'
import { connect } from 'react-redux';
import { SocketContext } from '../../../components/tchat/SocketContext';
import sendRequest from '../../../components/socket/requestModule';

const { Text } = Typography;

const URL =
    process.env.REACT_APP_MODE_ENV === 'development'
        ? process.env.REACT_APP_URL_SERVER_DEV
        : process.env.REACT_APP_URL_SERVER_PROD;

const ModalsendMessageToUser = ({ setModalUserHistory, modalUserHistory, userData, ...props }) => {
    const socket = useContext(SocketContext);
    const [messages, setMessages] = useState(null); // Utilisation de useState pour gérer le message

    const handleOk = () => {
        setModalUserHistory(false);
    };

    const handleCancel = () => {
        setModalUserHistory(false);
    };

    const handlerMessage = () => {

    };


    useEffect(() => {
        const getUserHistory = async () => {
            if (socket) {
                try {
                    const command = await sendRequest(socket, 'getUserHistory', { userData: userData });
                    const sortedMessages = [...command.data].sort((a, b) => new Date(b.time) - new Date(a.time));

                    setMessages(sortedMessages)
                } catch (error) {
                    console.error('Error sending request:', error);
                }
            }
        };
        getUserHistory();
    }, [socket, userData]);

    // Définir les colonnes pour la table
    const columns = [
       
        {
            title: 'Message',
            dataIndex: 'message',
            key: 'message',
        },
        {
            title: 'Time',
            dataIndex: 'time',
            key: 'time',
            render: (text) => new Date(text).toLocaleString(), // Formater la date
        },
    ];

    return (
        <Modal
            width={600}
            visible={modalUserHistory} // Utilisation de 'visible' au lieu de 'open' pour Modal
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button key="cancel" onClick={handleCancel}>
                    Annuler
                </Button>,
                <Button key="send" type="primary" onClick={handlerMessage}>
                    Envoyer
                </Button>,
            ]}
        >
            <div>
                <h1 style={{textAlign:'center'}}>History from {userData.username} </h1>
                <Table columns={columns} dataSource={messages} rowKey="id" />
            </div>
        </Modal>
    );
};

const mapStateToProps = ({ user, message }) => ({ user, message });
export default connect(mapStateToProps)(ModalsendMessageToUser);