import {  forwardRef } from 'react';
import { Button,Table,Modal} from 'antd';
import { connect } from 'react-redux';
import { adminSetSelectedTemplateColor , saveThemeByAdmin } from '../../../../../endpoints/admin'
const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

const ModalTemplateTheme = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {

    const handleOk = () => {
      
        //proceed with the update
            adminSetSelectedTemplateColor(props.themeColor, user?.token, user?.room).then(data => {
                //action when finish
            }) 
        props.setIsModalTemplateTheme(false)
    };
    const handleCancel = () => {
        props.setIsModalTemplateTheme(false)
        // cancel the update
    };

    const saveThemeByAdminFunction = (themeColor) => {
        saveThemeByAdmin(themeColor, user?.token).then(data => {
          
        }).catch(error => {
            console.error("Erreur lors de la sauvegarde du thème :", error);
        });
    
        // Fermer la modal après l'action
        props.setIsModalTemplateTheme(false);
    };

    return (
        <Modal 
        title={`Veuillez confirmer pour la couleur : ${props.themeColor ? props.themeColor.toUpperCase() : "AUCUNE COULEUR"}`}
        style={{ textAlign: 'center' }} 
        open={props.isModalTemplateTheme} 
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={() => props.setIsModalTemplateTheme(false)}>
            Annuler
          </Button>,
          <Button key="confirm" type="primary" onClick={() => handleOk()}>
            Changer
          </Button>,
              <Button  key="confirm" type="primary" onClick={() => saveThemeByAdminFunction(props.themeColor)}>
             Sauvegarder le template
            </Button>
        ]}
      >
        <p>Cette action remplacera votre template actuel. Êtes-vous sûr de vouloir continuer ?</p>
        <p>Avez-vous bien sauvegardé vos modifications ?</p>
      </Modal>
      
    );
})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ModalTemplateTheme);