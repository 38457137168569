import { axios } from './axios_custom';

export const valide_email = (token) => {
   
        return axios.get(`/valide_email`, {
            headers: {
                token:token
              
            }
        })
            .then(({ data }) => data)
            .catch((error) => {
                console.error("Une erreur s'est produite lors de la récuperation de l\'object image punch :", error);
                throw error;
            });

}