import { Form, Input, Button, message, Space, Typography, Col, Row, Layout } from "antd";
import Nav from '../components/pages/layout/Nav';
import { VideoCameraOutlined } from '@ant-design/icons';
import '../css/price.css'
const { Header, Footer, Content } = Layout;
const actualPage = 'price'

const headerStyle = {
    textAlign: 'center',
    color: '#fff',
    height: 64,
    paddingInline: 10,
    lineHeight: '64px',
    backgroundColor: ' rgb(22, 22, 22)',
};

const contentStyle = {
    textAlign: 'center',
    minHeight: '76vh',
    lineHeight: '80px',
    color: '#fff',
    backgroundColor: ' rgb(22, 22, 22)',
};

const footerStyle = {
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#7dbcea',
};

const Price = () => {

    return (
        <Space direction="vertical" style={{ width: '100%', height: '100vh' }} size={[0, 48]}>
            <Layout>
                <Header style={headerStyle}>
                    <Nav pageSelected={actualPage} />
                </Header>
                <Content style={contentStyle}>
                    <Row>
                        <Col span={24}  >
                            <div className="site-heading">
                                <h2>Prix <span>Plan</span></h2>
                                <h4>Liste de nos forfaits tarifaires</h4>
                            </div>
                        </Col>
                    </Row>

                    <Row className="container" gutter={{ xs: 5, sm: 5, md: 5, lg: 5 }}>
                        <Col xs={24} sm={8} >
                            <div style={{ color: 'black' }} className="pricing-item">
                                <ul>
                                    <li className="icon">
                                        <i className="fas fa-video">
                                            <VideoCameraOutlined />
                                        </i>
                                    </li>
                                    <li className="pricing-header">
                                        <h4>Version d'essai</h4>
                                        <h2>0$</h2>
                                    </li>
                                    <li>Version Gratuite<span data-toggle="tooltip" data-placement="top" title="Available on pro version"><i className="fas fa-info-circle"></i></span></li>
                                    <li style={{ textDecoration: 'line-through' }}>Shoutcast</li>
                                    <li>limite de 4 webcams</li>
                                    <li>3 salons</li>
                                    <li>50 usagés</li>
                                    <li className="footer">
                                        <a className="btn btn-dark border btn-sm" href="https://videochat-html5.ca/register">Essai gratuit</a>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col xs={24} sm={8} >
                            <div style={{ color: 'black' }} className="pricing-item active">
                                <ul>
                                    <li className="icon">
                                        <i className="fas fa-video">
                                            <VideoCameraOutlined />
                                        </i>
                                    </li>
                                    <li className="pricing-header">
                                        <h4>Régulier</h4>
                                        <h2><sup>$</sup>55$ <sub>/ mois</sub></h2>
                                    </li>
                                    <li>Version complete</li>
                                    <li>Shoutcast 256 kbps</li>
                                    <li>webcam illimités<span data-toggle="tooltip" data-placement="top" title="Only for extended licence"><i className="fas fa-info-circle"></i></span></li>
                                    <li>salon illimités</li>
                                    <li>usagés illimités</li>

                                    <li className="footer">
                                        <a className="btn btn-dark border btn-sm" href="https://videochat-html5.ca/register">Essai gratuit</a>
                                    </li>
                                </ul>
                            </div>
                        </Col>

                        <Col xs={24} sm={8} >
                            <div style={{ color: 'black' }} className="pricing-item">
                                <ul>
                                    <li className="icon">
                                        <i className="fas fa-video">
                                            <VideoCameraOutlined />
                                        </i>
                                    </li>
                                    <li className="pricing-header">
                                        <h4>Version d'essai</h4>
                                        <h2>0$</h2>
                                    </li>
                                    <li>Version Gratuite<span data-toggle="tooltip" data-placement="top" title="Available on pro version"><i className="fas fa-info-circle"></i></span></li>
                                    <li style={{ textDecoration: 'line-through' }}>Shoutcast</li>
                                    <li>limite de 4 webcams</li>
                                    <li>3 salons</li>
                                    <li>50 usagés</li>

                                    <li className="footer">
                                        <a className="btn btn-dark border btn-sm" href="https://videochat-html5.ca/register">Essai gratuit</a>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Content>
                <Footer style={footerStyle}>Footer</Footer>
            </Layout>
        </Space>
    );
};

export default Price
