import React, { useState, useEffect, useContext, forwardRef } from 'react';
import { Button, Col, Row, Modal, Input, Form, Upload, Switch, Spin } from 'antd';
import { LockOutlined, UploadOutlined, LoadingOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { updateRoom, getRoomById } from '../../../../endpoints/admin';

const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

const ModifyRoom = forwardRef(({ user, ...props }, ref) => {
    const [form] = Form.useForm();
    const [spiner, setSpiner] = useState(false);
    const [roomData, setRoomData] = useState({});

    useEffect(() => {
        if (props.roomToModify) {
            getRoomById(props.roomToModify.id, user?.token).then(resp => {
                setRoomData(resp.data);
                form.setFieldsValue(resp.data); // Set form fields with retrieved data
            });
        }
    }, [props.roomToModify, form, user?.token]);

    const handleOk = () => {
        props.setRoomToModify('');
        props.setIsModalOpenEditService(false);
    };

    const handleCancel = () => {
        props.setRoomToModify('');
        props.setIsModalOpenEditService(false);
    };

    const onFinish = async (values) => {
        try {
            let formData = new FormData();
            formData.append("roomId", props.roomToModify.id);
            formData.append("title", values.name);
            formData.append("description", values.description);
            formData.append("userMaximum", values.userMaximum);
            formData.append("password", values.password);
            formData.append("active", values.active ? 1 : 0);
            formData.append("show_history_on_connect", values.show_history_on_connect ? 1 : 0);

            setSpiner(true);

            await updateRoom(formData, user?.token, user?.room, user?.socketId);

            setTimeout(() => {
                setSpiner(false);
                handleOk();
            }, 3000);
        } catch (error) {
            console.error("Une erreur s'est produite lors de l'envoi du formulaire :", error);
            setSpiner(false);
        }
    };

    return (
        <Modal title="Modify Room" open={props.isModalOpenEditService} onOk={handleOk} onCancel={handleCancel}>
            <div style={{ width: '100%' }}>
               
               edit service
            </div>
        </Modal>
    );
});

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch });
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ModifyRoom);
