import React, { useState, useEffect, forwardRef } from 'react';
import { Button, Form, Input, Select, message, Switch } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { updateUser, adminGetAllRoleApi } from '../../../../endpoints/admin';

const { Option } = Select;

const ModifierUtilisateur = forwardRef(({ user, ...props }, ref) => {
    const [roles, setRoles] = useState([]);
    const [messageApi, contextHolder] = message.useMessage();
    const [messageContent, setMessageContent] = useState(null); // État pour stocker le message

    const onFinishFailed = (errorInfo) => {
        console.log('Échec:', errorInfo);
    };

    const onFinish = async (values) => {
        const userIdDb = props.currentComponentObj.object.id;
        values.id = userIdDb;
        updateUser(values, user?.token, user?.room, user?.socketId).then(resp => {
            if (resp) {
                props.setCurrentComponentObj({ name: 'user' }) 
                setMessageContent(resp);
            }
        });
    };

    useEffect(() => {
        adminGetAllRoleApi(user?.token, user?.room, user?.socketId).then(data => {
            setRoles(data.role);
        });
    }, []);

    useEffect(() => {
        if (messageContent) {
            messageApi.info(messageContent);
            setMessageContent(null); // Réinitialise après affichage
        }
    }, [messageContent]);

    return (
        <>
            {contextHolder}
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ textAlign: 'start', marginTop: '10px' }}
                initialValues={{
                    token_virtual: props.currentComponentObj.object.token_virtual,
                    username: props.currentComponentObj.object.username,
                    email: props.currentComponentObj.object.email,
                    password: props.currentComponentObj.object.password,
                    role: props.currentComponentObj.object.role,
                    gender: props.currentComponentObj.object.gender,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    className="custom-form-item"
                    label="Nom d'utilisateur"
                    name="username"
                    rules={[{ required: true, message: 'Veuillez entrer votre nom d’utilisateur !' }]}
                >
                    <Input defaultValue={props.currentComponentObj.object.username} />
                </Form.Item>

                <Form.Item
                    className="custom-form-item"
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'Veuillez entrer votre email !' }]}
                >
                    <Input defaultValue={props.currentComponentObj.object.email} />
                </Form.Item>

                <Form.Item
                    className="custom-form-item"
                    label="Mot de passe"
                    name="password"
                    rules={[{ required: true, message: 'Veuillez entrer votre mot de passe !' }]}
                >
                    <Input
                        defaultValue={props.currentComponentObj.object.password}
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Mot de passe"
                    />
                </Form.Item>

                <Form.Item
                    className="custom-form-item"
                    name="role"
                    label="Rôle"
                    rules={[{ required: true, message: 'Veuillez sélectionner un rôle !' }]}
                >
                    <Select placeholder="Sélectionnez votre rôle" defaultValue={props.currentComponentObj.object.role}>
                        {roles && roles.map((i) => (
                            <Option key={i.role} value={i.role}>{i.role}</Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    className="custom-form-item"
                    name="gender"
                    label="Genre"
                    rules={[{ required: true, message: 'Veuillez sélectionner un genre !' }]}
                >
                    <Select placeholder="Sélectionnez votre genre" defaultValue={props.currentComponentObj.object.gender}>
                        <Option key="male" value="male">Homme</Option>
                        <Option key="female" value="female">Femme</Option>
                        <Option key="other" value="other">Autre</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    className="custom-form-item"
                    label="Jeton"
                    name="token_virtual"
                    rules={[{ required: true, message: 'Veuillez saisir le nombre de jetons !' }]}
                >
                    <Input
                        defaultValue={props.currentComponentObj.object.token_virtual}
                        maxLength={6} 
                    />
                </Form.Item>

                <Form.Item
                    className="custom-form-item"
                    name="ban"
                    label="Banni"
                    wrapperCol={{ offset: 0, span: 24 }}
                    labelCol={{ span: 8, offset: 0 }}
                    initialValue={props.currentComponentObj.object.ban !== 0}
                >
                    <Switch defaultChecked={props.currentComponentObj.object.ban !== 0} />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 8, span: 24 }}>
                    <Button type="primary" htmlType="submit">
                        Soumettre
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
});

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ModifierUtilisateur);
