import React, { useState, useEffect, useRef, Fragment, useContext, forwardRef } from 'react';
import { Button, Col, Row, Space, Modal, Input, Select, Avatar, Form, Checkbox, Switch, Table, Upload } from 'antd';
import { MenuUnfoldOutlined, LockOutlined, UploadOutlined } from '@ant-design/icons';
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp } from '../../../../action/action';
import { adminAddBotApi } from '../../../../endpoints/admin'
import ModalShowRespondFromApi from './ModalShowRespondFromApi';
const { Option } = Select;
const { Column, ColumnGroup } = Table;

const AddBot = forwardRef(({ app, testStore, designPage, user, onSearch, ...props }, ref) => {

  const [form] = Form.useForm();
  const [lockButton, setLockButton] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [message, setMessage] = useState(null)
  const [modalRespondApi, setModalRespondApi] = useState(false)

  const handleSelect = (value) => {
    setSearchValue(value);
    onSearch(value); // Appeler la fonction de recherche avec la valeur sélectionnée
  };

  const onFinish = async (values) => {
    /*
    const secondes = values.delais 
    const millisecondes = secondes * 1000; 
    values.delais = millisecondes ;
    */

    adminAddBotApi(values, user?.token, user?.room, user?.socketId, user?.username).then(resp => {
      if (!resp.permission) {
        setMessage(resp.message)
        setModalRespondApi(true)
        props.setCurrentComponentObj({ name: 'ListeBot' })
      }
      else {
        props.setCurrentComponentObj({ name: 'ListeBot' })
        form.resetFields();
      }
    })
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
    Ajouter un bot
      <ModalShowRespondFromApi message={message} modalRespondApi={modalRespondApi} setModalRespondApi={setModalRespondApi} />
      <Form
        form={form}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          textAlign: 'start',
          marginTop: '10px',
        }}

        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          className="custom-form-item"
          label="Nom"
          name="name"
          rules={[
            {
              required: true,
              message: 'Veuillez entrer votre nom!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          className="custom-form-item"
          label="Message"
          name="message"
          rules={[
            {
              required: true,
              message: 'Veuillez entrer votre message!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          className="custom-form-item"
          label="Délai en MS"
          name="delais"
          rules={[
            {
              required: true,
              message: 'Veuillez entrer votre délai en seconde!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        
        <Form.Item
  className="custom-form-item"
  label="Ouvrir une page web"
  name="url"
  rules={[
    {
      required: false,
      message: 'Veuillez entrer une URL HTTPS valide',
    },
    {
      type: 'url',
      pattern: /^https:/,
      message: 'Veuillez entrer une URL HTTPS valide',
    },
  ]}
>
  <Input />
</Form.Item>


        <Form.Item
          className="custom-form-item"
          labelCol={{ span: 8 }}
          wrapperCol={{ offset: 0, span: 16 }}
          name="active"
          label="Actif"
          valuePropName="checked" // Utilisez valuePropName pour définir la propriété utilisée pour la valeur
          initialValue={false}
          
        >
          <Switch />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 24,
          }}
        >
          <Button disabled={lockButton} type="primary" htmlType="submit">
            Ajouter
          </Button>
        </Form.Item>
      </Form>
    </>
  );

})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AddBot);
