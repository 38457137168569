import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Modal, Button, Typography, Image, Divider } from 'antd';
import { connect } from 'react-redux';
import sendRequest from '../../../components/socket/requestModule';
import { SocketContext } from '../../../components/tchat/SocketContext';
import ModalsendMessageFromSysops from './ModalsendMessageFromSysops';
import ModalGetUserHistory from './ModalGetUserHistory';

const { Text } = Typography;

const URL =
  process.env.REACT_APP_MODE_ENV === 'development'
    ? process.env.REACT_APP_URL_SERVER_DEV
    : process.env.REACT_APP_URL_SERVER_PROD;

const UserModalOptions = ({ SetUserModalOptions, user, message, ...props }) => {
  const socket = useContext(SocketContext);
  const [userIsOffLine, setUserOffLine] = useState(false);
  const [kickStatus, setKickStatus] = useState('');
  const [modalSendMessageUser, setModalSendMessageUser] = useState(false)
  const [modalUserHistory, setModalUserHistory] = useState(false)

  const handleOk = () => {
    SetUserModalOptions(false);
  };

  const handleCancel = () => {
    SetUserModalOptions(false);
  };

  useEffect(() => {
    setUserOffLine(false);
    setKickStatus('');
  }, [props.selectedUser]);
  
  const kickHandler = async (id) => {
    try {
      const command = await sendRequest(socket, 'sysop_KickUser', { id });
      console.log('User kicked successfully:', command);
      setUserOffLine(true);
      setKickStatus('User kicked successfully');
      props.removeUserFromListe(id)
    } catch (error) {
      setUserOffLine(true);
      setKickStatus('Error kicking user');
      console.error('Error kicking user:', error);
      props.removeUserFromListe(id)
    }
  };

  const kickAndBanHandler = async (socketid, idDb) => {
    try {
      const command = await sendRequest(socket, 'sysop_KickAndUser', { idDb: idDb });
      setUserOffLine(true);
      setKickStatus('User kicked and Banned successfully');
      props.removeUserFromListe(socketid)
    } catch (error) {
      setUserOffLine(true);
      setKickStatus('Error kicking user');
      console.error('Error kicking user:', error);
      props.removeUserFromListe(socketid)
    }
  };

  return (
    <>
      <Modal
        width={600}   // Définir la largeur et la hauteur du modal
        open={props.userModalOptions}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Row >
          <Col span={8}>
            <Image width={170} src={`${URL}/${props.selectedUser.avatar}`} />
          </Col>

          <Col span={16}>
            <Row style={{ textAlign: 'left' }}>
              <Col span={6}>
                <Text strong >Username: </Text>
              </Col>
              <Col span={18}>
                {props.selectedUser.username}
              </Col>
              <Col span={6}>
                <Text strong >Finger Print: </Text>
              </Col>
              <Col span={18}>
                {props.selectedUser.fingerPrint}
              </Col>

              <Col span={6}>
                <Text strong >Ip : </Text>
              </Col>
              <Col span={18}>
                {props.selectedUser.ip}
              </Col>

              <Col span={6}>
                <Text strong style={{ width: '30%' }}>Email : </Text>
              </Col>
              <Col span={18}>
                {props.selectedUser.email}
              </Col>

              <Col span={6}>
                <Text strong style={{ width: '30%' }}>Gender : </Text>
              </Col>
              <Col span={18}>
                {props.selectedUser.gender}
              </Col>

              <Col span={6}>
                <Text strong style={{ width: '30%' }}>Age : </Text>
              </Col>
              <Col span={18}>
                {props.selectedUser.age}
              </Col>

              <Col span={6}>
                <Text strong style={{ width: '30%' }}>Token : </Text>
              </Col>
              <Col span={18}>
                {props.selectedUser.token_virtual}
              </Col>

              <Col span={6}>
                <Text strong style={{ width: '30%' }}>Role : </Text>
              </Col>
              <Col span={18}>
                {props.selectedUser.role}
              </Col>

            </Row>
          </Col>
          <Divider style={{ borderColor: 'red', borderWidth: '2px' }}>Command</Divider>

          <Row>
            <Col span={24} style={{textAlign:'center'}}>
              <span>{kickStatus}</span>
            </Col>
            <Col span={24}>
              <Button disabled={userIsOffLine} onClick={() => kickHandler(props.selectedUser.socketId)} style={{ width: '40%' }} >Kick</Button>
            </Col>
            <Col span={24}>
              <Button disabled={userIsOffLine} onClick={() => kickAndBanHandler(props.selectedUser.socketId, props.selectedUser.idDb)} style={{ width: '40%' }} >Kick and Ban</Button>
            </Col>
            <Col span={24}>
              <Button disabled={userIsOffLine} onClick={() => setModalSendMessageUser(true)} style={{ width: '40%' }} >Message</Button>
            </Col>
            <Col span={24}>
              <Button onClick={() => setModalUserHistory(true)} style={{ width: '40%' }} >Logs Conversation</Button>
            </Col>
          </Row>
        </Row>
      </Modal>
      <ModalsendMessageFromSysops userData={props.selectedUser} modalSendMessageUser={modalSendMessageUser} setModalSendMessageUser={setModalSendMessageUser} />
      <ModalGetUserHistory userData={props.selectedUser} modalUserHistory={modalUserHistory} setModalUserHistory={setModalUserHistory} />
    </>
  );
};

const mapStateToProps = ({ user, message }) => ({ user, message });
export default connect(mapStateToProps)(UserModalOptions);