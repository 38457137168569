import { connect } from 'react-redux';
import { Badge } from 'antd';
import { useEffect, useState, forwardRef, useRef } from 'react';

const TokenVirtual = forwardRef(({ user }, ref) => {
  const [token, setToken] = useState(user?.token_virtual ?? 0);
  const intervalRef = useRef(null);

  // Mettre à jour le token toutes les minutes
  useEffect(() => {
    if (!user?.virtualTokenPerMinute) return;

    intervalRef.current = setInterval(() => {
      setToken(prevToken => (prevToken ?? 0) + (user.virtualTokenPerMinute ?? 0));
    }, 60000);

    return () => clearInterval(intervalRef.current);
  }, [user?.virtualTokenPerMinute]);

  // Mise à jour du token avec ref si défini
  useEffect(() => {
    if (ref?.token_virtual?.current !== undefined) {
      setToken(ref.token_virtual.current ?? token);
    }
  }, [ref?.token_virtual?.current]);

  // Mise à jour du token avec user.updatetoken si défini
  useEffect(() => {
    if (user?.updatetoken !== undefined) {
      setToken(user.updatetoken);
    }
  }, [user?.updatetoken]);

  return (
    <>
      <span>
        <img src="/gold.png" alt="credit" />
      </span>
      <span>
        <Badge
          overflowCount={999999}
          size="small"
          count={token}
          style={{ color: 'white', borderColor: 'transparent', backgroundColor: 'transparent' }}
        />
      </span>
    </>
  );
});

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(TokenVirtual);
