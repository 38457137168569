import { useState, useContext } from 'react';
import { connect } from 'react-redux';
import { setUserApp } from '../../action/action';
import { SocketContext } from '../tchat/SocketContext';
import {  Button, Typography, Col, Row, Avatar   } from "antd";
import { LockOutlined } from '@ant-design/icons';
import ModalPasswordRoom from './ModalPasswordRoom';
const { Title } = Typography;

const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

const SelectRoom = ({ app, testStore, designPage, user, ...props }) => {
    
    const { Text, Link } = Typography;
    const [selectedRoom, setSelectedRoom] = useState(0);
    const [modal , setModal]  = useState(false)

    const handlerSetRoom = (room) => {
        if (room.password !== '' || undefined || null  ) {
            setModal(true)
            setSelectedRoom(room)
            // pop modal for entering password 
        }
        else {
            props?.dispatch(setUserApp({ InitUserState: 'tchat', selectedRoom: room.id }))
        }
    }

    return (
        <>  
        {modal ? <ModalPasswordRoom room={selectedRoom} modal= {modal} setModal = {setModal}  /> : null}
            <Row style={{ backgroundColor: '#010103' }}>
                <Col span={24}> <Title level={3} style={{ margin: 12, textAlign: 'center', color: ' rgb(248 248 248)' }}>VIDEOCHAT RADIO</Title></Col>
            </Row>
            <div
                style={{
                    height: '80%',
                    display: "flex",
                    justifyContent: "center",
                    alignItems: 'center'
                }}
            >
                <div style={{ margin: '20px', padding: '10px', border: ' 0.5px solid', borderRadius: '16px', backgroundColor: 'black', width: '80%' }}>

                    {Array.isArray(user?.roomInfos) && user?.roomInfos.map((room, index) => {
                        return (
                            <Row key={room.id} style={{ color: 'black', backgroundColor: 'white', padding: '5px', display: 'flex', alignItems: 'center' }}>
                                <Col xs={5} sm={5} lg={3} >
                                    <Avatar style={{ marginLeft: '5px' }} shape="square" src={`${URL}/uploads/${room.logo}`} size={64} />
                                </Col>

                                <Col xs={5} sm={6} lg={4} style={{}}>
                                    <Text strong>{room.title}</Text>
                                </Col>
                                <Col xs={0} sm={0} lg={6} style={{}}>
                                    <Text>{room.description}</Text>
                                </Col>

                                <Col lg={3} xs={4} sm={3} style={{}}>
                                    {room.total} / {room.capacity_max}
                                </Col>

                                {room.password !== '' ?
                                    <Col lg={1} xs={2} sm={2} style={{}}>
                                        <LockOutlined />
                                    </Col>
                                    :
                                    <Col lg={1} xs={2} sm={2} style={{}}>

                                    </Col>
                                }
                                <Col xs={7} style={{}} lg={6}>
                                    <Button onClick={() => handlerSetRoom(room)} style={{ width: "100%" }} type="primary">Entrer</Button>
                                </Col>
                            </Row>
                        );
                    })}
                </div>
            </div>
        </>
    );
}
const mapStateToProps = ({ user }) => ({ user })
export default connect(mapStateToProps)(SelectRoom);
