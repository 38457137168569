
import { useState } from 'react';
import { connect } from 'react-redux';
import { checkPasswordRoom } from '../../endpoints/CheckRoom'
import { setUserApp } from '../../action/action';
import {  Input, Typography,Modal } from "antd";
const { Title } = Typography;

const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

const ModalPasswordRoom = ({ app, testStore, designPage, user, ...props }) => {
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('')

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleSubmit = () => {
        //  setLoading(true);
        const data = { password: password, room: props.room }
        checkPasswordRoom(data).then(resp => {

            if (resp.result === 'true') {

                props?.dispatch(setUserApp({ InitUserState: 'tchat', selectedRoom: props.room.id }))
            }
            else {
                setMessage('Acces refuser!')
            }
        })
    };

    const [confirmLoading, setConfirmLoading] = useState(true);

    const handleCancel = () => {
        props.setModal(false);
    };

    return (
        <>
            <Modal
                title={`Entrez le mot de passe de : ${props.room.title}`}
                open={props.modal}
                onCancel={handleCancel}
                okText='Envoyer'
                onOk={handleSubmit}
            // okButtonProps={{ disabled: !password }}
            //okButtonProps={{ style: { display: 'none' } }}
            >
                <Input.Password
                    placeholder="Enter password"
                    value={password}
                    onChange={handlePasswordChange}
                    style={{ marginBottom: '20px' }}
                />
                <div>
                    {message}
                </div>
            </Modal>

        </>
    );
}
const mapStateToProps = ({ user }) => ({ user })
export default connect(mapStateToProps)(ModalPasswordRoom);
