import { jsPanel } from 'jspanel4/es6module/jspanel';
import 'jspanel4/es6module/extensions/modal/jspanel.modal';
import { SocketContext } from '../../SocketContext';
import 'jspanel4/dist/jspanel.min.css';
import ReactDOM from 'react-dom';
import { Slider, Spin } from 'antd';
import sendRequest from '../../../socket/requestModule'
import { CaretRightOutlined, PauseOutlined } from '@ant-design/icons';
import { useState, useEffect, useRef, Fragment, useContext } from 'react';


const Jspanelwebcam = ({ ...props }) => {

    let id = null
    let audioAnalysers = {}
    let audioContext = useRef(null)
    let audioFftArray = null
    let socket = useContext(SocketContext);
    const [pause, setPause] = useState(false);
    const [loading, setLoading] = useState(false);

    function initializeAudioTools() {

        let audioContextType = (window.AudioContext || window.webkitAudioContext);
        if (audioContextType) {
            audioContext.current = new audioContextType();
            audioFftArray = new Float32Array(256);
            if (audioContext.current) {

                setInterval(watchAudioTracks, 100);
            }
        }
    }

    function createAudioAnalyser(id, stream) {

        if (audioContext.current) {

            let source = audioContext.current.createMediaStreamSource(stream);
            let analyser = audioContext.current.createAnalyser();
            analyser.smoothingTimeConstant = 0.1;
            analyser.fftSize = 512;

            source.connect(analyser);
            //analyser.connect(audioContext.destination); // Makes the context play sound

            audioAnalysers[id] = analyser;
            // console.log('Analyser created id=', id);
        }
    }

    function watchAudioTracks() {
        for (id in audioAnalysers) {
            let analyser = audioAnalysers[id];
            analyser.getFloatFrequencyData(audioFftArray);

            let dBs = -Infinity;
            for (let i = 4, ii = audioFftArray.length; i < ii; i++) {
                if (audioFftArray[i] > dBs && audioFftArray[i] < 0) {
                    dBs = audioFftArray[i];
                }
            };

            /*
            Original formula to convert from dBs to linear scale (0..1):
            Math.pow(10, dBs / 20);
            Runo's version (exaggerated and normalized to ten levels):
            Math.round(Math.pow(10, dBs / 85) * 10) * 10; //Multiples of 10
            Math.ceil(Math.round(Math.pow(10, dBs / 85) * 100) / 5) * 5; //Multiples of 5
            */

            let volume = Math.round(Math.pow(10, dBs / 85) * 10) * 10;
            volume = (volume <= 10 ? 0 : volume); // Truncate 10% to 0%

            //$(`#meter_${id}`).css('height', `${volume}%`);

            var i = document.getElementById(`meter_${id}`)
            if (i) {
                i.style.height = volume + '%';
            }
        }
    }

    const handleClick = (e) => {
        let cl = e.target.closest('span').classList;
        props.removeJspanel(props.cam.id, props.cam.track.kind);
        e.target.removeEventListener('click', handleClick);
    };

    const handlerPause = async () => {
        setLoading(true);
        try {
            if (!pause) {
                await sendRequest(socket, 'pauseProducer',{kind: 'video'});
                // Remplacer l'élément vidéo par une couleur de fond
                const videoElement = document.getElementById(props.cam.id);
                if (videoElement) {
                    videoElement.style.display = 'none';
                    const backgroundDiv = document.createElement('div');
                    backgroundDiv.style.width = '100%';
                    backgroundDiv.style.height = '100%';
                    backgroundDiv.style.backgroundColor = 'black'; // Couleur de fond souhaitée
                    backgroundDiv.id = `background_${props.cam.id}`;
                    videoElement.parentNode.appendChild(backgroundDiv);
                }
            } else {
                await sendRequest(socket, 'resumeProducer', {kind: 'video'});
                // Remettre l'élément vidéo
                const videoElement = document.getElementById(props.cam.id);
                if (videoElement) {
                    videoElement.style.display = 'block';
                    const backgroundDiv = document.getElementById(`background_${props.cam.id}`);
                    if (backgroundDiv) {
                        backgroundDiv.remove();
                    }
                }
            }
            setPause(!pause);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 3000);
            
        }
    };



    const [disabled, setDisabled] = useState(false);


    const LocalProducerButton = () => {

        return (
            <>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    position: 'absolute',
                    bottom: '0px',
                    backgroundColor: 'black'
                }}>
                    <div style={{
                        height: '25px',
                        width: '25px',
                        marginLeft: '5px',
                        backgroundColor: 'black',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        {loading ? <Spin /> :
                            <div style={{ height: '15px' }} onClick={() => handlerPause()}>
                                {
                                    pause ? <PauseOutlined style={{
                                        color: 'white',
                                        cursor: 'pointer'
                                    }} />
                                        :
                                        <CaretRightOutlined style={{
                                            color: 'white',
                                            cursor: 'pointer'
                                        }} />
                                }

                            </div>
                        }
                    </div>

                    <div style={{
                        flex: 1,
                        padding: '0 10px' // Ajoute un espacement intérieur pour éviter que le slider touche les bords
                    }}>
                        <Slider style={{
                            width: '100%'
                        }} defaultValue={50} />
                    </div>
                </div>
            </>

        )
    }



    useEffect(() => {
        async function init() {
            let element = null;
            let stream = null
            element = document.getElementById(`jsPanel_${props.cam.id}`)

            if (!element) {
                let panel = await jsPanel.create({
                    headerControls: {
                        add: {
                            html: '<span class="fad fa-bars">x</span>',
                            name: 'menu',
                            position: 6,
                            handler: (panel, control) => {
                                if (props.cam.id === 'local') {

                                    if (props.webcam.current) {

                                        props.toggleWebcam()
                                    }
                                    if (props.micro.current) {
                                        props.toggleAudio()
                                    }

                                    props.removeJspanel(props.cam.id, props.cam.track.kind)
                                }
                                else {
                                    props.removeJSpanel(props.cam.id, props.cam.track.kind)
                                }
                            }
                        },
                        smallify: 'remove',
                        // menu: 'disable' // you could disable or hide the new control right here
                    },

                    id: () => `jsPanel_${props.cam.id}`,
                    resizeit: {
                        aspectRatio: true,
                        grid: [1, 1],
                        minWidth: 170,  // Limite minimale de largeur
                        minHeight: 170, // Limite minimale de hauteur
                    },
                    dragit: {
                        containment: [15, 20, 20]
                    },

                    theme: 'primary',
                    headerTitle: props.cam.username,
                    position: 'center-top 0 58',
                    panelSize: '170 170',
                    // panelSize: '170 130',
                    contentSize: { width: 190, height: 190 },

                    content: `<video class='videoContainer' id=${props.cam.id} muted playsInline ></video><div id="meter_${props.cam.id}"  class="audio-meter"></div><div id='test'> </div>`,

                    callback: panel => {
                        // handler for the icons
                        for (const btn of panel.headertoolbar.querySelectorAll('span')) {
                            btn.addEventListener('click', handleClick);
                        }
                    }
                }).setControlStatus('close', 'remove');

            }
            else {
                console.log('existe deja ?')
            }
            //si élement video existe deja , on ajoute la track sois video ou audio
            element = document.getElementById(props.cam.id)

            if (element.srcObject) {
                stream = element.srcObject;
                element.srcObject.addTrack(props.cam.track);
            }
            else {

                stream = new MediaStream();
                stream.addTrack(props.cam.track);
                element.srcObject = stream;
            }

            console.log(props.cam.track.kind)

            if (props.cam.id !== 'local' && props.cam.track.kind === 'audio') {
                element.muted = false
                createAudioAnalyser(props.cam.id, stream);
            }

            var playPromise = element.play();

            if (playPromise !== undefined) {
                playPromise.then(_ => {
                    // Automatic playback has already started!
                    // Show playing UI.
                })
                    .catch(error => {
                        alert('erreur')
                    });
            }
        }
        initializeAudioTools();
        init()

    }, [])

    setTimeout(() => {
        const reactRoot = document.getElementById(`test`);
        if (reactRoot) {
            ReactDOM.render(
                props.cam.id === 'local' ? <LocalProducerButton /> : <div>Non local</div>,
                reactRoot
            );
        }
    }, 1000);

    return (

        ''
    )

};

export default Jspanelwebcam;