import { axios } from "./axios_custom"

export const sendVoiceRecorded = async (formData, userToken) => {
    const headers = {
        Authorization: `Bearer ${userToken}`, // Pas de 'Content-Type', FormData le gère
    };

    return axios
        .post(`/sendVoiceRecorded`, formData, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("❌ Erreur lors de l'envoi du fichier :", error.response?.data || error.message);
            throw error;
        });
};

