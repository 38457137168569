import { useState, useEffect, useRef, Fragment, useContext, forwardRef } from 'react';
import { Button, Col, Row, Space, Table, Tag, Modal, Avatar, AutoComplete, Input, Switch } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp } from '../../../../action/action';
import { adminGetTemplate, adminTogglePunch } from '../../../../endpoints/admin';

import ModalShowRespondFromApi from './ModalShowRespondFromApi';
import * as InterfaceFunc from "../../../utils/ChatFunction";

const { Column, ColumnGroup } = Table;

const General = forwardRef(({ app, testStore, designPage, user, onSearch, ...props }, ref) => {

    const [modalRespondApi, setModalRespondApi] = useState(false);
    const [redirection, setRedirection] = useState(false);
    const [punch, setPunch] = useState(false);
    const [loadingPunch, setLoadingPunch] = useState(false); // Pour éviter les actions rapides répétées

    const checkPermit = ({ action, ...options }) => {
        if (options.options && options.options2) {
            InterfaceFunc[action](props.socket, user, options.options, options.options2)
                .then(response => alert(response))
                .catch(error => alert(`Erreur: ${error}`));
        } else {
            alert("Les options nécessaires sont manquantes.");
        }
    };

    useEffect(() => {
        const fonctionData = async () => {
            try {
                const data = await adminGetTemplate(user?.token, user?.room);
                if (data?.room?.loadPunch !== undefined) {
                    setPunch(data.room.loadPunch);
                }
            } catch (error) {
                console.error("Erreur lors du chargement du template:", error);
            }
        };
        fonctionData();
    }, [user?.token, user?.room]);

    const togglePunch = async () => {
        if (loadingPunch) return; // Évite les doubles clics pendant le traitement

        const newPunchState = !punch;
        setPunch(newPunchState);
        setLoadingPunch(true); // Démarre le chargement pour éviter les actions multiples

        try {
            await adminTogglePunch(user?.token, user?.room, newPunchState);
        } catch (error) {
            console.error("Erreur lors du basculement du punch:", error);
            setPunch(!newPunchState); // Rétablit l'état précédent en cas d'erreur
        } finally {
            setLoadingPunch(false); // Arrête le chargement
        }
    };

    return (
        <>
            <ModalShowRespondFromApi
                setRedirection={setRedirection}
                message={modalRespondApi}
                modalRespondApi={modalRespondApi}
                setModalRespondApi={setModalRespondApi}
            />

            <Row>
                <Col span={24} style={{ marginTop: '10px', textAlign: 'center' }}>
                    OPTIONS GÉNÉRAL
                </Col>

                <Col span={12} style={{ marginTop: '10px', textAlign: 'center' }}>
                    Effacer la conversation
                </Col>
                <Col span={12} style={{ marginTop: '10px', textAlign: 'center' }}>
                    <Button onClick={() => checkPermit({ action: 'eraseTchat', test: 'test', test2: 'test32' })}>
                        effacer
                    </Button>
                </Col>

                <Col span={12} style={{ marginTop: '10px', textAlign: 'center' }}>
                    Gestion de Bannière et Punch
                </Col>

                <Col span={12} style={{ marginTop: '10px', textAlign: 'center' }}>
                    <Switch
                        onChange={togglePunch}
                        checked={punch}  // ⚠️ Utilisation correcte de `checked` pour assurer la synchronisation
                        loading={loadingPunch}  // Indique visuellement que l'action est en cours
                    />
                </Col>
            </Row>
        </>
    );
});

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch });
export default connect(mapStateToProps, mapDispatchToProps)(General);
