import React, { useState, useEffect, useRef, Fragment, useContext, forwardRef } from 'react';
import { Button, Col, Row, Space, Modal, Input, Select, Avatar, Form, Upload, Switch } from 'antd';
import { Spin } from 'antd';
import { HomeOutlined, UploadOutlined, LockOutlined, LoadingOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
//import { setInitApp, setUserApp, setMessageApp } from '../../../../action/action';
import { updateClient } from '../enpoints/Client';

const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

const { Option } = Select;

const ModifyRoom = forwardRef(({ user, ...props }, ref) => {

    const [spiner, setSpiner] = useState(false)
    const [message, setmessage] = useState(null)

    const onFinish = async (values) => {
        try {
            setSpiner(true);
            const response = await updateClient(values, user?.token);
            if (response && response.detail) {
                setmessage(null);
                setTimeout(() => {
                    setSpiner(false);
                    setmessage(response.detail);
                }, 1000);
            } else {
                setmessage("La réponse n'est pas conforme :");
                setSpiner(false);
            }
        } catch (error) {
            console.error("Une erreur s'est produite lors de l'envoi du formulaire :", error);
            setSpiner(false);
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onChangeSubscription = (e) => {
      
    }
    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

            <Form
                name="basic"

                style={{
                    marginTop: '10px', padding: '0 15px 0 15px', width: '50%'
                }}

                initialValues={{
                    email: props.currentComponent.data.email,
                    description: props.currentComponent.data.description,
                    username: props.currentComponent.data.username,
                    password: props.currentComponent.data.password,
                    firstname: props.currentComponent.data.firstname,
                    lastname: props.currentComponent.data.lastname,
                    subscription: props.currentComponent.data.subcription,
                    active: props.currentComponent.data.active,
                    id: props.currentComponent.data.id,
                    register: props.currentComponent.data.register,
                    roomNumber: props.currentComponent.data.name,
                    title: props.currentComponent.data.title,

                }}

                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ offset: 0, span: 18 }}
                    label="email"
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your email!',
                        },
                    ]}
                >
                    <Input defaultValue={props.currentComponent.data.email} />
                </Form.Item>

                <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ offset: 0, span: 18 }}
                    label="Titre "
                    name="title"
                >
                    <Input defaultValue={props.currentComponent.data.title} />
                </Form.Item>

                <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ offset: 0, span: 18 }}
                    label="Username"
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Username!',
                        },
                    ]}
                >
                    <Input defaultValue={props.currentComponent.data.username} />
                </Form.Item>

                <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ offset: 0, span: 18 }}
                    label="Password"
                    name="password"
                    rules={[{ required: false, message: 'Please input your password!' }]}
                >
                    <Input
                        defaultValue={props.currentComponent.data.password}
                        prefix={<LockOutlined />}
                        type="password"
                        placeholder="Password"
                    />
                </Form.Item>

                <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ offset: 0, span: 18 }}
                    label="Firstname"
                    name="firstname"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your firstname!',
                        },
                    ]}
                >
                    <Input defaultValue={props.currentComponent.data.firstname} />
                </Form.Item>

                <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ offset: 0, span: 18 }}
                    label="Lastname"
                    name="lastname"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your lastname!',
                        },
                    ]}
                >
                    <Input defaultValue={props.currentComponent.data.lastname} />
                </Form.Item>

                <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ offset: 0, span: 18 }}
                    label="Id Database"
                    name="id"
                >
                    <Input defaultValue={props.currentComponent.data.id} disabled />
                </Form.Item>

                <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ offset: 0, span: 18 }}
                    label="register"
                    name="register"
                >
                    <Input defaultValue={props.currentComponent.data.register} disabled />
                </Form.Item>

                <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ offset: 0, span: 18 }}
                    label="Room Number"
                    name="roomNumber"
                >
                    <Input defaultValue={props.currentComponent.data.name} disabled />
                </Form.Item>

                <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ offset: 0, span: 1 }}
                    label="subscription"
                    name="subscription"
                >
                    <Select style={{ width: '200px' }} defaultValue={props.currentComponent.data.subscription} onChange={onChangeSubscription}>
                        <Select.Option value="free">Gratuit</Select.Option>
                        <Select.Option value="gold">Payant</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ offset: 0, span: 1 }}
                    name="active"
                    label="Active"
                    initialValue={props.currentComponent.data.active !== 0}
                >
                    <Switch defaultChecked={props.currentComponent.data.active !== '0'} />
                </Form.Item>

                <Row>
                    <Col span={20} >
                    </Col>
                    <Col span={1}>
                        {message}
                        {spiner &&
                            <Spin
                                indicator={
                                    <LoadingOutlined
                                        style={{ fontSize: 24 }}
                                        spin
                                    />
                                }
                            />
                        }
                    </Col>
                    <Col span={3} style={{ textAlign: 'right' }}>
                        <Button type="primary" htmlType="submit" disabled={spiner}>
                            Envoyer
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ModifyRoom);
