import React, { createContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

// ✅ Création du contexte Socket (doit être exporté correctement)
const SocketContext = createContext(null);

const SocketProvider = ({ children, user }) => {
    const URL = process.env.REACT_APP_MODE_ENV === "development" 
        ? process.env.REACT_APP_URL_SERVER_DEV 
        : process.env.REACT_APP_URL_SERVER_PROD;
    
    const [socket, setSocket] = useState(null);
    const [reconnectionAttempts, setReconnectionAttempts] = useState(0);
    const history = useHistory();

    useEffect(() => {
        if (!user?.token) return;

        const newSocket = io(URL, {
            reconnection: true,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 5000,
            reconnectionAttempts: 3,
            credentials: false,
            auth: { token: user.token },
        });

        newSocket.on('disconnect', () => {
            console.log('Connexion perdue avec le serveur.');
        });

        newSocket.on('connect_error', () => {
            setReconnectionAttempts(prev => {
                const newAttempts = prev + 1;
                console.warn(`Tentative de reconnexion n°${newAttempts}`);

                if (newAttempts >= 3) {
                    console.error('Échec de reconnexion après 3 tentatives. Redirection...');
                    history.go(0);
                }
                return newAttempts;
            });
        });

        newSocket.on('reconnect', () => {
            console.log('Reconnecté avec succès.');
            setReconnectionAttempts(0);
        });

        setSocket(newSocket);

        return () => {
            newSocket.off('disconnect');
            newSocket.off('connect_error');
            newSocket.off('reconnect');
            newSocket.disconnect();
        };
    }, [user?.token, history]);

    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    );
};

const ConnectedSocketProvider = connect(({ user }) => ({ user }))(SocketProvider);

// ✅ Exporter les deux correctement
export { ConnectedSocketProvider, SocketContext };
export default ConnectedSocketProvider;
