import React, { useState, useEffect, useContext, forwardRef } from 'react';
import { Button, Col, Row, Modal, Input, Form, Upload, Switch, Spin } from 'antd';
import { LockOutlined, UploadOutlined, LoadingOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { updateRoom, getRoomById } from '../../../../endpoints/admin';

const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

const ModifyRoom = forwardRef(({ user, ...props }, ref) => {
    const [form] = Form.useForm();
    const [spiner, setSpiner] = useState(false);
    const [roomData, setRoomData] = useState({});

    useEffect(() => {
        if (props.roomToModify) {
            getRoomById(props.roomToModify.id, user?.token).then(resp => {
                setRoomData(resp.data);
                form.setFieldsValue(resp.data); // Set form fields with retrieved data
            });
        }
    }, [props.roomToModify, form, user?.token]);

    const handleOk = () => {
        props.setRoomToModify('');
        props.setIsModalOpenRoom(false);
    };

    const handleCancel = () => {
        props.setRoomToModify('');
        props.setIsModalOpenRoom(false);
    };

    const onFinish = async (values) => {
        try {
            let formData = new FormData();
            formData.append("roomId", props.roomToModify.id);
            formData.append("title", values.name);
            formData.append("description", values.description);
            formData.append("userMaximum", values.userMaximum);
            formData.append("password", values.password);
            formData.append("active", values.active ? 1 : 0);
            formData.append("show_history_on_connect", values.show_history_on_connect ? 1 : 0);

            setSpiner(true);

            await updateRoom(formData, user?.token, user?.room, user?.socketId);

            setTimeout(() => {
                setSpiner(false);
                handleOk();
            }, 3000);
        } catch (error) {
            console.error("Une erreur s'est produite lors de l'envoi du formulaire :", error);
            setSpiner(false);
        }
    };

    return (
        <Modal title="Modify Room" open={props.isModalOpenRoom} onOk={handleOk} onCancel={handleCancel}>
            <div style={{ width: '100%' }}>
                <Form
                    form={form}
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    style={{ marginTop: '10px' }}
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[{ required: true, message: 'Please input the room name!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Description"
                        name="description"
                        rules={[{ required: true, message: 'Please input the room description!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="User Maximum"
                        name="userMaximum"
                        rules={[
                            { required: true, message: 'Please input the maximum number of users!' },
                            { pattern: /^[0-9]+$/, message: 'Please input a valid number!' },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Password"
                        name="password"
                    >
                        <Input prefix={<LockOutlined />} type="password" placeholder="Password" />
                    </Form.Item>
                  
                    <Form.Item
                        name="active"
                        label="Active"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                    <Form.Item
                        name="show_history_on_connect"
                        label="Show History on Connect"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 8, span: 24 }}>
                        {spiner && (
                            <Spin
                                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                            />
                        )}
                        <Button type="primary" htmlType="submit" disabled={spiner}>
                            Envoyer
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
});

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch });
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ModifyRoom);
