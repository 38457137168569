import { Layout, Row, Col, Input, Button, Typography, Modal, Avatar } from 'antd';
import { HomeOutlined, TeamOutlined, VideoCameraAddOutlined, EyeOutlined, AudioOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { useEffect, useState, useContext } from 'react';
import { getAllRoom } from '../enpoints/rooms';
import { SocketContext } from '../../../components/tchat/SocketContext';
import CreateConsumer from './mediasoup/CreateConsumer';
import UserModalOptions from './UserModalOptions';
import ModalShoutcastApi from './ModalShoutcastApi';

const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

const { Content } = Layout;
const { Title } = Typography;

const contentStyle = {
    maxHeight: '89vh',
    minHeight: '89vh',
    color: '#fff',
    backgroundColor: '#d9d9d9',
    padding: '15px'
};

const RoomSysop = ({ user }) => {
    const socket = useContext(SocketContext);
    const [searchTerm, setSearchTerm] = useState('');
    const [userSearchTerm, setUserSearchTerm] = useState('');
    const [originalData, setOriginalData] = useState([]);
    const [dataa, setDataa] = useState(null);
    const [focusRoom, SetFocusRoom] = useState(null);
    const [totalUsersNetwork, setTotalUsersNetwork] = useState(null);
    const [totalRoomsNetwork, setTotalRoomNetwork] = useState(null);
    const [totalProducerNetwork, setTotalProducerNetwork] = useState(null);
    const [totalConsumerNetwork, setTotalConsumerNetwork] = useState(null);
    const [userDetails, setUserDetails] = useState(null)
    const [roomDetails, setRoomDetails] = useState(null)
    const [userIsModalOpen, SetUserIsModalOpen] = useState(false);
    const [roomIsModalOpen, SetRoomIsModalOpen] = useState(false);
   const [modalShoutcast,setModalShoutcast ] = useState(false);
    const [userModalOptions, SetUserModalOptions] = useState(false);
    const [roomId, setRoomId] = useState(false);
    const [selectedUser, setSelectedUser] = useState(false);

    /* show user  modal */
    const userShowModal = (userData) => {
        socket.emit("sysop", 'allllllllllllllllllllllo sysop')
        setUserDetails(userData)
        SetUserIsModalOpen(true);
    };
    const userHandleOk = () => {
        SetUserIsModalOpen(false);
    };
    const userHandleCancel = () => {
        SetUserIsModalOpen(false);
    };

    /* show room  modal */
    const roomShowModal = (roomData) => {

        roomData.totalconsumerTransports = Object.keys(roomData.consumerTransports).length
        roomData.totalproducerTransports = Object.keys(roomData.producerTransports).length
        roomData.totalusers = Object.keys(roomData.users).length
        const { users, producerTransports, consumerTransports, ...filteredData } = roomData;
        setRoomDetails(filteredData)
        SetRoomIsModalOpen(true);
    };
    const roomHandleOk = () => {
        SetRoomIsModalOpen(false)
    };
    const roomHandleCancel = () => {
        SetRoomIsModalOpen(false);
    };

    useEffect(() => {
        getAllRoom(user?.token).then(resp => {
            setDataa(resp.room);
            setOriginalData(resp.room);
            let userTotal = calculateUsersPerRoom(resp.room);
            setTotalUsersNetwork(userTotal);
            const roomKeys = Object.keys(resp.room);
            const totalRooms = roomKeys.length;
            setTotalRoomNetwork(totalRooms);
            let producerTotal = calculateTotalVideoProducer(resp.room);
            setTotalProducerNetwork(producerTotal);
            const consumerTotal = calculateTotalVideoConsumer(resp.room);
            setTotalConsumerNetwork(consumerTotal);
        });
    }, [user?.token]);

    const handleSearch = (e) => {
        const searchValue = e.target.value;
        setSearchTerm(searchValue);

        const filteredRooms = Object.keys(originalData).filter((roomId) =>
            originalData[roomId].name.toLowerCase().includes(searchValue.toLowerCase())
        ).reduce((result, roomId) => {
            result[roomId] = originalData[roomId];
            return result;
        }, {});

        setDataa(filteredRooms);
    };

    const handleUserSearch = (e) => {
        const searchValue = e.target.value;
        setUserSearchTerm(searchValue);

        if (focusRoom) {

            const filteredUsers = focusRoom.users.filter((user) =>
                user.username.toLowerCase().includes(searchValue.toLowerCase())
            );

            SetFocusRoom({ ...focusRoom, filteredUsers });
        }
    };

    const focusRoomHandler = (roomData) => {
        setRoomId(roomData.name)
        SetFocusRoom(roomData);
    };

    const HandlerUserModalOptions = (data) => {
        SetUserModalOptions(true)
        setSelectedUser(data)
    }

    const calculateUsersPerRoom = (roomsData) => {
        let totalUsers = 0;
        for (const roomName in roomsData) {
            if (roomsData.hasOwnProperty(roomName)) {
                totalUsers += roomsData[roomName].users.length;
            }
        }
        return totalUsers;
    };

    const calculateTotalVideoProducer = (roomsData) => {
        let totalVideoProducer = 0;
        for (const roomName in roomsData) {
            if (roomsData.hasOwnProperty(roomName)) {
                totalVideoProducer += roomsData[roomName].totalVideoProducer;
            }
        }
        return totalVideoProducer;
    };

    const calculateTotalVideoConsumer = (roomsData) => {
        let totalVideoConsumer = 0;
        for (const roomName in roomsData) {
            if (roomsData.hasOwnProperty(roomName)) {
                totalVideoConsumer += roomsData[roomName].totalVideoConsumer;
            }
        }
        return totalVideoConsumer;
    };

    const removeUserFromListe = (socketID) => {
        // Utilisation de Array.filter() pour créer une nouvelle liste sans l'utilisateur ayant socketID
        focusRoom.users = focusRoom.users.filter(user => user.socketId !== socketID);
    };

    const handlershowModalShoutcast =() =>{
        setModalShoutcast(true)
    }

    return (
        <>

            <Content style={contentStyle}>
                <Row>
                    <Col span={8}>
                        <Row style={{ color: 'grey', fontSize: '20px', fontWeight: 'bold', textAlign: 'left' }}>
                            <Col span={24}>
                                Last refresh: 2024-06-15 07:01:49
                            </Col>
                            <Col span={24}>
                                Last TC data change: 2024-06-15 08:00:24
                            </Col>
                        </Row>
                    </Col>

                    <Col span={8}>
                        <Title style={{ lineHeight: 0, marginTop: '10px' }}> Chat Explorer</Title>
                    </Col>
                    <Col style={{ color: 'grey', paddingRight: '20px', textAlign: 'right', fontWeight: 'bold' }} span={8}>
                        Times refreshed: 1815819
                    </Col>
                </Row>

                <Row style={{ paddingTop: '20px', fontWeight: 'bold' }}>
                    <Col style={{ fontSize: '25px', color: 'black' }} span={6}>
                        <HomeOutlined />
                        Rooms online: {totalRoomsNetwork}
                    </Col>
                    <Col style={{ fontSize: '25px', color: 'black' }} span={6}>
                        <TeamOutlined />
                        Users online:  {totalUsersNetwork}
                    </Col>
                    <Col style={{ fontSize: '25px', color: 'black' }} span={6}>
                        <VideoCameraAddOutlined />
                        Users broadcasting: {totalProducerNetwork}
                    </Col>
                    <Col style={{ fontSize: '25px', color: 'black' }} span={6}>
                        <EyeOutlined />
                        Users watching: {totalConsumerNetwork}
                    </Col>
                </Row>

                <Row style={{ paddingTop: '45px' }}>
                    <Col span={8}>
                        <Row style={{ height: '62vh', overflowY: 'scroll', padding: '15px ' }}>
                            <Col style={{ textAlign: 'left' }} span={24}>
                                <Input
                                    style={{ width: '300px' }}
                                    prefix={<HomeOutlined />}
                                    placeholder="Search for Room"
                                    value={searchTerm}
                                    onChange={handleSearch}
                                />

                                {dataa && Object.keys(dataa).map(roomId => {
                                    const room = dataa[roomId];
                                    const totalUsers = room.users.length;
                                    const broadcasting = room.totalVideoProducer;
                                    const watching = room.totalVideoConsumer;
                                    const listening = room.totalAudioConsumer;

                                    return (
                                        <Col onClick={() => focusRoomHandler(room)} key={roomId} span={24} style={{ cursor: 'help', marginTop: '15px', border: '7px solid #f5f5f5', borderRadius: '10px', padding: '10px' }}>
                                            <div style={{ backgroundColor: 'white', position: 'absolute', top: '-10px', borderRadius: '5px' }}>
                                                <div style={{ color: 'black' }}><Button>Room {room.name}</Button> </div>
                                            </div>

                                            <div style={{ backgroundColor: 'white', position: 'absolute', top: '-10px', borderRadius: '5px' }}>
                                                <div style={{ color: 'black' }}><Button>Room {room.name}</Button> </div>
                                            </div>
                                            
                                            <Row style={{ fontSize: '25px', paddingTop: '15px', color: 'black' }}>
                                                <Col style={{ width: '25%' }} >
                                                    Total Users: {totalUsers}
                                                </Col>
                                                <Col style={{ width: '25%' }} >
                                                    Broadcasting: {broadcasting}
                                                </Col>
                                                <Col style={{ width: '25%' }} >
                                                    Watching: {watching}
                                                </Col>
                                                <Col style={{ width: '25%' }} >
                                                    Listening: {listening}
                                                </Col>
                                            </Row>
                                        </Col>
                                    );
                                })}
                            </Col>
                        </Row>
                    </Col>

                    {focusRoom &&
                        <Col span={8}>
                            <Row style={{ height: '62vh', overflowY: 'scroll', padding: '15px ' }}>
                                <Col style={{ textAlign: 'left' }} span={24}>
                                    <Input
                                        style={{ width: '300px' }}
                                        prefix={<TeamOutlined />}
                                        placeholder="Search for user"
                                        value={userSearchTerm}
                                        onChange={handleUserSearch}
                                    />

                                    <Col span={24} style={{ marginTop: '15px', border: '7px solid #f5f5f5', borderRadius: '10px', padding: '10px' }}>
                                        <div style={{ backgroundColor: 'white', position: 'absolute', top: '-10px', borderRadius: '5px' }}>
                                            <div onClick={() => roomShowModal(focusRoom)} style={{ cursor: 'help', color: 'black' }}><Button>Room ({focusRoom.name})</Button></div>
                                        </div>

                                        <Row style={{ fontSize: '25px', paddingTop: '15px', color: 'black' }}>
                                            <Col style={{ width: '20%' }} >
                                                <TeamOutlined size={'small'} /> Users: {focusRoom.users.length}
                                            </Col>
                                            <Col style={{ width: '20%' }} >
                                                <VideoCameraAddOutlined size={'small'} /> Broadcast: {focusRoom.totalVideoProducer}
                                            </Col>
                                            <Col style={{ width: '20%' }} >
                                                <AudioOutlined size={'small'} /> Broadcast: {focusRoom.totalAudioProducer}
                                            </Col>
                                            <Col style={{ width: '20%' }} >
                                                <EyeOutlined size={'small'} /> Viewer: {focusRoom.totalVideoConsumer}
                                            </Col>
                                            <Col style={{ width: '20%' }} >
                                                <EyeOutlined size={'small'} /> Listener: {focusRoom.totalAudioConsumer}
                                            </Col>

                                            <Col span={24}>
                                                <Button style={{ fontWeight: 'bold', width: '50%', height: '45px', backgroundColor: '#9C27B0', color: 'white' }}>Enter Room</Button>
                                                <Button onClick={() =>handlershowModalShoutcast()}  style={{ fontWeight: 'bold', width: '50%', height: '45px', backgroundColor: '#9C27B0', color: 'white' }}>Shoutcast</Button> 
                                            </Col>
                                            <Col span={6} style={{ fontWeight: 'bold' }}>
                                                Nickname
                                            </Col>
                                            <Col span={6} style={{ fontWeight: 'bold' }}>
                                                Broadcasting
                                            </Col>
                                            <Col span={6} style={{ fontWeight: 'bold' }}>
                                                Room Id
                                            </Col>

                                            <Col span={6} style={{ fontWeight: 'bold' }}>

                                            </Col>
                                        </Row>

                                        {focusRoom.filteredUsers ? focusRoom.filteredUsers.map((data) => (
                                            <Row style={{ paddingTop: '5px' }} key={data.username}>
                                                <Col style={{ color: 'black' }} span={6}>
                                                    <Avatar size={32} src={`${URL}/${data.avatar}`} />
                                                    {data.username}

                                                </Col>

                                                <Col style={{ color: 'green' }} span={6}>
                                                    Yes
                                                </Col>

                                                <Col style={{ color: 'green' }} span={4}>
                                                    {data.inRoomNumber.join(', ')}
                                                </Col>

                                                <Col style={{ display: 'flex', color: 'green' }} span={8}>
                                                    <Button onClick={() => HandlerUserModalOptions(data)} style={{ backgroundColor: '#9C27B0', color: 'white' }} size={'small'}>Options</Button>
                                                    <Button onClick={() => userShowModal(data)} style={{ backgroundColor: '#9C27B0', color: 'white' }} size={'small'}>Class Infos</Button>
                                                </Col>
                                            </Row>
                                        )) : focusRoom.users.map((data) => (
                                            <Row style={{ paddingTop: '5px' }} key={data.username}>
                                                <Col onClick={() => userShowModal(data)} style={{ cursor: 'help', color: 'black' }} span={6}>
                                                    <Avatar size={32} src={`${URL}/${data.avatar}`} />
                                                    {data.username}
                                                </Col>

                                                <Col style={{ color: 'green' }} span={6}>
                                                    Yes
                                                </Col>
                                                <Col style={{ color: 'green' }} span={4}>
                                                   {data.inRoomNumber[0]}
                                                </Col>
                                                <Col style={{ display: 'flex', color: 'green' }} span={8}>
                                                    <Button onClick={() => HandlerUserModalOptions(data)} style={{ backgroundColor: '#9C27B0', color: 'white' }} size={'small'}>Options</Button>
                                                    <Button onClick={() => userShowModal(data)} style={{ backgroundColor: '#9C27B0', color: 'white' }} size={'small'}>Class Infos</Button>
                                                </Col>
                                            </Row>
                                        ))}
                                    </Col>

                                    <Modal title="User infos Object" open={userIsModalOpen} onOk={userHandleOk} onCancel={userHandleCancel}>
                                        <pre>{JSON.stringify(userDetails, null, 2)}</pre>
                                    </Modal>

                                    <Modal title="Room infos Object" open={roomIsModalOpen} onOk={roomHandleOk} onCancel={roomHandleCancel}>
                                        <div>Total producerTransports : {roomDetails?.totalproducerTransports}</div>
                                        <div>Total consumerTransports : {roomDetails?.totalconsumerTransports}</div>
                                        <div>Total users : {roomDetails?.totalusers}</div>
                                    </Modal>

                                    <UserModalOptions
                                        removeUserFromListe={removeUserFromListe}  // Supprimez les parenthèses ici
                                        selectedUser={selectedUser}
                                        SetUserModalOptions={SetUserModalOptions}
                                        userModalOptions={userModalOptions}
                                    />

                                    <ModalShoutcastApi  setModalShoutcast={setModalShoutcast} modalShoutcast= {modalShoutcast}/>
                                    
                                </Col>
                            </Row>
                        </Col>
                    }
                    <Col span={8}>
                        <Row style={{ height: '62vh', overflowY: 'scroll', padding: '15px ' }}>
                            <Col span={24} style={{ marginTop: '15px', border: '7px solid #f5f5f5', borderRadius: '10px', padding: '10px' }}>
                                <CreateConsumer roomName={roomId} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Content>
        </>
    );
};

const mapStateToProps = ({ user, message }) => ({ user, message });
export default connect(mapStateToProps)(RoomSysop);

