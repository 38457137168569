import React, { useState, useEffect, useRef, useMemo, Fragment, useContext, forwardRef } from 'react';
import { Button, Col, Row, ColorPicker, Space, Modal, Input, Select, Form, Upload, Switch } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp } from '../../../../action/action';
import { test, updateRole } from '../../../../endpoints/admin'
import ModalShowRespondFromApi from './ModalShowRespondFromApi';
const { Option } = Select;

const ModifyRole = forwardRef(({ user, ...props }, ref) => {

    const [colorHex, setColorHex] = useState(props.currentComponentObj.object.role_color);
    const [formatHex, setFormatHex] = useState('hex');
    const [message, setMessage] = useState(null)
    const [modalRespondApi, setModalRespondApi] = useState(false)
    const [spiner, setSpiner] = useState(false)
    

    const hexString = useMemo(
        () => (typeof colorHex === 'string' ? colorHex : colorHex.toHexString()),
        [colorHex],
    );

    const onFinish = async (values) => {
        

        const formData = {
            ...values,
            color: colorHex,
        };

        updateRole(formData, user?.token, user?.room, user?.socketId).then(resp => {
            setSpiner(true)
            if (!resp.permission) {
               
                setMessage(resp.message)
                setModalRespondApi(true)
            }
            setTimeout(() => {
                setSpiner(false)
            }, 3000);
        })
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleColorChange = (newColor) => {

        setColorHex(newColor.toHexString());
    };

    return (
        <>
            <ModalShowRespondFromApi message={message} modalRespondApi={modalRespondApi} setModalRespondApi={setModalRespondApi} />


            <Form
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                style={{
                    marginTop: '10px'
                }}

                initialValues={{
                    role: props.currentComponentObj.object.role,
                    position: props.currentComponentObj.object.position,
                    id: props.currentComponentObj.object.id,
    
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >


                <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ offset: 0, span: 16 }}
                    label="Nom du Role"
                    name="role"

                >
                    <Input value={'testet'} style={{ color: hexString }} disabled={false} defaultValue={props.currentComponentObj.object.role} />
                </Form.Item>

                <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ offset: 0, span: 16 }}
                    label="Position dans la liste des utilisateurs"
                    name="position"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your position!',
                        },
                    ]}
                >
                    <Input defaultValue={props.currentComponentObj.object.position} />
                </Form.Item>


                <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ offset: 0, span: 16 }}
                    label="Couleur du role"
                    name="color"
                >
                    <ColorPicker
                        value={hexString ? hexString : 'red'}
                        onChange={handleColorChange}
                        format={formatHex}

                    />
                    <div style={{ display: 'none' }}>Hex Color: {hexString}</div>
                </Form.Item>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            className="custom-form-item"
                            labelCol={{ span: 18 }}
                            wrapperCol={{ offset: 0, span: 16 }}
                            name="open_webcam"
                            label="Utiliser Webcam"
                            initialValue={props.currentComponentObj.object.open_webcam !== 0}
                        >
                            <Switch defaultChecked={props.currentComponentObj.object.open_webcam !== 0} />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            className="custom-form-item"
                            labelCol={{ span: 18 }}
                            wrapperCol={{ offset: 0, span: 16 }}
                            label="Utiliser micro"
                            name="open_micro"
                            initialValue={props.currentComponentObj.object.open_micro !== 0}
                        >
                            <Switch defaultChecked={props.currentComponentObj.object.open_micro !== 0} />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            className="custom-form-item"
                            labelCol={{ span: 18 }}
                            wrapperCol={{ offset: 0, span: 16 }}
                            label="Utiliser le partage d'écran"
                            name="open_share_screen"
                            initialValue={props.currentComponentObj.object.open_share_screen !== 0}
                        >
                            <Switch defaultChecked={props.currentComponentObj.object.open_share_screen !== 0} />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            className="custom-form-item"
                            labelCol={{ span: 18 }}
                            wrapperCol={{ offset: 0, span: 16 }}
                            label="Envoyer des Messages"
                            name="send_message"
                            initialValue={props.currentComponentObj.object.send_message !== 0}
                        >
                            <Switch defaultChecked={props.currentComponentObj.object.send_message !== 0} />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            className="custom-form-item"
                            labelCol={{ span: 18 }}
                            wrapperCol={{ offset: 0, span: 16 }}
                            label="Voir les Ips"
                            name="see_user_ip"
                            initialValue={props.currentComponentObj.object.see_user_ip !== 0}
                        >
                            <Switch defaultChecked={props.currentComponentObj.object.see_user_ip !== 0} />
                        </Form.Item>
                    </Col>

                    <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ offset: 0, span: 16 }}
                    label="id role"
                    name="id"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your id!',
                        },
                    ]}
                >
                    <Input defaultValue={props.currentComponentObj.object.id} />
                </Form.Item>

                    <Col span={12}>
                        <Form.Item
                            className="custom-form-item"
                            labelCol={{ span: 18 }}
                            wrapperCol={{ offset: 0, span: 16 }}
                            label="Peut Kicker"
                            name="kick_user"
                            initialValue={props.currentComponentObj.object.kick_user !== 0}
                        >
                            <Switch defaultChecked={props.currentComponentObj.object.kick_user !== 0} />
                        </Form.Item>


                    </Col>

                    <Col span={12}>
                        <Form.Item
                            className="custom-form-item"
                            labelCol={{ span: 18 }}
                            wrapperCol={{ offset: 0, span: 16 }}
                            label="Peut bannir"
                            name="ban_user"
                            initialValue={props.currentComponentObj.object.ban_user !== 0}
                        >
                            <Switch defaultChecked={props.currentComponentObj.object.ban_user !== 0} />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            className="custom-form-item"
                            labelCol={{ span: 18 }}
                            wrapperCol={{ offset: 0, span: 16 }}
                            label="Demande de privé"
                            name="send_private"
                            initialValue={props.currentComponentObj.object.send_private !== 0}
                        >
                            <Switch defaultChecked={props.currentComponentObj.object.send_private !== 0} />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            className="custom-form-item"
                            labelCol={{ span: 18 }}
                            wrapperCol={{ offset: 0, span: 16 }}
                            label="Peut etre invisible"
                            name="can_be_invisible"
                            initialValue={props.currentComponentObj.object.can_be_invisible !== 0}
                        >
                            <Switch defaultChecked={props.currentComponentObj.object.can_be_invisible !== 0} />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            className="custom-form-item"
                            labelCol={{ span: 18 }}
                            wrapperCol={{ offset: 0, span: 16 }}
                            label="Modifier l'historique de conversation"
                            name="edit_message"
                            initialValue={props.currentComponentObj.object.erase_history_chat !== 0}
                        >
                            <Switch defaultChecked={props.currentComponentObj.object.erase_history_chat !== 0} />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            className="custom-form-item"
                            labelCol={{ span: 18 }}
                            wrapperCol={{ offset: 0, span: 16 }}
                            label="Envoyer des images"
                            name="send_image_tchat"
                            initialValue={props.currentComponentObj.object.send_image_tchat !== 0}
                        >
                            <Switch defaultChecked={props.currentComponentObj.object.send_image_tchat !== 0} />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            className="custom-form-item"
                            labelCol={{ span: 18 }}
                            wrapperCol={{ offset: 0, span: 16 }}
                            label="Envoyer des images Gyphi"
                            name="send_gif_api"
                            initialValue={props.currentComponentObj.object.send_gif_api !== 0}
                        >
                            <Switch defaultChecked={props.currentComponentObj.object.send_gif_api !== 0} />
                        </Form.Item>
                    </Col>

                </Row>


                <Form.Item
                    wrapperCol={{
                        offset: 20,
                        span: 24,
                    }}
                >
                {
                    spiner 
                    &&
                    <Spin
                        indicator={
                            <LoadingOutlined
                                style={{
                                    fontSize: 24,
                                }}
                                spin
                            />
                        }
                    />
                }
                  
                    <Button  type="primary" htmlType="submit" disabled={spiner}>
                        Envoyer
                    </Button>
                </Form.Item>

            </Form>

        </>
    );

})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ModifyRole);
