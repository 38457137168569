import React, { forwardRef, useEffect, useState } from 'react';
import { Button, Col, Space, Row, Moda, Avatar, Table, Modal } from 'antd';
import { connect } from 'react-redux';
import { getListBan } from '../../../../endpoints/admin'
import TimeAgo from 'react-timeago';

import frenchStrings from 'react-timeago/lib/language-strings/fr'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
const formatter = buildFormatter(frenchStrings)

const { Column, ColumnGroup } = Table;

const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;


const ModalShowImagePunchInformation = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {

    const [data, setData] = useState(null)

    useEffect(() => {
        getListBan(user?.token, user?.room).then(resp => {
            setData(resp.data)

        })
    }, [])

    const handleOk = () => {
        props.setIsModalOpenBanList(false);
    };
    const handleCancel = () => {
        props.setIsModalOpenBanList(false);
    };

    return (
        <Modal
            width={'80%'}
            title="Liste d'utilisateurs bannis"
            style={{ textAlign: 'center' }}
            open={props.isModalOpenBanLIst}
            onOk={handleOk}
            onCancel={handleCancel}

        >
            <Table dataSource={data}>

                <Column title="Username" dataIndex="username" key="username" />
                <Column title="Fingerprint" dataIndex="fingerprint" key="fingerprint" />

                <Column
                    title="from"
                    dataIndex="from_user"
                    key="from_user"
                />

                <Column
                    title="email"
                    dataIndex="email"
                    key="email"
                />

                <Column
                    title="Time"
                    key="exec_time"
                    render={(_, record) => (
                        <Space size="middle">
                            <TimeAgo date={record.exec_time} formatter={formatter} live={true} locale="fr" />
                        </Space>
                    )}
                />



                <Column
                    title="Action"
                    key="action"
                    render={(_, record) => (
                        <Space size="middle">
                            <Button type="primary" danger>Supprimer</Button>
                        </Space>
                    )}
                />
                {/*}
            <Column
                title=<Button onClick={() => props.setCurrentComponentObj({ name: 'addUser'})} style={{ position: 'absolute', left: '-100px', bottom: '10px' }} type="primary" >Ajouter</Button>
                key="add"
            />
                {*/}
            </Table>
        </Modal>
    );
});

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ModalShowImagePunchInformation);
