import React, { forwardRef, useEffect } from "react";
import { Modal } from "antd";
import { connect } from "react-redux";

const URL =
  process.env.REACT_APP_MODE_ENV === "development"
    ? process.env.REACT_APP_URL_SERVER_DEV
    : process.env.REACT_APP_URL_SERVER_PROD;

const ModalshowLike = forwardRef(({ user, ...props }, ref) => {
  if (!props.message || !Array.isArray(props.message.likeFrom) || props.message.likeFrom.length === 0) {
    return null ; 
  }

  // Regrouper les réactions par utilisateur
  const groupedReactions = props.message.likeFrom.reduce((acc, { username, reaction }) => {
    if (!acc[username]) {
      acc[username] = [];
    }
    acc[username].push(reaction);
    return acc;
  }, {});

  const handleOk = () => {
    props.setShowModalshowLike(false);
  };

  const handleCancel = () => {
    props.setShowModalshowLike(false);
  };

  return (
    <Modal
      title={`Réactions total : ${props.message.likeFrom.length}`}
      open={props.ShowModalshowLike}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <div>
        {Object.entries(groupedReactions).map(([username, reactions], index) => (
          <div key={index} style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
            <strong>{username} :</strong>
            {reactions.map((reaction, i) => (
              <img
                key={i}
                src={`${URL}/images/ico/${reaction}.png`}
                alt={reaction}
                style={{ height: "15px", marginLeft: "5px" }}
              />
            ))}
          </div>
        ))}
      </div>
    </Modal>
  );
});

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ModalshowLike);
