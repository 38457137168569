import { useState, useEffect, useRef, Fragment, useContext, forwardRef } from 'react';
import { Button, Col, Row, Space, Table, Tag, Modal, Avatar, Divider, AutoComplete, Input, Form, Typography } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp } from '../../../../action/action';
import { adminGetAllRoleApi } from '../../../../endpoints/admin'
const { Column, ColumnGroup } = Table;
const { Title } = Typography;

const ListeRole = forwardRef(({ app, testStore, designPage, user, onSearch, ...props }, ref) => {

    const [dataa, setDataa] = useState(null)
    const [originalData, setOriginalData] = useState([])
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        adminGetAllRoleApi(user?.token, user?.room, user?.socketId).then(resp => {
        
            setDataa(resp.role)
            setOriginalData(resp.role)
        })
    }, [])

    const handleSearch = (e) => {
        const searchValue = e.target.value;
        const filteredRoles = originalData.filter((role) =>
            role.role.toLowerCase().includes(searchValue.toLowerCase())
        );
        setDataa(filteredRoles);
        setSearchTerm(searchValue);
    };

    const onFinish = async (values) => {
    }

    const onFinishFailed = (errorInfo) => {
        console.log("Échec de la soumission du formulaire:", errorInfo);
    };

    return (
        <>
            <Row>
                <Col span={24} style={{ marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Input
                        type="text"
                        placeholder="Rechercher un role"
                        value={searchTerm}
                        style={{ width: '100%' }}
                        onChange={handleSearch}
                    />
                    <Button size="large" onClick={() => props.setCurrentComponentObj({ name: 'addRole' })} style={{ display: 'flex', alignItems: 'center', marginLeft: '5px' }} type="primary" >+Ajouter</Button>
                </Col>
            </Row>

            <Form
                name="loginForm"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                labelCol={{ span: 10 }}
            >
                <Table dataSource={dataa}>
                    <Column title="Position" dataIndex="position" key="prosition" />
                    <Column
                    
                     title="Role" style={{color:'red'}} dataIndex="role" key="role" />

                    <Column
                        title="Action"
                        key="action"
                        render={(_, record) => (
                            <Space size="middle">
                                {/*}
                                <Form.Item
                                    label="Nom d'utilisateur"
                                    name="username2"
                                    rules={[
                                        { required: true, message: "Veuillez saisir votre nom d'utilisateur!" }
                                    ]}
                                    initialValue={''}
                                >
                                    <Input />
                                </Form.Item>
{*/}
                                <Button onClick={() => props.setCurrentComponentObj({ name: 'editRole', object: record })} type="primary" primary>Modifier</Button>
                                <Button onClick={() => props.setCurrentComponentObj({ name: 'deleteRoom', object: record })} type="primary" danger>Supprimer</Button>
                            </Space>
                        )}
                    />
                    {/*}
                    <Column
                        title=<Button onClick={() => props.setCurrentComponentObj({ name: 'addRole' })} style={{ position: 'absolute', left: '-100px', bottom: '10px' }} type="primary" >Ajouter</Button>
                        key="add"
                    />
{*/}
                </Table>
            </Form>
        </>
    );
})


const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ListeRole);