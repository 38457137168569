import React, { useState } from 'react';
import { Space, Layout, Form, Input, Button ,Row , Col, Card, Typography} from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import Nav from '../components/pages/layout/Nav';

const { Header, Footer, Content } = Layout;
const actualPage = 'about'
const { Title, Paragraph } = Typography;

const headerStyle = {
    textAlign: 'center',
    color: '#fff',
    height: 64,
    paddingInline: 10,
    lineHeight: '64px',
    backgroundColor: '#7dbcea',
};

const contentStyle = {
    textAlign: 'center',
    minHeight: '76vh',
    lineHeight: '60px',
    color: '#fff',
    backgroundColor: ' rgb(22, 22, 22)',
};

const footerStyle = {
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#7dbcea',

};

const benefits = [
    {
        title: "Création rapide et facile d'un salon",
        description: "En quelques clics, l'utilisateur génère un espace où il peut inviter ses amis, sans procédures d'inscription fastidieuses."
    },
    {
        title: "Interaction naturelle et spontanée",
        description: "Grâce à la webcam et au micro, les conversations se rapprochent davantage des échanges réels, recréant l’ambiance d’un véritable salon entre amis."
    },
    {
        title: "Flexibilité d'utilisation",
        description: "Que ce soit pour organiser une soirée virtuelle, discuter de sujets en petit comité ou simplement passer du temps ensemble, votre plateforme offre une alternative rapide et adaptée."
    },
    {
        title: "Confidentialité et contrôle",
        description: "Chaque hôte gère son propre salon, avec la possibilité d’accepter ou de refuser les participants, garantissant ainsi une expérience sécurisée et conviviale."
    },
    {
        title: "Moins de contraintes techniques",
        description: "Contrairement à certaines plateformes qui nécessitent des configurations complexes ou des comptes utilisateurs, votre site met l'accent sur la simplicité d'accès."
    }
];

const About = () => {

    return (
        <Space direction="vertical" style={{ width: '100%', height: '100vh' }} size={[0, 48]}>
            <Layout >
                <Header style={headerStyle}>
                    <Nav pageSelected={actualPage} />
                </Header>
                <Content style={contentStyle}>
                <div style={{ padding: '40px', backgroundColor: '#f0f2f5' }}>
            <Title level={2} style={{ textAlign: 'center', marginBottom: '30px' }}>
                Les bénéfices clés de nos services
            </Title>

            <Row gutter={[24, 24]}> {/* Espacement entre les colonnes et les rangées */}
                {benefits.map((benefit, index) => (
                    <Col xs={24} sm={12} md={8} key={index}>
                        <Card 
                            hoverable
                            style={{ 
                                borderRadius: '12px', 
                                backgroundColor: '#ffffff',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' 
                            }}
                        >
                            <Title level={4}><CheckCircleOutlined style={{ color: '#52c41a', marginRight: '8px' }} />{benefit.title}</Title>
                            <Paragraph>{benefit.description}</Paragraph>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
                </Content>
                <Footer style={footerStyle}>Footer</Footer>
            </Layout>
        </Space>
    );
};

export default About