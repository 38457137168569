import React, { useState, useEffect, useRef, forwardRef } from 'react';
import { Button, Col, Row, Space, Table, Switch, Input } from 'antd';
import { connect } from 'react-redux';
import {adminDeleteShoutcastApi, adminGetAllShoutcastApi, adminSetActiveShoutcastApi, adminPunchDeleteImageApi } from '../../../../endpoints/admin';
import ModalShowShoutcastInformation from './ModalShowShoutcastInformation';
import ModalShowRespondFromApi from './ModalShowRespondFromApi';

const { Column } = Table;

const ListeShoutcast = forwardRef(({ user, ...props }, ref) => {
  const [dataa, setDataa] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [originalData, setOriginalData] = useState([]);
  const [isModalOpenFileInfo, setIsModalOpenFileInfo] = useState({ show: false, obj: null });
  const [forceUpdate, setForceUpdate] = useState(null);
  const [activePlayer, setActivePlayer] = useState(null);
  const audioRefs = useRef({});
  const [message , setMessage] = useState(null)
  const [modalRespondApi, setModalRespondApi] = useState(false)

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    const filteredRooms = originalData.filter((image) =>
      image.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setDataa(filteredRooms);
    setSearchTerm(searchValue);
  };

  useEffect(() => {
 
    const fetchData = async () => {
      try {
        const resp = await adminGetAllShoutcastApi(user?.token, user?.room, user?.socketId);
        if (resp.shoutcast) {
          setDataa(resp.shoutcast);
          setOriginalData(resp.shoutcast);
        }
      } catch (error) {
        // Gérer les erreurs si nécessaire
      }
    };
    if(isModalOpenFileInfo ){
        fetchData(); // Appel de la fonction d'initialisation
    }

    return () => {
     
      setDataa([]);
      setOriginalData([]);
    };
  }, [isModalOpenFileInfo]); 
  
  

  useEffect(() => {
    adminGetAllShoutcastApi(user?.token, user?.room, user?.socketId).then((resp) => {
      if (resp.shoutcast) {
        setDataa(resp.shoutcast);
        setOriginalData(resp.shoutcast);
      }
    });
  }, [forceUpdate]);

  const handleSwitchChange = (targetId) => {
    adminSetActiveShoutcastApi(user?.token, user?.room, user?.socketId, user?.username, targetId).then((resp) => {

        if(resp.permission){
            setDataa((prevState) =>
            prevState.map((obj) => ({
              ...obj,
              active: obj.id === targetId ? 1 : 0,
            }))
          );
        }
        else{
            setMessage(resp.message)
            setModalRespondApi(true)
        }
    });

 
  };

  const showModalInfo = (record) => {
    setIsModalOpenFileInfo({ show: true, obj: record });
  };

  const deleteShoutcast = (targetId) => {
    adminDeleteShoutcastApi(user?.token, user?.room, user?.socketId, targetId).then((resp) => {
      if(!resp.permission){
        setMessage(resp.message)
        setModalRespondApi(true)
      }
      setForceUpdate(new Date());
    });
  };

  const handleAudioPlay = (id) => {
    if (activePlayer && activePlayer !== id) {
      // Mettre en pause l'ancien lecteur s'il existe
      audioRefs.current[activePlayer].pause();
    }
    setActivePlayer(id);
  };

  return (
    <>
      <Row >
      <ModalShowRespondFromApi message={message} modalRespondApi={modalRespondApi} setModalRespondApi={setModalRespondApi} />

        <Col span={24} style={{ marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <Input type="text" placeholder="Rechercher un Shoutcast" value={searchTerm} style={{ width: '100%' }} onChange={handleSearch} />
          <Button size="large" onClick={() => props.setCurrentComponentObj({ name: 'addShoutcast' })} style={{ display: 'flex', alignItems: 'center', marginLeft: '5px' }} type="primary">
            +Ajouter
          </Button>
        </Col>
      </Row>

      <Table  dataSource={dataa}>
        <Column
          title="ip"
          dataIndex="ip"
          key="ip"
          render={(ip, record) => (
            <div>
              <audio key={dataa} ref={(audio) => (audioRefs.current[record.id] = audio)} style={{ width: '150px' }} controls onPlay={() => handleAudioPlay(record.id)}>
                <source src={ip+'/stream'} type="audio/mpeg" />
              </audio>
            </div>
          )}
        />

        <Column title="Nom" dataIndex="name" key="name" />

        <Column
          title="Action"
          key="action"
          render={(_, record) => (
            <Space size="middle">
              <Button onClick={() => deleteShoutcast(record.id)} type="primary" danger>
                Supprimer
              </Button>
              <Button style={{ backgroundColor: '#14A44D' }} onClick={() => showModalInfo(record)} type="primary" danger>
                Modifier
              </Button>
            </Space>
          )}
        />

        <Column
          title="active"
          dataIndex="active"
          key="active"
          render={(active, record) => (
            <>
              <Switch checked={active === 1} onChange={() => handleSwitchChange(record.id)} />
            </>
          )}
        />
      </Table>
      <ModalShowShoutcastInformation user={user} isModalOpenFileInfo={isModalOpenFileInfo} setIsModalOpenFileInf={setIsModalOpenFileInfo} />
    </>
  );
});

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = (dispatch) => ({ dispatch });
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ListeShoutcast);
