import { useEffect, forwardRef, useContext } from 'react';
import { connect } from 'react-redux';
import { SocketContext } from '../../SocketContext';
import { useState } from 'react';
import { getActivePunch } from '../../../../endpoints/punch'
import { TypeAnimation } from 'react-type-animation';

const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

const Banner = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {
   let socket = useContext(SocketContext);
   const [imagePunch, setImagePunch] = useState({ file_name: null })
   
   useEffect(() => {

      if (user?.token) {
         getPunchInfo();
      }
      if (socket) {
         socket.on('updatePunchImage', updatePunch)
         return () => {
            socket.off('updatePunchImage', updatePunch)
         }
      };
   }, [socket]);

   const updatePunch = (punch) => {
      setImagePunch(punch)
   }

   const getPunchInfo = async () => {
      if (user?.token) {
         await getActivePunch(user?.token, user?.room).then(punch => {
            setImagePunch(punch.punch)
         })
      }
   }

   return (

      imagePunch?.file_name ?
         <>
            <TypeAnimation
              sequence={[
               '🎧 Vos hits préférés, 24/7 !',
               1000,
               '🔥 Le meilleur du son, non-stop !',
               1000,
               '♥️ De la musique pour toutes vos humeurs !',
               1000,
               '📻 Votre vibe, votre son, votre radio !',
               1000
           ]}
               wrapper="span"
               speed={50}
               style={{
                  left:'-50vh',
                  fontSize: '2em',
                  display: 'inline-block',
                  position: 'absolute',
                  bottom: '-35px',
                  color: 'white',  // Texte en blanc
                  textShadow: '2px 2px 5px rgba(0, 0, 0, 0.8)'  // Ombre noire légère
               }}
               repeat={Infinity}
            />
            <img id="img_punch" style={{ width: '100%', height: '100%' }} src={`${URL}/${imagePunch.file_name}`} />


         </>

         :
         <>
            <img id="img_punch" style={{ width: '100%', height: '100%' }} src={`/images/punch/defaultPunch.gif`} />

         </>
   )
})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Banner);

