import { Space, Layout, Row, Col, Input, Button, Form, Table ,Typography } from 'antd';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { getMemory } from '../enpoints/memory';
const { Column, ColumnGroup } = Table;
const { Header, Footer, Content } = Layout;
const actualPage = 'room page';
const { Title } = Typography;

const RoomSysop = ({ app, testStore, designPage, user, ...props }) => {

    const [searchTerm, setSearchTerm] = useState('');
    const [originalData, setOriginalData] = useState([])
    const [dataa, setDataa] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const resp = await getMemory(user?.token);
                setDataa(resp.memory);
            } catch (error) {
                console.error("Error fetching memory data:", error);
            }
        };

        fetchData();

        const intervalId = setInterval(fetchData, 5000);

        return () => clearInterval(intervalId);
    }, [user?.token]); // Ajout de user?.token comme dépendance pour recharger les données lorsque le token change

    return (
        <>
         <Title level={2}>Mémoire ressources serveur</Title>
            <Row>
                <Col span={24}>
                    rss: {dataa?.rss}
                </Col>
                <Col span={24}>
                    heapTotal: {dataa?.heapTotal}
                </Col>
                <Col span={24}>
                    heapUsed: {dataa?.heapUsed}
                </Col>
                <Col span={24}>
                    external: {dataa?.external}
                </Col>
            </Row>
        </>
    );
};

const mapStateToProps = ({ user, message }) => ({ user, message });
export default connect(mapStateToProps)(RoomSysop);