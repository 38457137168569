import { useState, useContext, useEffect, useRef, Fragment, useMemo, createRef, forwardRef } from 'react';
import _ from 'underscore';
import { connect } from 'react-redux';
import { Avatar, TypingIndicator } from '@chatscope/chat-ui-kit-react';
import { SocketContext } from '../../SocketContext';
import { Col, Row, Modal } from 'antd';
import { VideoCameraOutlined, AudioOutlined, EyeOutlined, CustomerServiceOutlined } from '@ant-design/icons';
import ModalUserlistAction from './modalUserlistAction';
import * as InterfaceFunc from "../../../utils/ChatFunction"

const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

const ShowUser = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {

    const TypingIconeUserRef = {}
    const eyeUserRef = {}
    const userOpenModalRef = {}
    const userCloseModalRef = {}
    const socket = useContext(SocketContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [message, setMessage] = useState();

    const show_hideModalUserlistAction = (id) => {
        const openModal = userOpenModalRef[id]
        const closeModal = userCloseModalRef[id]
        openModal.showModal()
        closeModal.addEventListener("click", () => {
            openModal.close();
        }, { passive: true });
    }

    const askQuestion = (array) => {
        InterfaceFunc.askQuestion(socket, array)
    }

    function Webcam(propss) {
        if (propss.user.videoEnabled && propss.user.autoAccessMediaDevices === 'true') {
            return <VideoCameraOutlined style={{cursor: propss.user.socketId !== user?.socketId ? 'pointer' : 'auto'  ,color: 'white' }} ref={ref => props.allWebcamIcoTheRefs[propss.user.socketId] = ref} onClick={() => propss.user.socketId !== user?.socketId && user?.addConsumer(null, propss.user.socketId, propss.user.producerTransports, 'video', propss.user.username)} />
        }
        if (propss.user.videoEnabled && propss.user.autoAccessMediaDevices === 'false') {
            return <a href="#" onClick={() => propss.user.socketId !== user?.socketId && askQuestion({ action: 'watch', target: propss.user })}>ask</a>
        }
    }

    function Micro(propss) {
        if (propss.user.audioEnabled && propss.user.autoAccessMediaDevices === 'true') {
            return <AudioOutlined style={{cursor: propss.user.socketId !== user?.socketId ? 'pointer' : 'auto' , color: 'white' }} ref={ref => props.iconeMicroRef[propss.user.socketId] = ref} onClick={() => propss.user.socketId !== user?.socketId && user?.addConsumer(null, propss.user.socketId, propss.user.producerTransports, 'audio', propss.user.username)} />
        }
        if (propss.user.audioEnabled && propss.user.autoAccessMediaDevices === 'false') {
            return <a href="#" onClick={() => propss.user.socketId !== user?.socketId && askQuestion({ action: 'listen', target: propss.user.socketId })}>ask</a>
        }
    }

    const RespondModalAction = (value) => {
        return (
            <>
                <Modal title="Réponse du serveur" open={isModalOpen} onOk={handleOk} cancelButtonProps={{ style: { display: 'none' } }} >
                    <p>{message}</p>
                </Modal>
            </>
        )
    }

    const handleOk = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Row >
                {isModalOpen && <RespondModalAction />}
                <Col span={10} ref={props.refe} style={{paddingTop:'5px'}} >
                    {user.socketId !== props.userinfos.socketId ?
                        <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => show_hideModalUserlistAction(props.userinfos.socketId)}>
                            <img className='imgUserTyping' ref={TypingIconeUserRef[props.userinfos.socketId]} src="https://thumbs.gfycat.com/AdventurousSkinnyEyelashpitviper-size_restricted.gif" style={{ display: "none" }} width="35" height="35" />
                            <Avatar size="md" src={`${URL}/${props.userinfos.avatar}`} name={"Zoe"} status={props.userinfos.status} />
                            <div ref={ref => props.userTypingIcoRef.current[props.userinfos.socketId] = ref} style={{ zIndex: 2, display: 'none', position: 'absolute', bottom: '5px', left: '50px' }} > <TypingIndicator /></div>
                            <span style={{ paddingLeft: '5px', color: props.userinfos?.role_color ? props.userinfos?.role_color : 'green' }} className="name" >{props.userinfos.username} {props.userinfos?.roleColor}</span>
                        </div>
                        :
                        <div style={{ display: 'flex', alignItems: 'center' }}  >

                            <Avatar
                                status={props.userinfos.status}
                                alt="Remy Sharp"
                                src={`${URL}/${props.userinfos.avatar}`}
                                sx={{ width: 35, height: 35 }}
                            />
                            <div ref={ref => props.userTypingIcoRef.current[props.userinfos.socketId] = ref} style={{ zIndex: 2, display: 'none', position: 'absolute', bottom: '5px', left: '50px' }} > <TypingIndicator /></div>
                            <span className="name" style={{color: props.userinfos?.role_color ? props.userinfos?.role_color : 'green', paddingLeft: '5px' }}>{props.userinfos.username}</span>
                        </div>
                    }
                </Col>
                <Col style={{ display: 'flex', alignItems: 'center', color: 'black' }} span={7}>
                    <Webcam user={props.userinfos} />

                    <Micro user={props.userinfos} />
                </Col>
                <Col style={{ display: 'flex', alignItems: 'center', color: 'black' }} span={7}>
                    {props.userinfos?.YouWatching?.includes(user?.socketId, 0)
                        ?
                        <EyeOutlined onClick={() => alert()} style={{ color: 'white' }} ref={ref => eyeUserRef[props.userinfos.socketId] = ref} />
                        :
                        null
                    }
                    {
                        props.userinfos?.YouListen?.includes(user?.socketId, 0)
                            ?
                            <CustomerServiceOutlined onClick={() => alert()} style={{ color: 'white' }} ref={ref => eyeUserRef[props.userinfos.socketId] = ref} />
                            :
                            null
                    }
                </Col>
            </Row>
            <ModalUserlistAction userCloseModalRef={userCloseModalRef} ref={ref} userOpenModalRef={userOpenModalRef} userinfos={props.userinfos} />
        </>
    )
})


const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ShowUser);
