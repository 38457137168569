import {  Layout, theme } from 'antd';
import { useState, useRef, forwardRef, useEffect } from 'react';
import Onglet from '../onglet/Onglet'
import { connect } from 'react-redux';
import InputChat from '../InputChat/InputChat'
import UserList from '../rightSide/UserLIst'
import ListeMobile from '../rightSide/ListeMobile';
import ModalMessageFromServer from '../modal/ModalMessageFromServer'
import FlashMessageFromServer from '../rightSide/FlashMessageFromServer'
import useRefs from '../../ref/useRefs'

const { Header, Sider, Content } = Layout;

const Center = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {
    const refs = useRefs();
    const flashMessageRef = useRef()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const messagesEndRef = useRef(null);
    const [collapsed, setCollapsed] = useState(false);
    //set mobile cellulaire responsive
    const [mobile, setMobile] = useState(false)

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const closeModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (user?.messageFromServeur) {

            setIsModalOpen(true);
        }
    }, [user?.messageFromServeur]);

    return (
        <>
            {mobile &&
                <ListeMobile ref={ref} startVideoProducer={props.startVideoProducer} flashMessageRef={flashMessageRef} />
            }
            <Layout style={{ padding: 0, margin: 0 }}>
                <Content
                    style={{
                        backgroundColor: colorBgContainer,
                    }}
                >
                    <div ref={messagesEndRef} >
                        <Onglet ref={ref} messagesEndRef={{ messagesEndRef }} />
                        <div>
                            <FlashMessageFromServer flashMessageRef={flashMessageRef} />
                        </div>
                    </div>
                    <div ref={ref.bottonBox}>
                        <InputChat ref={ref} />
                    </div>
                </Content>
                {!mobile &&
                    <UserList socket= {props.socket} ref={ref} startVideoProducer={props.startVideoProducer} flashMessageRef={flashMessageRef} />
                }
            </Layout>

            <ModalMessageFromServer isModalOpen={isModalOpen} closeModal={closeModal} message={user?.messageFromServeur} />
                  </>
    );
});
const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Center);