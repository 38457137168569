import React, { useState, useEffect, useRef, Fragment, useContext, forwardRef } from 'react';
import { Button, Col, Row, Space, Modal, Input, Select, Avatar, Form, Checkbox, Switch, Table, Upload } from 'antd';
import { MenuUnfoldOutlined, LockOutlined, UploadOutlined } from '@ant-design/icons';
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp } from '../../../../action/action';
import { adminAddShoutcastApi } from '../../../../endpoints/admin'
import ModalShowRespondFromApi from './ModalShowRespondFromApi';


const AddShoutcast = forwardRef(({ app, testStore, designPage, user, onSearch, ...props }, ref) => {
  const [form] = Form.useForm();
  const [message, setMessage] = useState(null)
  const [modalRespondApi, setModalRespondApi] = useState(false)

  const onFinish = async (values) => {
    adminAddShoutcastApi(values, user?.token, user?.room, user?.username, user?.socketId).then(resp => {
      if (!resp.permission) {
        setMessage(resp.message)
        setModalRespondApi(true)
      }
      else {
        props.setCurrentComponentObj({ name: 'Shoutcast' })
        form.resetFields();
      }

    })
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onChange = () => {
    alert()
  }
  return (
    <>
      <ModalShowRespondFromApi message={message} modalRespondApi={modalRespondApi} setModalRespondApi={setModalRespondApi} />
      <Form
        form={form}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          textAlign: 'start',
          marginTop: '10px',

        }}

        initialValues={{
          remember: true,
          shoutcastpath: "/stream",
          type: "shoutcast2",
          metadatatechnic: "fallback"
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          className="custom-form-item"
          label="Nom"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please input your name!',
            },

          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          className="custom-form-item"
          label="Ip"
          name="ip"
          rules={[
            {
              required: true,
              message: 'Please input your ip!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          className="custom-form-item"
          label="shoutcastpath	"
          name="shoutcastpath"

          rules={[
            {
              required: true,
              message: 'Please input your shoutcastpath	!',
            },
          ]}
        >
          <Input defaultValue="/stream" />
        </Form.Item>

        <Form.Item
          className="custom-form-item"
          label="Type"
          name="type"

          rules={[
            {
              required: true,
              message: 'Please input your type!',
            },
          ]}
        >
          <Select onChange={onChange} defaultValue="shoutcast2">
            <Select.Option value="shoutcast2">shoutcast2</Select.Option>
            <Select.Option value="icecast2">icecast2</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          className="custom-form-item"
          label="Metadatatechnic"
          name="metadatatechnic"

          rules={[
            {
              required: true,
              message: 'Please select your metadatatechnic!',
            },
          ]}
        >
          <Select onChange={onChange} defaultValue="fallback">
            <Select.Option value="fallback">fallback</Select.Option>
            <Select.Option value="directly">directly</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 24,
          }}
        >
          <Button type="primary" htmlType="submit">
            Ajouter
          </Button>
        </Form.Item>
      </Form>
    </>
  );

  <Form.Item
    className="custom-form-item"
    label="Type"
    name="Type"

    rules={[
      {
        required: true,
        message: 'Please select your metadatatechnic!',
      },
    ]}
  >
    <Select onChange={onChange} defaultValue="fallback">
      <Select.Option value="fallback">fallback</Select.Option>
      <Select.Option value="directly">directly</Select.Option>
    </Select>
  </Form.Item>


})


const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AddShoutcast);