import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { useState, useEffect, useRef, Fragment } from 'react';
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp } from '../../action/action';
import { useParams } from "react-router-dom";
import  SocketProvider  from '../../components/tchat/SocketContext';
import Login from '../sysop/Login';
import Main from '../sysop/Main';
import { getIp } from '../sysop/enpoints/login';

const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

const Index = ({ app, testStore, designPage, user, ...props }) => {

    const [ip, setIp] = useState()
    let fpHash = useRef(null)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const resp = await getIp();
                setIp(resp.ip);
            } catch (error) {
                console.error("Erreur lors de la récupération de l'adresse IP:", error);
            }
        };
        fetchData();

        setTimeout(() => {
            // props?.dispatch(setUserApp({ InitSysopState: 'main-sysop' }))
        }, 5000);

        const setFp = async () => {
            const fp = await FingerprintJS.load();
            const { visitorId } = await fp.get();
            fpHash.current = visitorId;

            props?.dispatch(setUserApp({
                InitSysopState: 'login',
                login: false,
                username: null,
                password: null,
                ip: null,
                fpHash: fpHash.current
            }))
        };
        setFp();
    }, [])

    switch (user?.InitSysopState) {
        case 'login':
            return (
                <div style={{ backgroundColor: 'black', height: '100vh', maxHeight: '100vh', backgroundImage: `url(${URL}/uploads/intro.jpg)` }}>
                    <Login />
                    <div style={{ position: 'absolute', bottom: '0' }} >Votre Ip: {ip}
                    </div>
                    <div style={{ position: 'absolute', bottom: '20px' }} >FPHASH: {user?.fpHash}
                    </div>
                </div>
            )
            break;

        case 'main-sysop':
            return (
                <SocketProvider {...{ user, ...props }}>
                    <Main />
                </SocketProvider>
            )
            break;


    }
}

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps)(Index);
