import { axios } from "./axios_custom"

export const getNotification = ( data ) => {
    const token = data.usertoken
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const body = {
        data:'data'
    };
    return axios
        .post(`/getNotification`, body, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}

export const getMessage = async ( data ) => {
    const token = data.usertoken
    const headers = {
        Authorization: `Bearer ${token}`,
    };
  
    const body = {
        data:data
    };
    return axios
        .post(`/getMessage`, body, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}

export const APIdeleteNotification = async ( data ) => {
    const token = data.token
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const body = {
        data:data
    };
    return axios
        .post(`/deleteNotification`, body, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}

export const APIdeleteMessage = async ( data ) => {
    const token = data.token
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const body = {
        data:data
    };
    return axios
        .post(`/deleteMessage`, body, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}

export const deleteAllNotifications = async ( data ) => {
    const token = data.token
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const body = {
        data:data
    };
    return axios
        .post(`/deleteAllNotifications`, body, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
    }

    
export const APIdeleteAllMessage = async ( data ) => {
    const token = data.token

    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const body = {
        data:data
    };
    return axios
        .post(`/deleteAllMessage`, body, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
    }

    
export const APIreportMessage = async ( data ) => {
    const token = data.token
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const body = {
        data:data
    };
    return axios
        .post(`/deleteAllMessage`, body, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
    }