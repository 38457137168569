import { Space, Layout, Row, Col, Input, Button, Form, Table, Select } from 'antd';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { getAllClient } from '../enpoints/Client';
import  '../../../css/sysop/sysop.css'
const { Column, ColumnGroup } = Table;
const { Header, Footer, Content } = Layout;
const actualPage = 'room page';

const Client = ({ app, testStore, designPage, user, ...props }) => {

    const [searchTerm, setSearchTerm] = useState('');
    const [originalData, setOriginalData] = useState([])
    const [dataa, setDataa] = useState(null)

    useEffect(() => {
        getAllClient(user?.token).then(resp => {
            setDataa(resp.room)
            setOriginalData(resp.room)
        })
    }, [])

    const handleSearch = (e) => {
        const searchValue = e.target.value;
        const filteredRoles = originalData.filter((client) =>
            client.email.toLowerCase().includes(searchValue.toLowerCase())
        );
        setDataa(filteredRoles);
        setSearchTerm(searchValue);
    };

    const onFinish = async (values) => {
    }

    const onFinishFailed = (errorInfo) => {
        console.log("Échec de la soumission du formulaire:", errorInfo);
    };
  

    
    return (
        <>
            <Row style={{ padding: '5px 10px 5px 10px' }}>
                <Col span={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Input
                        type="text"
                        placeholder="Rechercher un role"
                        value={searchTerm}
                        style={{ width: '100%' }}
                        onChange={handleSearch}
                    />
                </Col>
                <Col span={2} offset={16} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Button size="small" onClick={() => props.setCurrentComponentObj({ name: 'addRole' })} style={{ display: 'flex', alignItems: 'center', marginLeft: '5px' }} type="primary" >+Ajouter</Button>
                </Col>
            </Row>

            <Form
                name="loginForm"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                labelCol={{ span: 10 }}
            >
                <Table rowClassName='sysop-table' dataSource={dataa} style={{ overflowX: 'auto', padding: '0 10px 0 10px' }}>
                    <Column title="id" dataIndex="id" key="id" />
                    <Column
                        title="Nom" dataIndex="name" key="name" />

                    <Column
                        title="title" dataIndex="title" key="title" />

                    <Column
                        title="Email" dataIndex="email" key="email" />

                    <Column
                        title="firstname" dataIndex="firstname" key="firstname" />

                    <Column
                        title="lastname" dataIndex="lastname" key="lastname" />

                    <Column
                        title="register date" dataIndex="register" key="register" />

                    <Column
                        title="Subscription" dataIndex="subscription" key="subscription" />

                    <Column
                        title="Action"
                        key="action"
                        render={(_, record) => (
                            <Space size="middle">
                                <Button onClick={() => props.setCurrentComponent({ component: 'modifyClient', data: record })} type="primary" primary>Modifier</Button>
                                <Button onClick={() => props.setCurrentComponentObj({ name: 'deleteRoom', object: record })} type="primary" danger>Supprimer</Button>
                            </Space>
                        )}
                    />
                </Table>
            </Form>
        </>
    );
};

const mapStateToProps = ({ user, message }) => ({ user, message });
export default connect(mapStateToProps)(Client);
