import { useState, useEffect, useRef, useContext, forwardRef } from 'react';
import { connect } from 'react-redux';
import { setUserApp } from '../../../../action/action';
import {MenuUnfoldOutlined, VideoCameraAddOutlined, AudioOutlined, DesktopOutlined, ApiOutlined, SettingOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { } from '@chatscope/chat-ui-kit-react';
import { Col,Typography, Radio, Button, Badge } from 'antd';
import { SocketContext } from '../../SocketContext';
import { EventEmitter } from '../../../utility/Emitter'
import { getMessage, getNotification } from '../../../../endpoints/notification';
import ModalUserOptions from './ModalUserOptions'
import Gift from '../Gift/Gift';

const { Text, Link } = Typography;
const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

const Middle = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {
  
    const socket = useContext(SocketContext);
    const fileInputRef = useRef(null);
    const [videoEnableDisabled, setEnableVideoDisabled] = useState(false)
    const [audioEnableDisabled, setEnableAudioDisabled] = useState(false)
    const [isModalOpenGift, setIsModalOpenGift] = useState(false);
    const [totalNotification, setTotalNotification] = useState();
    const [gift, setGift] = useState();
    const [badgeTotal, setBadgeTotal] = useState(0)

    const [boleanStateMessage, setBoleanStateMessage] = useState(false)
    const [boleanStateNotification, setBoleanStateNotification] = useState(false)

    const show_panel_option = () => {
        setIsModalOpen(true)
    }

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const notification = await getNotification({ usertoken: user?.token, user: user?.room, Iddb: user?.IdDb });
                const messages = await getMessage({ usertoken: user?.token, user: user?.room });
             //  props?.dispatch(setUserApp({ totalNotification: notification.data.length, totalMessage: messages.length }))
                setBadgeTotal(messages.length + notification.data.length)

                /* setTotalNotification(notification.data.length + totalMessages); */
            } catch (error) {
                console.error('Erreur lors de la récupération des notifications :', error);
            }
        };
        fetchNotifications();
    }, [user.getTotalMessage,user.getNotificationTotal]);

    const receiveGift = async (gift) => {
        setIsModalOpenGift(true)
        setGift(gift)
        setTotalNotification((prevTotal) => prevTotal + 1);
    }

    const updateUserToken = (value) => {
        ref.setTemplateTexteColorState(prevState => ({
            ...prevState,
            ['token_virtual']: value
        }));

        props?.dispatch(setUserApp({ token_virtual: value }))
        ref.token_virtual.current = value
    }

    const updateMessageAndNotification = () => {
  
        let ancienneValeurNotification;
        let ancienneValeurMessage;
    
        setBoleanStateNotification((prevNotificationState) => {
            ancienneValeurNotification = prevNotificationState;
            return !prevNotificationState; // Inversion de l'état
        });
    
        setBoleanStateMessage((prevMessageState) => {
            ancienneValeurMessage = prevMessageState;
            return !prevMessageState; // Inversion de l'état
        });
    
        // Envoie les anciennes et nouvelles valeurs dans le dispatch
        props?.dispatch(setUserApp({
            getTotalMessage: !ancienneValeurMessage,
            getNotificationTotal: !ancienneValeurNotification
        }));
    };
    

  useEffect(() => {
    if (socket) {
        socket.on('blockVideo', blockVideo);
        socket.on('blockAudio', blockAudio);
        socket.on('unBlockVideo', unblockVideo);
        socket.on('unBlockAudio', unBlockAudio);
        socket.on('sendGift', receiveGift);
        socket.on('updateUserToken', updateUserToken);
        socket.on('updateMessageAndNotification', updateMessageAndNotification);
    }

    // Nettoyage lors du démontage du composant
    return () => {
        if (socket) {
            socket.off('blockVideo', blockVideo);
            socket.off('blockAudio', blockAudio);
            socket.off('unBlockVideo', unblockVideo);
            socket.off('unBlockAudio', unBlockAudio);
            socket.off('sendGift', receiveGift);
            socket.off('updateUserToken', updateUserToken);
            socket.off('updateMessageAndNotification', updateMessageAndNotification);
        }
    };
}, [socket]);

    useEffect(() => {
        //donc je peut recevoir tout par ref 
        //donc je pourrais creer un component lui passer ref et faire jouer le MP3 par le nom de ref.soundRoom
        if (ref.SoundEffect) {

        }
    }, [ref.SoundEffect])

    const blockVideo = () => {
        setEnableVideoDisabled(true)
    }

    const unblockVideo = () => {
        setEnableVideoDisabled(false)
    }

    const blockAudio = () => {
        setEnableAudioDisabled(true)
    }

    const unBlockAudio = () => {
        setEnableAudioDisabled(false)
    }

    const toggleUserList = () => {
        props?.dispatch(setUserApp({ userlist: !user?.userlist }))
    }

    const emitterMessage = EventEmitter().subscriber('totalNotification', async (totalNotification) => {
        try {
            setTotalNotification(totalNotification.total)
        } catch (err) {
        }
    });

    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            {/* ouvre la fenetre gift */}
            <Gift gift={gift} setIsModalOpenGift={setIsModalOpenGift} isModalOpenGift={isModalOpenGift} ref={ref} />
            <ModalUserOptions ref={ref} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />

            <Col ref={ref.logo} style={{ color: 'rgb(0, 0, 7) ', placeContent: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }} xs={7} sm={8} lg={8}>
                {/* logo s'affichera ici depuis la reference */}
            </Col>

            <Col style={{ textAlign: 'right', color: 'black' }} xs={16} sm={14} lg={16} >
                <Badge
                    count={badgeTotal} // Nombre dans le badge
                    style={{
                        position: 'absolute',
                        bottom: '-5px',     // Déplacement vers le bas
                        left: '-8px',       // Déplacement vers la gauche
                        backgroundColor: 'red', // Couleur personnalisée
                        zIndex: '999999'
                    }}
                />
                <Button style={{ backgroundColor: 'rgb(22, 22, 22)' }} icon={<SettingOutlined style={{ fontSize: '20px', color: 'white', width: '40px' }} />} onClick={show_panel_option} >
                    <span style={{ textAlign: 'center', color: 'white' }}>{user?.username}
                    </span>
                </Button>

                <Button style={{ marginRight: '2px', backgroundColor: 'rgb(22, 22, 22)', }} icon={user?.userlist ? <MenuFoldOutlined style={{ fontSize: '20px', color: 'white', }} /> : <MenuUnfoldOutlined style={{ fontSize: '20px', color: 'white', backgroundColor: 'black' }} />} onClick={toggleUserList} ></Button>

                {user?.role?.open_webcam === 1 ?
                    <Button style={{ marginRight: '2px', backgroundColor: 'rgb(22, 22, 22)' }} disabled={videoEnableDisabled} icon={<VideoCameraAddOutlined ref={props.localwebcamRef} style={{ fontSize: '20px', color: 'white' }} />} onClick={props.toggleWebcam}></Button>
                    : null
                }

                {user?.role?.open_micro === 1 ?
                    <Button style={{ marginRight: '2px', backgroundColor: 'rgb(22, 22, 22)' }} disabled={audioEnableDisabled} icon={<AudioOutlined ref={props.localMicroRef} style={{ fontSize: '20px', color: 'white' }} />} onClick={props.toggleAudio}></Button>
                    : null
                }

                {user?.role?.open_share_screen === 1 ?
                    <Button style={{ marginRight: '2px', backgroundColor: 'rgb(22, 22, 22)' }} icon={<DesktopOutlined style={{ fontSize: '20px', color: 'white' }} />} onClick={props.enableScreenVideoTrack}></Button>
                    : null
                }
                <Button style={{ marginRight: '2px', backgroundColor: 'rgb(22, 22, 22)' }} icon={<ApiOutlined style={{ fontSize: '20px', color: 'white' }} />}></Button>
            </Col>
        </>
    )
})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Middle);