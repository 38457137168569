import { useContext, useState, useEffect, useRef, forwardRef, createRef } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { setUserApp } from '../../../../action/action';
import { Tabs, Badge, notification} from 'antd';
import { SocketContext } from '../../SocketContext';
import ModalRoomSelect from '../rightSide/ModalRoomSelect'
import MessageFromUser from './MessageFromUser';
import { EventEmitter } from '../../../utility/Emitter'
import MessageFromBot from './MessageFromBot';

const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

const Onglet = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {

    const socket = useContext(SocketContext);
    const [message, setMessage] = useState([]);
    const [mode, setMode] = useState('top');
    const [tab, setTab] = useState();
    const [activeTab, setActiveTab] = useState();
    const [lastMessage, setLastMessage] = useState(null);
    const [showBadge, setShowBadge] = useState(true);
    const [messageServeur, setMessageServeur] = useState(null)
    const [isModalOpenserveur, setIsModalOpenServeur] = useState(false);
    const [antModalRooms, setAntModalRooms] = useState(false);
    const [firstTabKey, setFirstTabKey] = useState(null);
    const [objectBotMessage, setObjectBotMessage] = useState();
    const [editMessagePackage, setEditMessagePackage] = useState()
    const history = useHistory();
    const refs = useRef({});

    const showNotificationIn = () => {
        notification.info({
          message: 'Message',
          description: "Quelqu'un a réagi à votre commentaire.",
          placement: 'bottomRight', // Options : 'topLeft', 'topRight', 'bottomLeft', 'bottomRight'
          duration: 5, // Durée d'affichage en secondes
        });
      };

    useEffect(() => {
        props?.dispatch(setUserApp({ tab: tab }));
        if (tab?.length > 0) {
            setFirstTabKey(String(tab[0].key));
        }
    }, [tab]);

    useEffect(() => {
        //apres que tab change et seter le firstTabKey on force le click sur cette tab
        if (tab?.length > 0) {
            handleTabClick(firstTabKey)
        }
    }, [firstTabKey]);

    useEffect(() => {
        if (messageServeur) {
            setIsModalOpenServeur(true)
        }
    }, [messageServeur]);

    const eraseAllMessage = () => {
        alert('effacement')
        setMessage([])
    }

    const userAsSendGift = (objToUserInfos, fromUsername, gift) => {
        const MessageId = crypto.randomUUID(); // ✅ ID généré à chaque nouvel appel
        let currentDate = new Date();
        let formattedDate = currentDate.toISOString();
        let state;
        let message =
        {
            status: "dnd",
            time: formattedDate,
            message: `${fromUsername} a envoyé un cadeau à ${objToUserInfos.toUserInfos.username}   `,
            from: fromUsername,
            colorLeft: '',
            colorRight: '',
            for: null,
            type: "bot",
            gift: true,
            giftType: gift,
            room: objToUserInfos.roomId,
            url: null,
            bot: true,
            roomId: 1, //--------destination tab
            typeTab: "room",
            avatar: "uploads/63734f4f44707077eb0a2b231e208cbb.jpg",
            formatMessage: {
                "bold": false,
                "italic": false,
                "underline": true,
                "colorText": "#1677ff"
            },
            roleColor: "#d5352a",
            messageId: MessageId,
            socketId: null
        }

        setMessage((prevMessages) => [...prevMessages, message]);
        setTimeout(() => {
            scrollToBottom()
        }, 1000);
    }
 
    const newVoiceMessage = (data) => {
        const voiceMessageId = crypto.randomUUID(); // ✅ ID généré à chaque nouvel appel
        let currentDate = new Date();
        let formattedDate = currentDate.toISOString();
          
            const message = {
            status: null,
            time: formattedDate,
            message: '',
            from: `${data.user.username}`,
            colorLeft: '',
            colorRight: '',
            for: null,
            url: data.fileUrl,
            type: "voice",
            gift: true,
            giftType: null,
            room: `${data.user.selectedRoom}`,
            bot: false,
            roomId: `${data.user.selectedRoom}`,
            typeTab: "room",
            avatar: `${data.user.avatar}`,
            formatMessage: {
                "bold": false,
                "italic": false,
                "underline": true,
                "colorText": "#1677ff"
            },
            roleColor: "#d5352a",
            messageId: voiceMessageId, // ✅ Utilisation de l'ID unique généré
            socketId: null
        };
    
        setMessage((prevMessages) => [...prevMessages, message]);
    
        setTimeout(() => {
            scrollToBottom();
        }, 1000);
    };
    
    useEffect(() => {
        if (socket) {
            socket.on('messageBot', (msgBotArrayOfObject) => setObjectBotMessage(msgBotArrayOfObject));
            socket.on('messageHistory', handleMessageHistory);
            socket.on('message', handleMessage);
            socket.on('userAsSendGift', userAsSendGift);
            socket.on('newVoiceMessage',(data) => newVoiceMessage(data));

            socket.on('eraseAllMessage', eraseAllMessage);
            socket.on('updateMessage', (data) => updateMessage(data));
            socket.on('deleteMessage', (data) => deleteMessage(data));
            socket.on('addTab', addTab);
            socket.on('updateMessageDetailConvo', updateMessageDetailConvo);
            socket.on('disconnectUser', (id, roomData) => closeTabAndMessageUser(id, roomData));
            socket.on('forceUserDisconnect', () => forceUserDisconnect());

        }

        const forceUserDisconnect = () => {
            //quand un admin supprime l'utilisateur on le force a deconnecter 
            history.push('/');
        }
        const closeTabAndMessageUser = (id, roomData) => {
            onEdit(id, 'remove')
            props?.dispatch(setUserApp({ messageFromServeur: `vous avez été kicker de la room ${roomData.title}` }));
        }

        return () => {
            if (socket) {
                socket.off('messageBot', (msgBotArrayOfObject) => setObjectBotMessage(msgBotArrayOfObject));
                socket.off('messageHistory', handleMessageHistory);
                socket.off('message', handleMessage);
                socket.off('updateMessage', updateMessage);
                socket.off('deleteMessage', (data) => deleteMessage(data));
                socket.off('addTab', addTab);
                socket.off('updateMessageDetailConvo', updateMessageDetailConvo);
                socket.off('disconnectUser', (id, roomData) => closeTabAndMessageUser(id, roomData));
                socket.off('forceUserDisconnect', () => forceUserDisconnect());
            }
        };
    }, [socket]);

    useEffect(() => {
        //au premier chargement on parcours les room et affiche dans le tab celui selectionner
        user?.roomInfos.filter(room => room.id === user?.selectedRoom)
            .map((room, index) => (
                setTab([room])
            ))
        const emitter = EventEmitter().subscriber('informRoomUserEntrance', data => {
            infRoomUserEntrance(data.user, data.message, data.state, data.idRoom)
        })
        return () => {
            emitter.unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (lastMessage && user?.selectedRoom != lastMessage?.roomId) {
            setTab(prevTab => prevTab.map(value => {
                if (value.id === lastMessage?.roomId) {
                    return {
                        ...value,
                        unReadMessage: value.unReadMessage + 1,
                    };
                }
                return value;
            }));
        }
        scrollToBottom()
    }, [lastMessage]);

    useEffect(() => {
        if (!editMessagePackage) return;
        setMessage((messages) =>
            Array.isArray(messages) ? messages.map((value) => {
                if (value.messageId === editMessagePackage?.originalMessage?.messageId) {
                    return {
                        ...value,
                        message: editMessagePackage.message,
                        likeFrom: editMessagePackage.likeFrom
                    };
                } else {
                    return value;
                }
            }) : []
        );

    }, [editMessagePackage]);

    const infRoomUserEntrance = (user, messageText, state, idRoom) => {
        const MessageId = crypto.randomUUID(); // ✅ ID généré à chaque nouvel appel
        let currentDate = new Date();
        let formattedDate = currentDate.toISOString();
        let message =
        {
            status: 'dnd',
            time: formattedDate,
            message: `${user.username}  ${messageText}`,
            from: 'Systeme',
            for: null,
            type: 'messageSysteme',
            room: user?.room,
            roomId: idRoom,
            typeTab: 'room',
            avatar: 'uploads/3135158fcab96cedf38ba7832da314be.jpg',
            formatMessage: {
                bold: false,
                italic: false,
                underline: false,
                colorText: 'black'
            },
            roleColor: 'red',
            messageId: MessageId,
            socketId: 'messageSysteme',
            state: state,
            messageColorSysteme: state === 'in' ? 'green' : 'red'
        }

        setMessage((prevMessages) => [...prevMessages, message]);
        setTimeout(() => {
            scrollToBottom()
        }, 1000);
    }

    // Fonction pour ajouter une ref dynamique
    const setRef = (id) => {
        if (!refs.current[id]) {
            refs.current[id] = createRef();
        }
        return refs.current[id];
    };

    const updateMessage = (messageToUpdate) => {
        setEditMessagePackage(messageToUpdate)
        if (messageToUpdate.from === user?.username) {
            showNotificationIn();
        }
    };

    const deleteMessage = (message) => {
        setMessage((messages) =>
            messages.filter((value) => value.messageId !== message.messageId)
        );
    }

    const handleMessage = (message) => {
        if (message.type !== 'bot') {
            const urlRegex = /^(?!https:\/\/media)(ftp|http|https):\/\/[^ "]+/g;
            const matches = message.message.match(urlRegex);
            // si le message n'est pas une image mais une adresse URL
            if (matches && message.type !== 'image') {
                let modifiedMessage = message.message;

                matches.forEach((url) => {
                    const clickableLink = `<a href="${url}" target="_blank">${url}</a>`;
                    modifiedMessage = modifiedMessage.replace(url, clickableLink);
                });
                // Utilisez dangerouslySetInnerHTML pour interpréter le HTML
                message.message = <div dangerouslySetInnerHTML={{ __html: modifiedMessage }} />;
            }
            setLastMessage(message)
        }

        setMessage((prevMessages) => {
            if (prevMessages.length > 0) {
                if (message.type === 'bot') {
                    const lastFiveMessages = prevMessages.slice(-10);
                    for (let i = 0; i < lastFiveMessages.length; i++) {
                        const prevMessage = lastFiveMessages[i];
                        if (prevMessage.message === message.message) {
                            return prevMessages;
                        }
                    }
                }
            }
            setTimeout(() => {
                scrollToBottom();
            }, 500);
            return [...prevMessages, message];
        });
    }

    const handleMessageHistory = (messageHistory) => {
        setMessage((prevMessages) => [...prevMessages, ...messageHistory]);
        setTimeout(() => {
            scrollToBottom();
        }, 1000);

    };

    const scrollToBottom = () => {
        Object.values(refs.current).forEach(ref => {
            const elementscrollTop = ref.current;
            if (elementscrollTop) {
                const scrollHeight = elementscrollTop.scrollHeight;
                const clientHeight = elementscrollTop.clientHeight;
                const maxScrollTop = scrollHeight - clientHeight;
                elementscrollTop.scrollTop = maxScrollTop;
            }
        });
    };

    const addTab = (roomInfos) => {
        setTab((oldTabs) => [...oldTabs, roomInfos]);
        handleTabClick(roomInfos.key)
    }

    const updateMessageDetailConvo = (userToUpdate) => {
        setMessage((messages) =>
            messages.map((value) => {
                if (value.socketId === userToUpdate.socketId) {
                    return {
                        ...value,
                        status: userToUpdate.status,
                        avatar: userToUpdate.avatar
                    };
                } else {
                    return value;
                }
            })
        );
    };

    const handleTabClick = (targetKey) => {
        props?.dispatch(setUserApp({ selectedRoom: targetKey }));
        setActiveTab(String(targetKey));
        const clickedTab = tab?.find((item) => item.key == targetKey);

        if (clickedTab) {
            const title = clickedTab.title;
            if (clickedTab.type === 'user') {
                props?.dispatch(setUserApp({ typeTab: 'user' }));
            }
            else {
                props?.dispatch(setUserApp({ typeTab: 'room' }));
            }
        }
        setTab(prevTab => prevTab.map(value => {
            if (value.id == targetKey) {
                return {
                    ...value,
                    unReadMessage: 0, // Mettez à jour les propriétés de l'objet souhaitées
                };
            }
            return value;
        }));
    };

    const onEdit = (targetKey, action) => {
        props?.dispatch(setUserApp({ messageFromServeur: null }));

        if (action === 'add') {
            setAntModalRooms(true)
        } else {
            const typeTab = tab.find(item => item.id == targetKey);
            if (typeTab?.typeTab === 'room') {
                remove(Number(targetKey));
                socket.emit('userCloseTab', targetKey, typeTab)
            }
            if (!typeTab?.typeTab) {
                remove(targetKey);
            }
            if (firstTabKey) {
                handleTabClick(firstTabKey)
                setActiveTab(String(firstTabKey))
            }
            else {
                handleTabClick(targetKey)
            }
        }
    };

    const remove = (targetKey) => {
        setTab((prevTab) => prevTab.filter(item => item.id !== targetKey));
    }

    const mergeRefs = (...refs) => (el) => {
        refs.forEach((ref) => {
            if (typeof ref === 'function') ref(el);
            else if (ref && 'current' in ref) ref.current = el;
        });
    };

    return (
        <>
     
            { // if message bot existe , send back message with handler with interval
                objectBotMessage &&

                <MessageFromBot messageFromBot={objectBotMessage} handleMessage={handleMessage} user={user} />
            }
            <div >
                <Tabs
                    onChange={(activeKey) => handleTabClick(activeKey)}
                    onEdit={((activeKey, action) => onEdit(activeKey, action))}
                    tabPosition={mode}
                    type="editable-card"
                    activeKey={activeTab}
                    //couleur de l'onglet
                    style={{ backgroundColor: '#00000012' }}
                >
                    {tab && tab.map((item, tabIndex) => (
                        <Tabs.TabPane
                            key={item.key}
                            closable={tab.length > 1}
                            tab={<span>{item.title} <Badge count={showBadge ? item.unReadMessage : 0} /> </span>}
                        >
                            
                            {/* si tabs es plus grand que 1 creer une nouvelle reference */}
                            <div
                                className={ref.loadPunch.current ? 'mainConvo' : 'mainConvoNopunch'}
                                style={{ overflowY: 'scroll', backgroundSize: '100% 80vh', backgroundRepeat: 'no-repeat' }}
                                ref={mergeRefs(setRef(item.id), ref.scrollContainerRef)}
                            >

                                {message.filter(value => Number(value.roomId) === Number(user.selectedRoom) && value?.typeTab === 'room' || value.roomId === user.selectedRoom)
                                    .map((value, messageIndex) =>

                                        <MessageFromUser socket={socket} key={`message_${tabIndex}_${messageIndex}`} message={value} ref={ref} />
                                    )}

                            </div>
                        </Tabs.TabPane>
                    ))}
                </Tabs>
            </div>
            <ModalRoomSelect antModalRooms={antModalRooms} setAntModalRooms={setAntModalRooms} />
        </>
    );
})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Onglet);