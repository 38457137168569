import { useState, useEffect, useRef, Fragment, useContext, forwardRef } from 'react';
import { Button, Col, Row, Space, Table, Tag, Modal, Avatar, Divider, AutoComplete, Input, Form, Typography } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp } from '../../../../action/action';
import { adminGetAllBotApi, adminDeleteBot } from '../../../../endpoints/admin'
import ModalShowRespondFromApi from './ModalShowRespondFromApi';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
const { Column, ColumnGroup } = Table;
const { Title } = Typography;

const ListeBot = forwardRef(({ app, testStore, designPage, user, onSearch, ...props }, ref) => {

    const [dataa, setDataa] = useState(null)
    const [originalData, setOriginalData] = useState([])
    const [searchTerm, setSearchTerm] = useState('');
    const [modalRespondApi, setModalRespondApi] = useState(false)
    const [redirection, setRedirection] = useState(false)

    useEffect(() => {
        adminGetAllBotApi(user?.token, user?.room, user?.socketId).then(resp => {
             /*
            const keys = Object.keys(resp.messageBot);
            for (const key of keys) {
                const millisecondes = resp.messageBot[key].message_interval
                resp.messageBot[key].message_interval = millisecondes / 1000;
              }
              */
            setDataa(resp.messageBot)
            setOriginalData(resp.messageBot)
        })
    }, [])

    useEffect(() => {
        if (redirection) {
            adminGetAllBotApi(user?.token, user?.room, user?.socketId).then(resp => {
                setDataa(resp.messageBot)
                setOriginalData(resp.messageBot)
            })
        }
        setRedirection(false)
    }, [redirection])

    const handleSearch = (e) => {
        const searchValue = e.target.value;
        const filteredRoles = originalData.filter((bot) =>
            bot.message.toLowerCase().includes(searchValue.toLowerCase())
        );
        setDataa(filteredRoles);
        setSearchTerm(searchValue);
    };

    const onFinish = async (values) => {
    }

    const onFinishFailed = (errorInfo) => {
        console.log("Échec de la soumission du formulaire:", errorInfo);
    };

    const deleteBot = (id) => {
        adminDeleteBot(id, user?.token, user?.room, user?.socketId).then(resp => {
            setModalRespondApi(resp.message)
        })
    }

    return (
        <>
            <ModalShowRespondFromApi setRedirection={setRedirection} message={modalRespondApi} modalRespondApi={modalRespondApi} setModalRespondApi={setModalRespondApi} />
            <Row>
                <Col span={24} style={{ marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Input
                        type="text"
                        placeholder="Rechercher un Message bot"
                        value={searchTerm}
                        style={{ width: '100%' }}
                        onChange={handleSearch}
                    />
                    <Button size="large" onClick={() => props.setCurrentComponentObj({ name: 'addBot' })} style={{ display: 'flex', alignItems: 'center', marginLeft: '5px' }} type="primary" >+Ajouter</Button>
                </Col>
            </Row>
            <Form
                name="loginForm"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                labelCol={{ span: 10 }}
            >
                <Table dataSource={dataa}>
                    <Column title="Nom" dataIndex="name" key="Nom" />
                    <Column
                        s
                        title="message" style={{ color: 'red' }} dataIndex="message" key="mesage" />

                    <Column
                        title="Action"
                        key="action"
                        render={(_, record) => (
                            <Space size="middle">
                                {/*}
                                <Form.Item
                                    label="Nom d'utilisateur"
                                    name="username2"
                                    rules={[
                                        { required: true, message: "Veuillez saisir votre nom d'utilisateur!" }
                                    ]}
                                    initialValue={''}
                                >
                                    <Input />
                                </Form.Item>
{*/}
                                <Button onClick={() => props.setCurrentComponentObj({ name: 'editBot', object: record })} type="primary" primary>Modifier</Button>
                                <Button onClick={() => deleteBot(record.id)} type="primary" danger>Supprimer</Button>
                            </Space>
                        )}
                    />
                    {/*}
                    <Column
                        title=<Button onClick={() => props.setCurrentComponentObj({ name: 'addRole' })} style={{ position: 'absolute', left: '-100px', bottom: '10px' }} type="primary" >Ajouter</Button>
                        key="add"
                    />
{*/}
                </Table>
            </Form>
        </>
    );
})


const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ListeBot);
