import React, { useState, forwardRef, useEffect, useCallback } from "react";
import { Select, Col, Button } from "antd";
import { connect } from "react-redux";
import { returnAllTemplateColors, setSavedByUserTemplate ,setSavedByUserTemplateUserSaved } from "../../../../endpoints/admin";

const SelectTemplate = forwardRef(({ user, ...props }, ref) => {
    const [templates, setTemplates] = useState([]); // ✅ Initialisation avec un tableau vide
    const [selectedTemplate, setSelectedTemplate] = useState("");

    // Fonction pour récupérer les templates
    const fetchTemplates = useCallback(async () => {
        try {
            const data = await returnAllTemplateColors(user.token, user.room, user.socketId);
            setTemplates(data.template || []); // ✅ Vérification avant d'ajouter les données
        } catch (error) {
            console.error("Erreur lors du chargement des templates :", error);
        }
    }, [user.token, user.room, user.socketId]);

    useEffect(() => {
        fetchTemplates();
    }, [fetchTemplates, props.reload]); // ✅ Dépendance `reload` pour forcer le rafraîchissement

    const handleChange = (value) => {
        setSelectedTemplate(value);
    };

    const changeTemplate = async () => {
        if (!selectedTemplate) return;

        try {
            await setSavedByUserTemplateUserSaved(selectedTemplate, user.token, user.room);
        } catch (error) {
            console.error("Erreur lors du changement de template :", error);
        }
    };

    const deleteTemplate = async () => {
        if (!selectedTemplate) return;

        try {
            setSelectedTemplate("");
            // await deleteSavedByUserTemplate(selectedTemplate, user.token, user.room); // 🔹 Décommente si cette API existe
            props.setReload(prev => !prev); // ✅ Force le rechargement des templates après suppression
        } catch (error) {
            console.error("Erreur lors de la suppression du template :", error);
        }
    };

    return (
        <>
            <Col ref={ref}>
                <Select
                    placeholder="Choisissez un template"
                    style={{ width: 220 }}
                    onChange={handleChange}
                    value={selectedTemplate || undefined} // ✅ Permet de réinitialiser l'affichage si `deleteTemplate` est appelé
                    options={templates?.map((template) => ({
                        value: template.id,
                        label: template.name,
                    }))}
                />
            </Col>
            <Col>
                <Button onClick={changeTemplate} disabled={!selectedTemplate}>Charger</Button>
                <Button onClick={changeTemplate} disabled={!selectedTemplate}>Updater</Button>
                <Button onClick={deleteTemplate} disabled={!selectedTemplate}>Supprimer</Button>
            </Col>
        </>
    );
});

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SelectTemplate);
