import React, { useState, useEffect, forwardRef } from 'react';
import { Button, Modal, Input, Select, Form, Switch } from 'antd';
import {  LockOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { updateUser, adminGetAllRoleApi } from '../../../../endpoints/admin'

const { Option } = Select;

const ModifyUser = forwardRef(({ user, ...props }, ref) => {

    const [roles, setRoles] = useState([]);
   //isModalOpenEditUser, setIsModalOpenEditUser

    const showModal = () => {
        props.setIsModalOpenEditUser(true);
    };
    const handleOk = () => {
        props.setIsModalOpenEditUser(false);
        props.setUserToModify('')
    };
    const handleCancel = () => {
        props.setIsModalOpenEditUser(false);
        props.setUserToModify('')
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onFinish = async (values) => {
        const userIdDb = props.userToModify.id
        values.id = userIdDb
        updateUser(values, user?.token, user?.room, user?.socketId).then(resp => {
        
            if (resp) {
                alert(resp)
            }
        })
    };

    useEffect(() => {
        adminGetAllRoleApi(user?.token, user?.room, user?.socketId).then(data => {
            setRoles(data.role);
        });
    }, [])

    return (<>
          <Modal title="Basic Modal" open={props.isModalOpenEditUser} onOk={handleOk} onCancel={handleCancel}>
        <Form
            name="basic"
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 16,
            }}
            style={{
                textAlign: 'start',
                marginTop: '10px',

            }}

            initialValues={{
                username: props.userToModify.username,
                email: props.userToModify.email,
                password: props.userToModify.password,
                role: props.userToModify.role, role: props.userToModify.role,
                gender: props.userToModify.gender,

            }}

            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                className="custom-form-item"
                label="Username"
                name="username"
                rules={[
                    {
                        required: true,
                        message: 'Please input your username!',
                    },
                ]}
            >
                <Input defaultValue={props.userToModify.username} />
            </Form.Item>

            <Form.Item
                className="custom-form-item"
                label="email"
                name="email"
                rules={[
                    {
                        required: true,
                        message: 'Please input your email!',
                    },
                ]}
            >
                <Input defaultValue={props.userToModify.email} />
            </Form.Item>

            <Form.Item
                className="custom-form-item"
                label="Password"
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }]}
            >
                <Input
                    defaultValue={props.userToModify.password}
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                />
            </Form.Item>

            <Form.Item
                className="custom-form-item"
                name="role"
                label="Role"
                rules={[
                    {
                        required: true,
                        message: 'Please select Role!',
                    },
                ]}
            >
                <Select placeholder="select your role" defaultValue={props.userToModify.role}>
                    if(roles){
                        roles.map((i) => (
                            <Option key={i.role} value={i.role}>{i.role}</Option>
                        ))
                    }
                </Select>
            </Form.Item>

            <Form.Item
                className="custom-form-item"
                name="gender"
                label="gender"
                rules={[
                    {
                        required: true,
                        message: 'Please select gender!',
                    },
                ]}
            >
                <Select placeholder="select your gender" defaultValue={props.userToModify.gender}>
                    <Option key="male" value="male">male</Option>
                    <Option key="femmelle" value="femelle">femelle</Option>
                    <Option key="other" value="other">other</Option>
                </Select>
            </Form.Item>

            <Form.Item
                className="custom-form-item"
                name="ban"
                label="Ban"
                wrapperCol={{
                    offset: 0,
                    span: 24,
                }}
                labelCol={{ span: 8, offset: 0 }} // Décalage d'une colonne vers la droite

                initialValue={props.userToModify.ban !== 0}
            >
                <Switch defaultChecked={props.userToModify.ban !== 0} />
            </Form.Item>

            <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 24,
                }}
            >
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>
        </Modal>
    </>
    );
})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ModifyUser);
