import { axios } from "../../../endpoints/axios_custom";

export const getContact = (token) => {
    return axios.get(`/getContact`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête getAllRoom sysop :", error);
            throw error;
        });
}



