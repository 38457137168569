import React, { useState, useEffect, useRef, Fragment, useContext, forwardRef } from 'react';
import { Button, Col, Row, Space, Modal, Input, Select, Avatar, Form, Checkbox, Switch, Table, Upload } from 'antd';
import { MenuUnfoldOutlined, LockOutlined, UploadOutlined } from '@ant-design/icons';
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp } from '../../../../action/action';
import { adminAddPunchImageApi } from '../../../../endpoints/admin'
import ModalShowRespondFromApi from './ModalShowRespondFromApi';


import { compressImage } from '../../chatUtils/CompressImage'

const { Option } = Select;

const { Column, ColumnGroup } = Table;

const AddPunch = forwardRef(({ app, testStore, designPage, user, onSearch, ...props }, ref) => {
  const [form] = Form.useForm();
  const [lockButton, setLockButton] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [message, setMessage] = useState(null)
  const [modalRespondApi, setModalRespondApi] = useState(false)

  const handleSelect = (value) => {
    setSearchValue(value);
    onSearch(value); // Appeler la fonction de recherche avec la valeur sélectionnée
  };

  const normFile = (e) => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onFinish = async (values) => {
    let file = null
    let formData = new FormData();

    if (values?.upload) {
      file = await compressImage(values.upload[0].originFileObj)

      formData.append("upload", file);
    }

    adminAddPunchImageApi(values, user?.token, user?.room, user?.socketId, formData, user?.username).then(resp => {

      if (!resp.permission) {
        setMessage(resp.message)
        setModalRespondApi(true)
      }
      else {
        props.setCurrentComponentObj({ name: 'Punch' })
        form.resetFields();
      }

    })

  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {

  }, [])

  return (

    <>
      <ModalShowRespondFromApi message={message} modalRespondApi={modalRespondApi} setModalRespondApi={setModalRespondApi} />
      <Form
        form={form}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          textAlign: 'start',
          marginTop: '10px',

        }}

        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >

        <Form.Item
          className="custom-form-item"
          label="Titre de l'image punch"
          name="file_name"
          rules={[
            {
              required: true,
              message: 'Please input your title!',
            },

          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          className="custom-form-item"
          labelCol={{ span: 8 }}
          wrapperCol={{ offset: 0, span: 16 }}
          name="upload"
          label="Image Punch"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[
            {
              required: true,
              message: 'Please select File!',
            },
          ]}
        >
          <Upload name="logo" listType="picture">
            <Button icon={<UploadOutlined />}>Click to upload</Button>
          </Upload>
        </Form.Item>

        <Form.Item
          className="custom-form-item"
          labelCol={{ span: 8 }}
          wrapperCol={{ offset: 0, span: 16 }}
          name="active"
          label="Active"
          valuePropName="checked" // Utilisez valuePropName pour définir la propriété utilisée pour la valeur
          initialValue={false}

        >
          <Switch />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 24,
          }}
        >
          <Button disabled={lockButton} type="primary" htmlType="submit">
            Ajouter
          </Button>
        </Form.Item>

      </Form>

    </>
  );

})


const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AddPunch);