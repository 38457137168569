import React, { useState, useEffect, useRef, Fragment, useContext, forwardRef } from 'react';
import { Button, Col, Row, Space, Modal, Input, Select, Avatar, Form, Checkbox, Switch, Table } from 'antd';
import { MenuUnfoldOutlined, LockOutlined } from '@ant-design/icons';
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp } from '../../../../action/action';
import { adminGetAllRoleApi ,adminAddUserApi } from '../../../../endpoints/admin'
const { Option } = Select;

const { Column, ColumnGroup } = Table;

const AddUser = forwardRef(({ app, testStore, designPage, user, onSearch, ...props }, ref) => {
  const [form] = Form.useForm();
  const [dataa, setDataa] = useState([])
  const [options, setOptions] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const handleSelect = (value) => {
    setSearchValue(value);
    onSearch(value); // Appeler la fonction de recherche avec la valeur sélectionnée
  };

  const onFinish = async (values) => {
    adminAddUserApi(values, user?.token, user?.room, user?.socketId).then(resp => {
        form.resetFields();
    })
};

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    form.setFieldsValue({
      gender: 'male',
      role : 'user'
   
    });
  }, []);


  useEffect(() => {
    adminGetAllRoleApi(user?.token, user?.room, user?.socketId).then(resp => {
      setDataa(resp.role)
    })

  }, [])
  return (

    
    <>
      <Form
       form={form}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          textAlign: 'start',
          marginTop: '10px',

        }}

        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >

        <Form.Item
          className="custom-form-item"
          label="Username"
          name="username"
          rules={[
            {
              required: true,
              message: 'Please input your username!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || !/\s/.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Username should not contain spaces.'));
              },
            }),
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          className="custom-form-item"
          name="gender"
          label="gender"
          rules={[
            {
              required: true,
              message: 'Please select gender!',
            },
          ]}
        >
          <Select placeholder="select your gender">
            <Option value="male">male</Option>
            <Option value="femelle">femelle</Option>
            <Option value="other">other</Option>
          </Select>
        </Form.Item>

        <Form.Item
          className="custom-form-item"
          name="age"
          label="Age"
          rules={[
            {
              required: true,
              message: 'Please select age!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (value >= 18 && value <= 99) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Age must be between 18 and 99.'));
              },
            }),
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          className="custom-form-item"
          name="email"
          label="Email"
          rules={[
            {
              type: 'email',
              message: 'Please enter a valid email!',
            },
            {
              required: true,
              message: 'Please enter your email!',
            },
          ]}
        >
          <Input />
        </Form.Item>


        <Form.Item
          className="custom-form-item"
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input

            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>

        <Form.Item
          className="custom-form-item"
          name="role"
          label="Role"
          rules={[
            {
              required: true,
              message: 'Please select Role!',
            },
          ]}
        >
          <Select placeholder="select your role">


            {dataa.map(role => (
              <Option key={role.role} value={role.role}>{role.role}</Option>

            ))}

          </Select>
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 24,
          }}
        >
          <Button type="primary" htmlType="submit">
            Ajouter
          </Button>
        </Form.Item>

      </Form>
    </>
  );

})


const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AddUser);

