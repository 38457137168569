import { SET_INIT, SET_TEST, SET_USER ,SET_MESSAGE ,SET_DESIGNPAGE } from "../constant/constant";


const setInitApp = (payload) => ({
    payload,
    type: SET_INIT
})

const setTestApp = (payload) => ({
    payload,
    type: SET_TEST
})

const setDesignPage = (payload) => ({
    payload,
    type: SET_DESIGNPAGE
})

const setUserApp = (payload) => ({
    payload,
    type: SET_USER
})

const setMessageApp = (payload) => ({
    payload,
    type: SET_MESSAGE
})

export { setInitApp, setTestApp, setUserApp ,setMessageApp ,setDesignPage }