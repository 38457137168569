import React, { useState,forwardRef } from 'react';
import { Button, Input, Select, Form, Upload, Switch } from 'antd';
import { Spin } from 'antd';
import { UploadOutlined, LockOutlined, LoadingOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';

import { updateRoom } from '../../../../endpoints/admin'
import { compressImage } from '../../chatUtils/CompressImage'

const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

const { Option } = Select;

const ModifyRoom = forwardRef(({ user, ...props }, ref) => {

    const [spiner, setSpiner] = useState(false)
    const [fileList, setFileList] = useState();

    const onFinish = async (values) => {
        try {
            let file = null;
            let formData = new FormData();

            if (values.upload) {
                file = await compressImage(values.upload[0].originFileObj);
                formData.append("file", file);
            }


            formData.append("roomId", props.currentComponentObj.object.id);
            formData.append("title", values.title);
            formData.append("description", values.description);
            formData.append("userMaximum", values.userMaximum);
            formData.append("password", values.password);
            formData.append("active", values.active);
            formData.append("show_history_on_connect", values.show_history_on_connect);

            setSpiner(true);

            await updateRoom(formData, user?.token, user?.room, user?.socketId);

            // L'attente peut ne pas être nécessaire, en fonction de la logique de votre application
            setTimeout(() => {
                setSpiner(false);
            }, 3000);
        } catch (error) {
            console.error("Une erreur s'est produite lors de l'envoi du formulaire :", error);
            setSpiner(false);
            // Gérer l'erreur comme nécessaire
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const onChangeLogo = ({ fileList: newFileList }) => {
        setFileList(newFileList);

    };

    const customRequest = ({ file, onSuccess, onError }) => {
        onSuccess(true, file);
    }

    const beforeUpload = (file) => {
        // Return false to prevent automatic upload
        // You can perform additional checks or show a modal/dialog here
        return false;
    };

    return (
        <div style={{ width: '100%' }}>

            <Form
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                style={{
                    marginTop: '10px',
                }}

                initialValues={{
                    title: props.currentComponentObj.object.title,
                    description: props.currentComponentObj.object.description,
                    userMaximum: props.currentComponentObj.object.capacity_max,
                    password: props.currentComponentObj.object.password,
                    active: props.currentComponentObj.object.active,
                    show_history_on_connect: props.currentComponentObj.object.show_history_on_connect,
                }}

                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ offset: 0, span: 16 }}
                    label="title"
                    name="title"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your title!',
                        },
                    ]}
                >
                    <Input defaultValue={props.currentComponentObj.object.title} />
                </Form.Item>

                <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ offset: 0, span: 16 }}
                    label="description"
                    name="description"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your description!',
                        },
                    ]}
                >
                    <Input defaultValue={props.currentComponentObj.object.description} />
                </Form.Item>

                <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ offset: 0, span: 16 }}
                    label="User Maximum"
                    name="userMaximum"
                    rules={[
                        {
                            required: true,
                            message: 'Veuillez entrer le nombre maximum d\'utilisateurs!',
                        },
                        {
                            pattern: /^[0-9]+$/,
                            message: 'Veuillez entrer uniquement des chiffres..',
                        },
                    ]}
                >
                    <Input defaultValue={props.currentComponentObj.object.capacity_max} />

                </Form.Item>

                <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ offset: 0, span: 16 }}
                    label="Password"
                    name="password"
                    rules={[{ required: false, message: 'Please input your password!' }]}
                >
                    <Input
                        defaultValue={props.currentComponentObj.object.password}
                        prefix={<LockOutlined />}
                        type="password"
                        placeholder="Password"
                    />
                </Form.Item>

                <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ offset: 0, span: 16 }}
                    name="upload"
                    label="Upload"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}

                >
                    <Upload name="file" listType="picture"
                        onChange={onChangeLogo}
                        beforeUpload={beforeUpload}
                        accept=".jpg, .jpeg, .png, .gif, .bmp"
                        maxCount={1}
                    >
                        {props.currentComponentObj.object.logo}
                        <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </Upload>
                </Form.Item>

                <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ offset: 0, span: 16 }}
                    name="active"
                    label="Active" valuePropName={props.currentComponentObj.object.active != 0 ? 'checked' : 'checked'}
                >
                    <Switch defaultChecked={props.currentComponentObj.object.active === 1} />
                </Form.Item>

                <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ offset: 0, span: 16 }}
                    name="show_history_on_connect"
                    label="Historique on connect" valuePropName={props.currentComponentObj.object.show_history_on_connect != 0 ? 'checked' : 'checked'}
                >
                    <Switch defaultChecked={props.currentComponentObj.object.show_history_on_connect === 1} />
                </Form.Item>

                <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 24,
                    }}
                >
                    {
                        spiner
                        &&
                        <Spin
                            indicator={
                                <LoadingOutlined
                                    style={{
                                        fontSize: 24,
                                    }}
                                    spin
                                />
                            }
                        />
                    }

                    <Button type="primary" htmlType="submit" disabled={spiner}>
                        Envoyer
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );

})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ModifyRoom);
