import React, { useState } from 'react';
import { Modal, Input, Button, Row, Col } from 'antd';

const GiphySearch = (props) => {

    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    const handleSearch = async () => {
        fetch("https://api.giphy.com/v1/gifs/search?q=" +
            encodeURIComponent(searchTerm) +
            "&api_key=OU3ApZVikO0v4wA5tj3P8xEYWwRyWA3Z&limit=150&rating=r")
            .then(res => res.json())
            .then(
                (result) => {
                    setSearchResults(result.data);
                    props.setModalVisibleGiphySearch(true);
                },
                (error) => {
                    console.error(error);
                }
            );
    };

    const closeModal = () => {
        props.setModalVisibleGiphySearch(false);
    };

    const handlerSelect = (url) => {
        const cleanUrl = url.replace(/^(https?:\/\/.*\.(bmp|gif|jpe?g|png|svg|tiff?|webp))(\?.*)?$/i, '$1');
        props.setText(cleanUrl);
        props.setModalVisibleGiphySearch(false);
    };
    
    const handleKeyDown = (e) => {
        if (e.code === 'Enter') {
            handleSearch()
        }
        
      };

    return (
        <div>
            <Modal
                title="Résultats de recherche"
                open={props.modalVisibleGiphySearch}
                onCancel={closeModal}
                footer={null}
            >
                <Row >

                    <Col span={18}>
                        <Input
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder="Rechercher des images Giphy"
                            onKeyDown={handleKeyDown}
                        />
                    </Col>
                    <Col span={6}>
                        <Button onClick={handleSearch}>Rechercher</Button>
                    </Col>
                </Row>

                {searchResults.map((gif) => (
                    <img onClick={() => handlerSelect(gif.images.fixed_height.url) } key={gif.id} src={gif.images.fixed_height.url} style={{ height: '80px' }} alt={gif.title} />
                ))}

            </Modal>
        </div>
    );
};

export default GiphySearch;
