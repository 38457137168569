import React, { useState } from 'react';
import { AppstoreOutlined, MailOutlined, SettingOutlined, ToolOutlined, DatabaseOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { Menu } from 'antd';
const items = [
    {
        label: 'Clients',
        key: 'client',
        icon: <AppstoreOutlined />,
    },
    { 
        label: 'Rooms Lives',
        key: 'rooms',
        icon: <AppstoreOutlined />,
    },
    {
        label: 'DB Explorer',
        key: 'db_explorer',
        icon: <AppstoreOutlined />,
    },
    {
        label: 'Messages',
        key: 'contact',
        icon: <MailOutlined />,

    },
    {
        label: 'Serveur',
        key: 'server',
        icon: <SettingOutlined />,
        children: [

            {
                label: 'Memory',
                key: 'memory',
            },
            {
                label: 'Log errors',
                key: 'log',
            },
            {
                label: 'User online',
                key: 'connected',
            },


        ],
    },
    {
        key: 'alipay',
        label: (
            <a href="https://ant.design" target="_blank" rel="noopener noreferrer">
                Navigation directe
            </a>
        ),
    },
];
const Nav = ({ app, testStore, designPage, user, ...props }) => {
   
    const onClick = (e) => {
        props.setCurrentComponent({component:e.key});
    };
    return <Menu onClick={onClick} selectedKeys={[props.currentComponent]} mode="horizontal" items={items} />;
};
const mapStateToProps = ({ user, message }) => ({ user, message });
export default connect(mapStateToProps)(Nav);