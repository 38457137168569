import { axios } from "./axios_custom"

export const contact = (data) => {
    return axios.post(`/contact`, data)
        .then(({ data }) => data)
        .catch((error) => {
            if (error.response && error.response.status === 429) {
                // Afficher un message d'erreur dans l'interface utilisateur
                // Vous pouvez utiliser un composant d'alerte ou un autre moyen pour afficher le message
                console.error("Trop de requêtes, veuillez réessayer plus tard :", error.response.data);
            } else {
                console.error("Une erreur s'est produite lors de l'envois du formulaire :", error);
            }
            throw error;
        });
}


