import {useState, useEffect, useRef, Fragment, useContext, forwardRef } from 'react';
import {Col, Row,Image } from 'antd';
import {Avatar, Conversation } from '@chatscope/chat-ui-kit-react';
import {connect } from 'react-redux';
import InformRoomUserEntrance from './InformRoomUserEntrance';
import MessageFromSysteme from './MessageFromSysteme';
import {LinearGradient } from 'react-text-gradients'
import LikeComments from './LikeComments';
import TimeAgo from 'react-timeago';
import frenchStrings from 'react-timeago/lib/language-strings/fr'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import ShowReactionLike from './ShowReactionLike';
import ReceivedVoice from './ReceivedVoice';
const formatter = buildFormatter(frenchStrings)

const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

const MessageFromUser = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {

    // Custom formatter to display only minutes and not show seconds
    const customFormatter = buildFormatter({
        minute: (value, unit, suffix) => {
            return `${value} minute`;
        },
        minutes: (value, unit, suffix) => {
            return `${value} minutes`;
        },
        second: () => '-1 minute', // Fallback to 1 minute if seconds are not present
        seconds: () => '-1 minute', // Fallback to 1 minute if seconds are not present
    });


    const [editMessage, SetEditMessage] = useState(false)
    const messageToEdit = useRef(null)
    const [messageServeur, setMessageServeur] = useState(null)
    /// ------------------------------------------------------------------------------
    const [visibleLikeButton, setVisibleLikeButton] = useState(false);

    const [currentTime, setCurrentTime] = useState(Date.now());

    const OpenModaleditUserMessage = (obj) => {
        SetEditMessage(true)
        messageToEdit.current = obj
    }
    const closeModal = () => {
        SetEditMessage(false);
        setMessageServeur(null)
    };

    const timeStyles = {
        color: ref.templateTexteColorState.conversationMoment,
        // Ajoutez d'autres styles au besoin
    };

    useEffect(() => {
        const elements = document.querySelectorAll(".cs-conversation__info");
        elements.forEach(el => {
            el.style.whiteSpace = "normal";
        });
    }, []);

    useEffect(() => {
        let animationFrameId;
        function animate(timestamp) {
            setCurrentTime(Date.now());
            animationFrameId = requestAnimationFrame(animate);
        }

        // Démarrer l'animation
        animationFrameId = requestAnimationFrame(animate);

        // Nettoyer le frame d'animation lors du démontage du composant
        return () => cancelAnimationFrame(animationFrameId);
    }, [setCurrentTime]);

    // Utiliser un intervalle pour mettre à jour le temps toutes les 60 secondes
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(Date.now());
        }, 60000); // Mettre à jour toutes les 60 secondes

        // Nettoyer l'intervalle lors du démontage du composant
        return () => clearInterval(intervalId);
    }, []);
    useEffect(() => {

    }, [props.message]);

    const getGradientStyle = (colors) => {
        if (colors && colors.length === 2) {
            return {
                background: `linear-gradient(90deg, ${colors[0]}, ${colors[1]})`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
            };
        }
        return {};
    };

    return (
      
        <div onMouseOut={() => setVisibleLikeButton(false)} onMouseOver={() => setVisibleLikeButton(true)} key={props.index + 1} style={{ display: 'flex', justifyContent: 'flex-start' }} >
            <div key={props.index + 2} style={{ flex: '0 0 97%' }}>
                {
                    props.message.type == 'messageBuyChat' || props.message.type == 'bot' ? <MessageFromSysteme ref={ref} msg={props.message} /> :
                    
                props.message.type == 'messageSysteme' ? <InformRoomUserEntrance ref={ref} message={props.message} /> :
                <Conversation
                    style={{ backgroundColor: ref.templateTexteColorState.conversationActive, cursor: 'auto' }}
                    key={props.index}
                    name={<span style={{ color: props.message.roleColor }}>
                        {props.message.from}

                        <span style={{ paddingLeft: '5px', fontSize: '10px' }}>
                            <ShowReactionLike message={props.message} socket={props.socket} />
                            <TimeAgo style={timeStyles} date={props.message.time} formatter={customFormatter} live={true} locale="fr" />

                        </span>

                    </span>}
                    lastSenderName={props.message.username}

                    info={/^https?:\/\/.*\.(bmp|gif|jpe?g|png|svg|tiff?|webp)$/i.test(props.message.message)
                        ?
                        <>
                            <Row>
                                <Col span={5} style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Image src={props.message.message} style={{ paddingBottom: '5px' }} alt="Image" height={150} />
                                </Col>
                                <Col style={{ paddingLeft: '5px', marginTop: 'auto', padding: '10px' }} span={5} >
                                    <LikeComments ref={ref} socket={props.socket} message={props.message} visibleLikeButton={visibleLikeButton} setVisibleLikeButton={setVisibleLikeButton} />
                                </Col>
                            </Row>
                        </>
                        :
                        <Row style={{}}>
                            <Col
                                style={{
                                    width:'100%',
                                    marginBottom:'-10px',
                                    ...getGradientStyle(props.message?.gradientColors),
                                    fontWeight: props.message?.formatMessage?.bold ? 'bold' : 'normal',
                                    fontStyle: props.message?.formatMessage?.italic ? 'italic' : 'normal',
                                    textDecoration: props.message?.formatMessage?.underline ? 'underline' : 'none',
                                    fontSize: '15px',
                                    position: 'relative', // Pour positionner l'effet de glow
                                    fontFamily: props.message?.formatMessage?.font,
                                    color: props.message?.formatMessage?.colorText,
                                    fontSize: props.message?.formatMessage?.fontSize
                                }}
                            >
                                {props.message?.gradientColors?.length === 2 ? (
                                    <LinearGradient
                                        gradient={[
                                            'to left',
                                            props.message.gradientColors[0],
                                            props.message.gradientColors[1]
                                        ]}
                                    >
                                        {props.message?.message}
                                    </LinearGradient>
                                ) : (
                                    props.message.type === 'voice' 
                                     ?
                                     <ReceivedVoice ref={ref} msg={props.message} /> 
                                     : 
                                      props.message?.message 
                                  
                                )}
                            </Col>


                            <Col style={{ paddingLeft: '5px',marginTop:'15px' }} >
                                <LikeComments ref={ref} socket={props.socket} message={props.message} visibleLikeButton={visibleLikeButton} setVisibleLikeButton={setVisibleLikeButton} />
                            </Col>
                        </Row>
                    }
                    lastActivityTime={
                        null
                    }
                >
                    <Avatar
                        src={`${URL}/${props.message.avatar}`}
                        name={'props.message.username'}
                        status={props.message.status}
                    />
                </Conversation>
                }

            </div>
        </div>
      
    );

})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(MessageFromUser);