import { useState, forwardRef, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Select, Modal, Button } from 'antd';
import { SketchPicker } from 'react-color';
import NiceAvatar, { genConfig } from 'react-nice-avatar';
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';

const { Option } = Select;

const AvatorGenerator = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {
    const [avatarConfig, setAvatarConfig] = useState(genConfig());
    const [colorType, setColorType] = useState(null);
    const [colorModalVisible, setColorModalVisible] = useState(false);
    const avatarRef = useRef(null);

    const updateConfig = (key, value) => {
        if (key === 'sex') {
            const newConfig = genConfig({ sex: value, hairColor: value });

            setAvatarConfig(newConfig);
        } else {
            setAvatarConfig((prevConfig) => ({
                ...prevConfig,
                [key]: value
            }));
        }
    };

    const saveAvatarAsImage = async () => {
        const node = avatarRef.current;

        if (node) {
            const scale = 3; // 🔥 Améliore la qualité de l'image
            const options = {
                width: node.offsetWidth * scale,
                height: node.offsetHeight * scale,
                style: {
                    transform: `scale(${scale})`,   // Agrandit l'image temporairement
                    transformOrigin: 'top left',   // ✅ Évite les déformations
                    borderRadius: '0',             // ✅ Supprime temporairement les bords arrondis pour éviter les coupures
                    backgroundColor: 'white'       // ✅ Fond blanc pour éviter la transparence par défaut
                }
            };

            try {
                const blob = await domtoimage.toBlob(node, options);
                saveAs(blob, "avatar.png");
            } catch (error) {
                console.error("Erreur lors de la capture de l'avatar :", error);
            }
        } else {
            console.error("L'élément avatar n'a pas été trouvé.");
        }
    };




    const handleOk = () => props.SetAvatorGenerator(false);
    const handleCancel = () => props.SetAvatorGenerator(false);

    const openColorModal = (type) => {
        setColorType(type);
        setColorModalVisible(true);
    };

    const handleColorChange = (color) => {
        console.log(`Couleur changée pour ${colorType} :`, color.hex);
        if (colorType) {
            updateConfig(colorType, color.hex);
        }
    };

    const handleColorModalOk = () => {
        setColorModalVisible(false);
    };

    const resetAvatar = () => {
        const initialConfig = genConfig(); // Recrée une configuration initiale aléatoire
        setAvatarConfig(initialConfig);
        console.log('Configuration réinitialisée :', initialConfig);
    };

    useEffect(() => {
        console.log('Config initial:', avatarConfig);
    }, []);

    return (
        <Modal width={800} title="Éditeur d'Avatar" open={props.modalAvatorGenerator} onOk={handleOk} onCancel={handleCancel}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '15px', padding: '20px' }}>
                <div
                    ref={avatarRef}
                    style={{
                        width: '200px',             // 🔥 Taille carrée plus grande pour une meilleure qualité
                        height: '200px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'white',
                        borderRadius: '50%',         // Bordure arrondie pour un effet correct
                        overflow: 'hidden'           // ✅ Important pour éviter les coupures d'image
                    }}
                >
                    <NiceAvatar style={{ width: '100%', height: '100%' }} {...avatarConfig} />
                </div>




                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '15px', width: '100%' }}>
                    {[
                        { label: 'Sexe', key: 'sex', options: ['man', 'woman'] },
                        { label: 'Style des yeux', key: 'eyeStyle', options: ['circle', 'oval', 'smile'] },
                        { label: 'Lunettes', key: 'glassesStyle', options: ['none', 'round', 'square'] },
                        { label: 'Style du nez', key: 'noseStyle', options: ['short', 'long', 'round'] },
                        { label: 'Style de cheveux', key: 'hairStyle', options: ['normal', 'thick', 'mohawk', 'womanLong', 'womanShort'] },
                        { label: 'Taille des oreilles', key: 'earSize', options: ['small', 'big'] },
                        { label: 'Style de bouche', key: 'mouthStyle', options: ['peace', 'smile', 'laugh'] },
                        { label: 'Style du haut', key: 'shirtStyle', options: ['hoody', 'short', 'polo'] },
                        { label: 'Couleur des cheveux', key: 'hairColor', isColor: true },
                        { label: 'Couleur du visage', key: 'faceColor', isColor: true },
                        { label: 'Fond de l\'avatar', key: 'bgColor', isColor: true },

                    ].map(({ label, key, options, isColor }) => (
                        <div key={key} style={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                            <label>{label} :</label>
                            {isColor ? (
                                <Button onClick={() => openColorModal(key)}>Choisir la couleur</Button>
                            ) : (
                                <Select value={avatarConfig[key] || options[0]} onChange={(value) => updateConfig(key, value)}>
                                    {options.map(option => (
                                        <Option key={option} value={option}>{option}</Option>
                                    ))}
                                </Select>
                            )}
                        </div>
                    ))}
                    <Button
                        type="default"
                        onClick={resetAvatar}
                        style={{ marginTop: '20px', width: '100%' }}
                    >
                        🔄 Réinitialiser
                    </Button>

                    <Button
                        type="primary"
                        onClick={saveAvatarAsImage}
                        style={{ marginTop: '20px', width: '100%' }}
                    >
                        📥 Télécharger l'Avatar
                    </Button>
                </div>
            </div>

            <Modal
                title="Choisir une couleur"
                open={colorModalVisible}
                onCancel={() => setColorModalVisible(false)}
                footer={[
                    <Button key="ok" type="primary" onClick={handleColorModalOk}>
                        OK
                    </Button>
                ]}
            >
                <SketchPicker
                    color={avatarConfig[colorType] || "#ffffff"}
                    onChangeComplete={handleColorChange}
                />
            </Modal>
        </Modal>
    );
});

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch });
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AvatorGenerator);