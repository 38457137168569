import { Space, Layout, Row, Col, Typography } from 'antd';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { getConnected } from '../enpoints/connected';
import { PayPalScriptProvider, PayPalButtons, FUNDING } from '@paypal/react-paypal-js';

const { Header, Footer, Content } = Layout;
const { Title } = Typography;

const Connected = ({ app, testStore, designPage, user, ...props }) => {
    const [dataa, setDataa] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const resp = await getConnected(user?.token);
                setDataa(resp.connected);
            } catch (error) {
                console.error("Error fetching user connected data:", error);
            }
        };

        fetchData();

        const intervalId = setInterval(fetchData, 5000);
        return () => clearInterval(intervalId);
    }, [user?.token]);

    return (
        <>
           

            <Title level={2}>Utilisateur connecté à socket.io</Title>
            <Row>
                <Col span={24}>
                    User connected: {dataa}
                </Col>
            </Row>
        </>
    );
};

const mapStateToProps = ({ user, message }) => ({ user, message });
export default connect(mapStateToProps)(Connected);
