import { useEffect, useState, forwardRef, useRef } from "react";
import { connect } from "react-redux";
import { SoundOutlined } from '@ant-design/icons';
import { Alert, Button } from 'antd';

const URL =
    process.env.REACT_APP_MODE_ENV === "development"
        ? process.env.REACT_APP_URL_SERVER_DEV
        : process.env.REACT_APP_URL_SERVER_PROD;

const ReceivedGift = forwardRef(({ user, ...props }, ref) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(null);

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    const handleEnded = () => setIsPlaying(false);
    audio.addEventListener('ended', handleEnded);

    return () => {
        audio.removeEventListener('ended', handleEnded);
        // Optionnellement, libérez l'audio si ce n'est plus nécessaire :
        audio.pause();
        audio.src = "";
    };
}, []);


    const togglePlay = () => {
        const audio = audioRef.current;

        if (audio.paused) {
            audio.play();
            setIsPlaying(true);
        } else {
            audio.pause();
            setIsPlaying(false);
        }
    };

    return (
        <>
            <Alert
                style={{
                   width:'100%',
                    display: 'flex',
                    alignItems: 'center',
                    background: 'linear-gradient(135deg, #e6f7ff, #bae7ff)',
                    border: '2px solid #1890ff',
                    borderRadius: '8px',
                    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.15)',
                }}
                icon={<SoundOutlined style={{ color: '#1890ff', fontSize: '28px' }} />}
                description={
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <span
                            style={{
                                flex: 1,
                                textAlign: 'center',
                                fontWeight: 'bold',
                                color: '#0050b3',
                            }}
                        >
                            🎙️ Un nouveau message vocal de : {props.msg.from}
                        </span>

                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <Button 
                                onClick={togglePlay} 
                                type={isPlaying ? "danger" : "primary"}
                            >
                                {isPlaying ? '⏸️ Pause' : '▶️ Lire'}
                            </Button>
                         
                            <audio ref={audioRef}>
                                <source src={`${URL}/${props?.msg.url}`} type="audio/ogg" />
                                <source src={`${URL}/${props?.msg.url}`} type="audio/mpeg" />
                                Votre navigateur ne supporte pas l'élément audio.
                            </audio>
                        </div>
                    </div>
                }
                type="info"
                showIcon
            />
        </>
    );
});

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ReceivedGift);
