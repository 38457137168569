import { useState, useEffect, forwardRef } from 'react';
import {Layout} from 'antd';
import { connect } from 'react-redux';
import Liste from '../rightSide/Liste'
const {  Sider } = Layout;

const UserList = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => 
{
    const [collapsed, setCollapsed] = useState(true);
    const [sliderWidth, setSliderWidth] = useState();
    const [intialWidth ,setIntialWidth] =useState();

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
        setSliderWidth(0);
    };

    useEffect(()=>{
        toggleCollapsed()
    },[user.userlist])

    useEffect(()=>{
        if(document.body.clientWidth < 800 ){
            setIntialWidth(200);
        }
        else{
            //depend si on es sur cell ou pas 
            setIntialWidth(300);
        }
    },[])

    return (
        <>
            <Sider style={{backgroundColor:'transparent'}} ref={ref.zoneUserList} width={intialWidth} collapsedWidth={sliderWidth}  collapsed={collapsed} onCollapse={setCollapsed} >
                <Liste socket={props.socket} ref={ref} startVideoProducer={props.startVideoProducer} flashMessageRef={props.flashMessageRef} />
            </Sider>
        </>
    )

})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(UserList);