import { useState, useContext } from 'react';
import _ from 'underscore';
import { connect } from 'react-redux';
import { SocketContext } from '../../SocketContext';
import { Modal, Button } from 'antd';

const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

const ModalAskQuestion = ({ app, testStore, designPage, user, ...props }) => {

    const socket = useContext(SocketContext);

    const [modalVisible, setModalVisible] = useState(true);

    const handleCloseModal = (value, propss) => {
        setModalVisible(false);
        props.setShowmodalQuestion(false)
        propss.respond = value
        socket.emit('respondQuestion', propss)
    };

    return (

        <Modal
            open={modalVisible}
            title={`Demande envoyer de : ${props.question.fromUser}`}
            onCancel={handleCloseModal}
            footer={[
                <Button key="no" onClick={() => handleCloseModal(false, props)}>
                    Non
                </Button>,
                <Button key="yes" type="primary" onClick={() => handleCloseModal(true, props)}>
                    Oui
                </Button>,
            ]}
        >
            <p>Autorisez-vous {props.question.fromUser} à accéder à votre webcam ? </p>
        </Modal>
    )
}

const mapStateToProps = ({ app, testStore, designPage, user }) => ({ app, testStore, designPage, user });
const mapDispatchToProps = dispatch => ({ dispatch });
export default connect(mapStateToProps, mapDispatchToProps)(ModalAskQuestion);