import React, { useState } from 'react';
import { Space, Layout, Form, Input, Button, Row, Col, Radio, Select } from 'antd';
import Nav from '../components/pages/layout/Nav';
import { registerRoom } from '../endpoints/registerRoom';
import '../css/register.css'

const { Header, Footer, Content } = Layout;
const actualPage = 'register';
const { Option } = Select;

const headerStyle = {
    textAlign: 'center',
    color: '#fff',
    height: 64,
    paddingInline: 10,
    lineHeight: '64px',
    backgroundColor: '#7dbcea',
};

const contentStyle = {
    textAlign: 'left',
    minHeight: '76vh',
    lineHeight: '60px',
    color: '#fff',
    backgroundColor: 'rgb(22, 22, 22)',
};

const footerStyle = {
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#7dbcea',
};

const Register = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);

    const handleSubmit = (values) => {
        setLoading(true);
        registerRoom(values)
            .then((resp) => {
                window.scrollTo({ top: 0, behavior: 'smooth' }); // Faire défiler la page vers le haut
                setLoading(false);
                setMessage(resp);
                form.resetFields(); // Réinitialiser les champs après soumission
                console.log('Données du formulaire :', values);
            })
            .catch((error) => {
                if (error.error) {
                    setMessage(error.error)
                    return
                }
                setLoading(false);
                if (error.response && error.response.status === 429) {
                    window.scrollTo({ top: 0, behavior: 'smooth' }); // Faire défiler la page vers le haut
                    setMessage(
                        "Vous êtes autorisé à envoyer un seul message. Veuillez réessayer plus tard."
                    );
                    console.error(
                        "Trop de requêtes, veuillez réessayer plus tard :",
                        error.response.data
                    );
                } else {
                    console.error("Une erreur s'est produite lors de l'envois du formulaire :", error);

                    console.error(
                        "Une erreur s'est produite lors de l'envoi du formulaire. Veuillez réessayer plus tard."
                    );
                }
            });
    };

    const validateConfirmPassword = ({ getFieldValue }) => ({
        validator(_, value) {
            if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
            }
            return Promise.reject(new Error('Les deux mots de passe ne correspondent pas.'));
        },
    });

    const ageOptions = [];
    for (let i = 1; i <= 100; i++) {
        ageOptions.push(<Option key={i} value={i}>{i}</Option>);
    }

    return (
        <Space direction="vertical" style={{ width: '100%', height: '100vh' }} size={[0, 48]}>
            <Layout>
                <Header style={headerStyle}>
                    <Nav pageSelected={actualPage} />
                </Header>
                <Content style={contentStyle}>
                    <h1 style={{ textAlign: 'center' }}>Créer un nouveau tchat</h1>
                    <h3 style={{ textAlign: 'center', color: message?.success ? '#52c41a' : 'rgb(132, 32, 41)' }}>{message?.msg}</h3>
                    <Row >
                        <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Form form={form} onFinish={handleSubmit} layout="vertical">
                                <Form.Item
                                    label={<label className="labelRegister">Nom du tchat</label>}
                                    name="title"
                                    rules={[{ required: true, message: 'Veuillez entrer le nom du tchat' }]}
                                >
                                    <Input size="large" maxLength={20} />
                                </Form.Item>


                                <Form.Item
                                    label={<label className="labelRegister">Template</label>}
                                    name="template"
                                    rules={[{ required: true, message: 'Veuillez sélectionner le template du tchat' }]}
                                >
                                    <Radio.Group>
                                        <Radio.Button value="webradio">Dédier webradio</Radio.Button>
                                        <Radio.Button value="standard">Standard</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>


                                <Form.Item
                                    label={<label className="labelRegister">Description</label>}
                                    name="description"
                                    rules={[{ required: true, message: 'Veuillez entrer une description du tchat' }]}
                                >
                                    <Input.TextArea rows={2} maxLength={100} />
                                </Form.Item>

                                <Form.Item
                                    label={<label className="labelRegister">Pseudo</label>}
                                    name="username"
                                    rules={[{ required: true, message: 'Veuillez entrer votre pseudo' }]}
                                >
                                    <Input size="large" maxLength={20} />
                                </Form.Item>

                                <Form.Item
                                    label={<label className="labelRegister">Prénom</label>}
                                    name="firstName"
                                    rules={[{ required: true, message: 'Veuillez entrer votre prénom' }]}
                                >
                                    <Input size="large" maxLength={40} />
                                </Form.Item>
                                <Form.Item
                                    label={<label className="labelRegister">Nom</label>}
                                    name="lastName"
                                    rules={[{ required: true, message: 'Veuillez entrer votre nom' }]}
                                >
                                    <Input size="large" maxLength={40} />
                                </Form.Item>
                                <Form.Item
                                    label={<label className="labelRegister">Email</label>}
                                    name="email"
                                    rules={[
                                        { required: true, message: 'Veuillez entrer votre adresse e-mail' },
                                        { type: 'email', message: 'Adresse e-mail invalide' },
                                    ]}
                                >
                                    <Input size="large" maxLength={60} />
                                </Form.Item>

                                <Form.Item
                                    label={<label className="labelRegister">Mot de passe</label>}
                                    name="password"
                                    rules={[{ required: true, message: 'Veuillez entrer votre mot de passe' }]}
                                >
                                    <Input.Password size="large" maxLength={50} />
                                </Form.Item>
                                <Form.Item
                                    label={<label className="labelRegister">Confirmer le mot de passe</label>}
                                    name="confirmPassword"
                                    dependencies={['password']}
                                    rules={[
                                        { required: true, message: 'Veuillez confirmer votre mot de passe' },
                                        validateConfirmPassword,
                                    ]}
                                >
                                    <Input.Password size="large" maxLength={50} />
                                </Form.Item>

                                <Form.Item
                                    label={<label className="labelRegister">Genre</label>}
                                    name="gender"
                                    rules={[{ required: true, message: 'Veuillez sélectionner votre genre' }]}
                                >
                                    <Radio.Group>
                                        <Radio.Button value="male">Homme</Radio.Button>
                                        <Radio.Button value="female">Femme</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>

                                <Form.Item
                                    label={<label className="labelRegister">Âge</label>}
                                    name="age"
                                    rules={[{ required: true, message: 'Veuillez sélectionner votre âge' }]}
                                >
                                    <Select placeholder="Sélectionnez votre âge">
                                        {ageOptions}
                                    </Select>
                                </Form.Item>

                                <Form.Item>
                                    <Button type="primary" htmlType="submit" loading={loading}>
                                        Créer le tchat
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </Content>
                <Footer style={footerStyle}>Footer</Footer>
            </Layout>
        </Space>
    );
};

export default Register
