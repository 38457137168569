import { useState, useEffect, useRef, forwardRef,useContext } from 'react';

const LOCATION_PHP_LUNA_FILE = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_PHP_FILE_PLAYER_DEV : process.env.REACT_APP_URL_PHP_FILE_PLAYER_PROD;


const PlayerApi = (props) => {
   const [shoutcast, setShoutcast] = useState(null);

   return (
     /*FILE LUNA PLAYER ARE ON SAME SERVER LOCATED IN FOLDER /PLAYER */
      <iframe src={`${LOCATION_PHP_LUNA_FILE}?id=${props.room}`} frameBorder="0" allowtransparency="true" width="100%" height="100%" scrolling="no" /> 
    );
    
}


export default PlayerApi ;