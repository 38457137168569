// requestModule.js
const sendRequest = (socket, type, data) => {
    return new Promise((resolve, reject) => {
      socket.emit(type, data, (err, response) => {
        if (!err) {
          resolve(response);
        } else {
          reject(err);
        }
      });
    });
  };
  
  export default sendRequest;