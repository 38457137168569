import { Space, Layout, Row, Col, Input, Button, Typography, Modal, Avatar, Table } from 'antd';
import { HomeOutlined, TeamOutlined, VideoCameraAddOutlined, EyeOutlined, UserOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { useEffect, useState, useContext } from 'react';
import { getAllRoomDbOwner, getAllUserInRoom } from '../../enpoints/rooms';
import { SocketContext } from '../../../../components/tchat/SocketContext';
import UserModalOptions from '../UserModalOptions';
import ModalShoutcastApi from '../ModalShoutcastApi';
import EditUser from './EditUser';
import EditRoom from './EditRoom';
import EditService from './EditService';


const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

const { Content } = Layout;
const { Title } = Typography;

const contentStyle = {
    maxHeight: '89vh',
    minHeight: '89vh',
    color: '#fff',
    backgroundColor: '#d9d9d9',
    padding: '15px'
};

const RoomSysop = ({ user }) => {
    const socket = useContext(SocketContext);
    const [searchTerm, setSearchTerm] = useState('');
    const [userSearchTerm, setUserSearchTerm] = useState('');
    const [originalData, setOriginalData] = useState([]);
    const [dataRoom, setDataRoom] = useState([]);
    const [focusRoomData, setFocusRoomData] = useState(null);
    const [totalUsersNetwork, setTotalUsersNetwork] = useState(null);
    const [totalRoomsNetwork, setTotalRoomNetwork] = useState(null);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [userToModify, setUserToModify] = useState(null);
    const [roomToModify, setRoomToModify] = useState(null);
    const [isModalOpenEditUser, setIsModalOpenEditUser] = useState(false);
    const [isModalOpenRoom, setIsModalOpenRoom] = useState(false);
    const [isModalOpenEditService, setIsModalOpenEditService] = useState(false);

    useEffect(() => {
        getAllRoomDbOwner(user?.token).then(resp => {
            setDataRoom(resp.room);
            setOriginalData(resp.room);
            const totalRooms = resp.room.length;
            setTotalRoomNetwork(totalRooms);
        });
    }, [user?.token]);

    useEffect(() => {
        const exampleFocusRoom = {};
        setFocusRoomData(exampleFocusRoom);
    }, []);

    const handleSearchRoom = (e) => {
        const searchValue = e.target.value;
        setSearchTerm(searchValue);
        const filteredRooms = originalData.filter((data) =>
            data.name.toString().toLowerCase().includes(searchValue.toLowerCase())
        );
        setDataRoom(filteredRooms);
    };

    const handleUserSearchUsers = (e) => {
        const inputValue = e.target.value || '';
        const searchValue = inputValue.toLowerCase().trim();
        setUserSearchTerm(searchValue);

        if (focusRoomData) {
            const filteredUsers = Object.values(focusRoomData).filter((user) =>
                user.username && typeof user.username === 'string' && user.username.toLowerCase().includes(searchValue)
            );
            setFilteredUsers(filteredUsers);
        }
    };

    const focusRoomHandler = (roomData) => {
        setUserSearchTerm('');
        getAllUserInRoom(roomData.name, user?.token).then(resp => {
            setFocusRoomData(resp.users);
            setTotalUsersNetwork(resp.users.length);
        });
    };

    const handlerModifyUser = (data) => {
        setUserToModify(data);
        setIsModalOpenEditUser(true);
    };

    const handlerModifyRoom = (data) => {
        setRoomToModify(data);
        setIsModalOpenRoom(true);
    };

    const handlerEditService = (data) => {
        setRoomToModify(data);
        setIsModalOpenEditService(true);
    };

    const columns = [
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
            render: (text, record) => (
                <span>
                    <Avatar size={32} src={`${URL}/${record.avatar}`} style={{ marginRight: '8px' }} />
                    {text}
                    <Button style={{ marginLeft: '8px' }} onClick={() => handlerModifyUser(record)}>edit</Button>
                </span>
            ),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'First Name',
            dataIndex: 'firstname',
            key: 'firstname',
        },
        {
            title: 'Last Name',
            dataIndex: 'lastname',
            key: 'lastname',
        },
        {
            title: 'Subscription',
            dataIndex: 'subscription',
            key: 'subscription',
        },
        {
            title: 'Register Date',
            dataIndex: 'register',
            key: 'register',
        },
    ];

    return (
        <>
            <Content style={contentStyle}>
                <Row>
                    <Col span={8}>
                        <Row style={{ color: 'grey', fontSize: '20px', fontWeight: 'bold', textAlign: 'left' }}>
                            <Col span={24}>
                                Last refresh: 2024-06-15 07:01:49
                            </Col>
                            <Col span={24}>
                                Last TC data change: 2024-06-15 08:00:24
                            </Col>
                        </Row>
                    </Col>
                    <Col span={8}>
                        <Title style={{ lineHeight: 0, marginTop: '10px' }}> Chat Explorer</Title>
                    </Col>
                    <Col style={{ color: 'grey', paddingRight: '20px', textAlign: 'right', fontWeight: 'bold' }} span={8}>
                        Times refreshed: 1815819
                    </Col>
                </Row>

                <Row style={{ paddingTop: '20px', fontWeight: 'bold' }}>
                    <Col style={{ fontSize: '25px', color: 'black' }} span={6}>
                        <HomeOutlined />
                        Rooms: {totalRoomsNetwork}
                    </Col>
                    <Col style={{ fontSize: '25px', color: 'black' }} span={6}>
                        <TeamOutlined />
                        Users:  {totalUsersNetwork}
                    </Col>
                    <Col style={{ fontSize: '25px', color: 'black' }} span={6}>
                        <VideoCameraAddOutlined />
                        Users broadcasting: 0
                    </Col>
                    <Col style={{ fontSize: '25px', color: 'black' }} span={6}>
                        <EyeOutlined />
                        Users watching: 0
                    </Col>
                </Row>

                <Row style={{ paddingTop: '45px' }}>
                    <Col span={8}>
                        <Row style={{ height: '62vh', overflowY: 'scroll', padding: '15px ' }}>
                            <Col style={{ textAlign: 'left' }} span={24}>
                                <Input
                                    style={{ width: '300px' }}
                                    prefix={<HomeOutlined />}
                                    placeholder="Search for Room"
                                    value={searchTerm}
                                    onChange={handleSearchRoom}
                                />
                                {dataRoom && dataRoom.map(room => (
                                    <Col key={room.id} span={24} style={{ cursor: 'help', marginTop: '15px', border: '7px solid #f5f5f5', borderRadius: '10px', padding: '10px' }}>
                                        <div style={{ backgroundColor: 'white', position: 'absolute', top: '-10px', borderRadius: '5px' }}>
                                            <div style={{ color: 'black' }}>Room {room.name}</div>
                                        </div>
                                        <Row style={{ fontSize: '25px', paddingTop: '15px', color: 'black' }}>
                                            <Col style={{ width: '25%' }}>
                                                <div style={{ color: 'black' }}><Button onClick={() => handlerModifyRoom(room)}>Modify</Button></div>
                                            </Col>
                                            <Col style={{ width: '25%' }}>
                                                <Button onClick={() => focusRoomHandler(room)}>Users</Button>
                                            </Col>
                                            <Col style={{ width: '25%' }}>
                                                <Button onClick={() => handlerEditService(room)}>Service</Button>
                                            </Col>
                                            <Col style={{ width: '25%' }}></Col>
                                        </Row>
                                    </Col>
                                ))}
                            </Col>
                        </Row>
                    </Col>

                    {focusRoomData && (
                        <Col span={16}>
                            <Row style={{ height: '62vh', overflowY: 'scroll', padding: '15px ' }}>
                                <Col style={{ textAlign: 'left' }} span={24}>
                                    <Input
                                        style={{ width: '300px' }}
                                        prefix={<TeamOutlined />}
                                        placeholder="Search for user"
                                        value={userSearchTerm}
                                        onChange={handleUserSearchUsers}
                                    />
                                    <Table
                                        columns={columns}
                                        dataSource={userSearchTerm === '' ? Object.values(focusRoomData) : filteredUsers}
                                        rowKey="username"
                                        pagination={true}
                                        style={{ marginTop: '15px', color: 'black' }}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    )}
                </Row>
            </Content>

            {userToModify && <EditUser setUserToModify={setUserToModify} userToModify={userToModify} isModalOpenEditUser={isModalOpenEditUser} setIsModalOpenEditUser={setIsModalOpenEditUser} />}
            {<EditRoom roomToModify={roomToModify} setRoomToModify={setRoomToModify} isModalOpenRoom={isModalOpenRoom} setIsModalOpenRoom={setIsModalOpenRoom} />}
            {<EditService roomToModify={roomToModify} setRoomToModify={setRoomToModify} isModalOpenEditService={isModalOpenEditService} setIsModalOpenEditService={setIsModalOpenEditService} />}
        </>
    );
};

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps)(RoomSysop);
