import {useState, useEffect, forwardRef } from 'react';
import { connect } from 'react-redux';
import { Conversation, Message } from '@chatscope/chat-ui-kit-react';
import TimeAgo from 'react-timeago';
import frenchStrings from 'react-timeago/lib/language-strings/fr'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
const formatter = buildFormatter(frenchStrings)

const InformRoomUserEntrance = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {

    const [currentTime, setCurrentTime] = useState(Date.now());
    const MessageId = crypto.randomUUID();

    const timeStyles = {
        color: ref.templateTexteColorState.conversationMoment,
        // Ajoutez d'autres styles au besoin
    };

    useEffect(() => {
        let animationFrameId;

        function animate(timestamp) {
            setCurrentTime(Date.now());
            animationFrameId = requestAnimationFrame(animate);
        }

        // Démarrer l'animation
        animationFrameId = requestAnimationFrame(animate);

        // Nettoyer le frame d'animation lors du démontage du composant
        return () => cancelAnimationFrame(animationFrameId);
    }, []); // Tableau de dépendances vide pour effectuer le montage et le démontage uniquement

    return (

        <Conversation
            style={{ color: 'orange', backgroundColor: ref.templateTexteColorState.conversationActive, cursor: 'auto' }}
            key={MessageId}
            info={
                <div style={{
                    fontSize: '15px',
                    height:'50px'
                }}>
                    <Message style={{ borderRadius: '3px' }} model={{

                    }}>
                        <Message.TextContent style={{ padding: '20px' }} text=<span style={{ borderRadius: '0px', margin: '1px', padding: '1px', color: props.message.messageColorSysteme, fontSize: '12px' }}>
                            {props.message.message}
                            <TimeAgo style={timeStyles} date={props.message.time} formatter={formatter} live={true} locale="fr" />
                        </span> />
                    </Message>
                </div>
            }
        >
        </Conversation>
    )
})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(InformRoomUserEntrance);