function startLocalWebcam(LocalvideoId, constraints) {
  return new Promise((resolve, reject) => {
    navigator.mediaDevices.getUserMedia(constraints)
      .then((stream) => {
        const videoElement = document.getElementById(LocalvideoId);
        const interval = setInterval(() => {
          if (videoElement) {
            videoElement.srcObject = stream;
            videoElement.play()
              .then(() => {
                clearInterval(interval);
                resolve(); // La vidéo a démarré avec succès
              })
              .catch((error) => {
                clearInterval(interval);
                reject('Erreur de démarrage de la vidéo : ' + error);
              });
          }
        }, 500);
      })
      .catch((error) => {
        reject('Erreur lors de l\'accès à la webcam : ' + error);
      });
  });
}

function stopWebcam(LocalvideoId, constraints) {
  return new Promise((resolve, reject) => {
    const videoElement = document.getElementById('localVideoPrivate');
    if (videoElement) {
      videoElement.pause();
      videoElement.srcObject = null;
      resolve();
    }
    else {
      reject('Erreur lors de l\'accès à la webcam : ');
    }
  });
}


function checkWebcamAvailability() {
  return navigator.mediaDevices.enumerateDevices()
    .then(devices => {
      const videoInputs = devices.filter(device => device.kind === 'videoinput');
      return videoInputs.length > 0;
    })
    .catch(error => {
      console.error('Erreur lors de la vérification des périphériques :', error);
      return false;
    });
}

module.exports = {
  startLocalWebcam, checkWebcamAvailability
};
