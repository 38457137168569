import React, { useState, useEffect,forwardRef } from 'react';
import { Col, Row, Select, Typography } from 'antd';
import { connect } from 'react-redux';
import { getRoomClass } from '../enpoints/rooms'
const { Title } = Typography;

const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

const { Option } = Select;

const ModifyRoom = forwardRef(({ user, ...props }, ref) => {

    const [spiner, setSpiner] = useState(false)
    const [message, setmessage] = useState(null)
    const [formKey, setFormKey] = useState(0); // Initial key value
    const [test, setTest] = useState('test')

    const [dataa, setDataa] = useState()

    function getObjectSize(obj) {
        const seenObjects = new Set();
        const stack = [obj];
        let bytes = 0;
    
        while (stack.length) {
            const value = stack.pop();
    
            if (typeof value === 'boolean') {
                bytes += 4;
            } else if (typeof value === 'string') {
                bytes += value.length * 2;
            } else if (typeof value === 'number') {
                bytes += 8;
            } else if (typeof value === 'object' && value !== null && !seenObjects.has(value)) {
                seenObjects.add(value);
                for (const key in value) {
                    if (Object.prototype.hasOwnProperty.call(value, key)) {
                        stack.push(value[key]);
                    }
                }
            }
        }
    
        return bytes;
    }
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getRoomClass(props.currentComponent.data.name, user?.token);
                response.room.test = getObjectSize(response.room)
                setDataa(response.room)

                setFormKey(prevKey => prevKey + 1); // Update form key to force reload
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        setInterval(() => {
            fetchData();
        }, 3000);
     
    }, [props.currentComponent.data.name]);

    return (
        <>

            <Row style={{ lineHeight: '30px' }}>
            <Col span={24} style={{ marginBottom: '5px' }}>
                    longeur: {dataa?.test}
                </Col>

                <Col span={24} style={{ marginBottom: '5px' }}>
                    Numero de la room: {dataa?.name}
                </Col>

                <Col span={24} style={{ marginBottom: '5px' }}>
                    label: {dataa?.label}
                </Col>

                <Col span={24} style={{ marginBottom: '5px' }}>
                    users: {dataa?.users.length}
                </Col>

                <Col key={formKey} span={24} style={{ paddingBottom: '5px' }}>
                    TokenPerMinute: {dataa?.TokenPerMinute}
                </Col>

                <Col span={24} style={{ marginBottom: '5px' }}>
                    allAudioBlocked: {String(dataa?.allAudioBlocked)}
                </Col>

                <Col span={24} style={{ marginBottom: '5px' }}>
                    allVideoBlocked: {String(dataa?.allVideoBlocked)}
                </Col>

                <Col span={24} style={{ marginBottom: '5px' }}>
                    videoProducers: {dataa && dataa.videoProducers && Object.keys(dataa.videoProducers).length}
                </Col>

                <Col span={24} style={{ marginBottom: '5px' }}>
                    audioProducers: {dataa && dataa.audioProducers && Object.keys(dataa.audioProducers).length}
                </Col>

                <Col span={24} style={{ marginBottom: '5px' }}>
                    chatHistoryMaxLenght: {dataa?.chatHistoryMaxLenght}
                </Col>

                <Col span={24} style={{ marginBottom: '5px' }}>
                    chatHistory: {dataa?.chatHistory.length}
                </Col>

                <Col span={24} style={{ marginBottom: '5px' }}>
                    consumerTransports: {dataa && dataa.consumerTransports && Object.keys(dataa.consumerTransports).length}
                </Col>

                <Col span={24} style={{ marginBottom: '5px' }}>
                    producerTransports: {dataa?.producerTransports.length}
                </Col>

                <Col span={24} style={{ marginBottom: '5px' }}>
                    videoConsumer: {dataa && dataa.videoConsumerSets && Object.keys(dataa.videoConsumerSets).length}
                </Col>
                <Col span={24} style={{ marginBottom: '5px' }}>
                    audioConsumer: {dataa && dataa.audioConsumerSets && Object.keys(dataa.audioConsumerSets).length}
                </Col>



            </Row>



        </>
    );
})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ModifyRoom);
