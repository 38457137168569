import { useState, useContext, useEffect, useRef, Fragment, useMemo, createRef, forwardRef } from 'react';
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp, setDesignPage } from '../../../../action/action';
import { SocketContext } from '../../SocketContext';
import { Form, Input, Button, message, Space, Typography, Col, Row, Menu, Modal, Badge } from "antd";
import ShowUserMobile from './ShowUserMobile'
import ModalAskQuestion from './ModalAskQuestion'
import ModalRoomSelect from './ModalRoomSelect'
import TokenVirtual from './tokenVirtual'
import PrivateTopLevel from '../center/PrivateTopLevel';
import { playAudio } from '../../chatUtils/SoundSysteme'
import { EventEmitter } from '../../../utility/Emitter'
import MessageUserFromSysop from './MessageFromSysop'

const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

const UserList2 = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {

    const socket = useContext(SocketContext);
    const [userList, setUserList] = useState([])
    const allWebcamIcoTheRefs = {}
    const iconeMicroRef = {}
    const userOpenModalRef = {}
    const userCloseModalRef = {}
    const userTypingIcoRef = useRef({});
    const [userCount, setUserCount] = useState(0);
    const [showmodalQuestion, setShowmodalQuestion] = useState(false);
    const [question, setQuestion] = useState(null);
    const [updateUserConsumer, setupdateUserConsumer] = useState()
    const [mute, setMute] = useState(false)
    const enterMp3 = useRef()
    const exitMp3 = useRef()
    const itemRefs = useRef([]);
    const [sysopMessage, setSysopMessage] = useState(null)

    const addUser = (value, title, newUser, idRoom) => {
        const { flashMessageRef } = props;
        if (newUser) {
            if (flashMessageRef.current) {

                EventEmitter().emit('informRoomUserEntrance', {
                    user: value,
                    message: 'Connexion',
                    state: 'in',
                    idRoom: idRoom
                })

                const newMessage = `${value.username} viens de se joindre ${title}`;
                const newMessageDiv = document.createElement('div');
                newMessageDiv.textContent = newMessage;
                flashMessageRef.current.insertAdjacentElement('beforebegin', newMessageDiv);

                const timer = setTimeout(() => {
                    newMessageDiv.remove();
                }, 5000);
                if (ref.SoundEffect.current) {
                    playAudio(ref.entranceMp3.current)
                }
            }
        }
  
        setUserList(userList => {
            // Recherche d'un utilisateur existant avec le même socketId
            const existingUserIndex = userList.findIndex(user => user.socketId === value.socketId);

            // Recherche d'un utilisateur existant avec le même username
            const existingUsernameIndex = userList.findIndex(user => user.username === value.username);

            // Si un utilisateur existant avec le même socketId est trouvé, ou si un utilisateur avec le même username est trouvé
            if (existingUserIndex !== -1 || existingUsernameIndex !== -1) {
                // Si un utilisateur avec le même socketId est trouvé, ou si un utilisateur avec le même username est trouvé
                // Remplace l'utilisateur existant par le nouveau
                const updatedList = [...userList];
                if (existingUserIndex !== -1) {
                    updatedList[existingUserIndex] = value;
                } else {
                    updatedList[existingUsernameIndex] = value;
                }
                return updatedList;
            } else {
                // Sinon, ajoute le nouvel utilisateur à la liste
                return [...userList, value];
            }
        });
    }

    const userQuit = async (value) => {
        setUserList(prevUserList => {
            // Filtrer les utilisateurs déconnectés et émettre un événement
            const updatedState = prevUserList.filter(user => {
                if (user.socketId === value.socketId) {
                    EventEmitter().emit('informRoomUserEntrance', {
                        user: user,
                        message: 'Déconnexion',
                        state: 'out',
                        idRoom: value.id
                    });
                    return false; // Ne pas inclure l'utilisateur déconnecté dans la nouvelle liste
                }
                return true; // Inclure les autres utilisateurs dans la nouvelle liste
            });

            // Jouer l'effet sonore si nécessaire
            if (ref.SoundEffect.current) {
                playAudio(ref.exitMp3.current);
            }
            return updatedState; // Retourner la nouvelle valeur du state
        });
    };

    useEffect(() => {
        if (mute != false && mute.socketId === user?.socketId) {

            props?.dispatch(setUserApp({ allowUserTyping: String(mute) }))
        }
    }, [mute]);

    const updateUserInfos = (userInfos) => {
      
        setUserList(prevUserList => {
            const updatedState = prevUserList.map(item => {
                if (item.socketId === userInfos.socketId) {
                    props?.dispatch(setUserApp({ token_virtual: userInfos.token_virtual }))

                    setMute(userInfos.blockUserMessage)
                    return userInfos;
                }
                return item;
            });
            return updatedState;
        });
    }

    const myInfos = (value, role, virtualTokenPerMinute) => {

        if (value.UserColorText) {
            /*set input color text message  */
            ref.UserColorText.current = value.UserColorText
        }

        ref.token_virtual.current = value.token_virtual
        props?.dispatch(setUserApp({ autoAccessMediaDevices: value.autoAccessMediaDevices, avatar: value.avatar, status: value.status, typeTab: 'room', roleColor: role?.role_color, role: role, token_virtual: value.token_virtual, virtualTokenPerMinute: virtualTokenPerMinute }))
    }

    const show_hideRoomSelect = (id) => {
        const openModal = userOpenModalRef[1]
        const closeModal = userCloseModalRef[1]
        if (openModal) {
            openModal.showModal()
            closeModal.addEventListener("click", () => {
                openModal.close();
            }, { passive: true });
        }
    }
    const askQuestion = (obj) => {
        setShowmodalQuestion(true)
        setQuestion(obj)
    }

    const respondQuestion = (value) => {
        user?.addConsumer(null, value.question.target.socketId, value.question.target.producerTransports, 'video', value.question.target.username, socket)
    }

    useEffect(() => {
        setUserList(prevUserList => {
            const updatedState = prevUserList.map(item => {
                if (item.socketId === updateUserConsumer.consumer.remoteId) {
                    return {
                        ...item,
                        userConsumer: updateUserConsumer.consumer,
                    };
                }
                return item;
            });
            return updatedState;
        });
    }, [updateUserConsumer]);

    const updateConsumer = (obj) => {
        setupdateUserConsumer(obj)
    }

    useEffect(() => {
        props?.dispatch(setUserApp({ updateConsumer: updateConsumer }))

        if (socket) {
            socket.on("getAllUser", (arrayUser, roomTitle) => {
                arrayUser.forEach(user => {
                    addUser(user, roomTitle);
                });
            });
            socket.on("askQuestion", askQuestion)
            socket.on("respondQuestion", respondQuestion)
            socket.on('userQuit', userQuit);
            socket.on("updateUserInfos", updateUserInfos)
            socket.on('userAsEnter', (value, valus, ifNewUser, idRoom) => addUser(value, valus, ifNewUser, idRoom));
            socket.on('myInfos', myInfos);
            socket.on('newProducer', () => console.log('new producer'));
            socket.on('userTyping', userTyping);
            socket.on('message_User_From_Sysop', handlermessageFromSysop);

            return () => {
                socket.off('userAsEnter', addUser);
                socket.off('userQuit', userQuit);
                socket.off("updateUserInfos", updateUserInfos)
                socket.off('myInfos', myInfos);
                socket.off('respondQuestion', respondQuestion)
                socket.off('askQuestion', askQuestion)
                socket.off('newProducer')
                socket.off('message_User_From_Sysop', handlermessageFromSysop);
            }
        };
    }, [socket]);

    const handlermessageFromSysop = (message) => {
       
        setSysopMessage(message)
    }

    const userTyping = (socketId) => {
        // userTypingIcoRef[socketId].current.textContent = 'aaaaaaaaaaaaa'
        if (userTypingIcoRef.current[socketId]) {
            userTypingIcoRef.current[socketId].style.display = 'block';
            setTimeout(() => {
                userTypingIcoRef.current[socketId].style.display = 'none';
            }, 3000);
        }
    }
    const filteredUserList = userList.filter(valueUser => valueUser.inRoomNumber.includes(Number(user?.selectedRoom)));

    const renderedUsers = filteredUserList.map((valueUser, index) => (
        <ShowUserMobile
            userTypingIcoRef={userTypingIcoRef}
            userinfos={valueUser}
            key={valueUser.socketId}
            allWebcamIcoTheRefs={allWebcamIcoTheRefs}
            iconeMicroRef={iconeMicroRef}
            ref={ref}
            itemRefs={itemRefs}
        />
    ));

    useEffect(() => {
        setUserCount(renderedUsers.length);
    }, [renderedUsers]);

    return (
        <>
            <PrivateTopLevel userlist={userList} ref={ref} />

            <aside style={{ visibility: 'open', backgroundColor: designPage?.color }} >
                <Row style={{ backgroundColor: '#222', margin: '7px', borderRadius: '0.25rem', padding: '3px' }}>
                    <Col style={{ color: 'white' }} span={8}>
                        <span style={{ fontWeight: 'normal', paddingRight: '5px' }} onClick={() => show_hideRoomSelect(1)}>
                            {user?.roomInfos.length > 1 ? 'Salons:' : 'Salon:'}
                        </span>
                        <Badge overflowCount={999999} size={"small"} count={user?.roomInfos.length} style={{ borderColor: 'transparent', backgroundColor: 'transparent', color: 'white' }} />
                    </Col>
                    {showmodalQuestion ? <ModalAskQuestion setShowmodalQuestion={setShowmodalQuestion} question={question} /> : null}

                    <Col style={{ color: 'white' }} span={8}>
                        <span style={{ paddingRight: '5px' }}>
                            {userCount > 1 ? 'users:' : 'user:'}
                        </span>
                        <span>
                            <Badge overflowCount={999999} size={"small"} count={userCount} style={{ borderColor: 'transparent', backgroundColor: 'transparent', color: 'white' }} />
                        </span>
                    </Col>
                    <Col span={8}>
                        <span>
                            <TokenVirtual ref={ref} />
                        </span>
                    </Col>
                </Row>

                <Row>
                    <Col style={{ color: 'white' }} span={24}>
                        <span >

                        </span>
                    </Col>
                </Row>
                {/* grandeur pour l'espace afficher les usernames dans la liste */}
               
                    <div  style={{width:'100%',display:'flex', padding: '0px 5px 5px 5px' }}>
                        {renderedUsers}
                    </div>
              
            </aside>
            <ModalRoomSelect userCloseModalRef={userCloseModalRef} userOpenModalRef={userOpenModalRef} />
            <MessageUserFromSysop sysopMessage={sysopMessage} />
        </>
    )
})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(UserList2);