import { useState, useEffect, useRef, Fragment, useContext, forwardRef } from 'react';
import { connect } from 'react-redux';
import { setUserApp } from '../../../../action/action';
import { } from '@chatscope/chat-ui-kit-react';
import { Col, Row, Button, Modal, Avatar, Divider } from 'antd';
import { SocketContext } from '../../SocketContext';
import { ShowAllGiftEndpoint } from '../../../../endpoints/giftEndpoint'
import * as InterfaceFunc from "../../../utils/ChatFunction"

const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

const ShowAllGift = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {

    const socket = useContext(SocketContext);
    const [allGift, setAllGift] = useState([]);
    const [isModalOpenGift, setIsModalOpenGift] = useState(true);


    const checkPermit = ({ action, ...datas }) => {
        props?.dispatch(setUserApp({ messageFromServeur: null }));
        if (Object.entries(datas).length) {
            InterfaceFunc[action](socket, user, datas.userinfos, datas.gift)
                .then(
                    function (data) {
                        props?.dispatch(setUserApp({ messageFromServeur: data }));
                    },
                    function (error) { props?.dispatch(setUserApp({ messageFromServeur: error })); }
                );
        }
    }

    const handleOkGift = () => {
        setIsModalOpenGift(false);
        props.setShowModalAllGift(false)
    };
    const handleCancelGift = () => {
        setIsModalOpenGift(false);
        props.setShowModalAllGift(false)
    };

useEffect(() => {
    const fetchGifts = async () => {
        try {
            if (isModalOpenGift) {
                const data = await ShowAllGiftEndpoint(user?.token, user?.room, user?.socketId);
                setAllGift(data.gift);
            }
        } catch (err) {
            console.error("Erreur lors du chargement des cadeaux :", err);
        }
    };

    fetchGifts();
}, [isModalOpenGift]);

    return (
        <>
            <Modal style={{ textAlign: 'center' }} title="Offrir un cadeau" open={isModalOpenGift} onOk={handleOkGift} onCancel={handleCancelGift}>
                <Row>
                    {
                        allGift.map((gift, key) => (
                            <Col span={4} >
                                <Row>
                                    <Col span={24} style={{ textAlign: 'center' }}>
                                        <Button onClick={() => checkPermit({ action: 'sendGif', userinfos: props.userinfos, gift: gift })} style={{ height: '56px' }}>
                                            <Avatar style={{ borderColor: 'grey', backgroundColor: 'white' }} shape="square" size={48} src={`${URL}/systeme/image/gift/giftNoAnimated/${gift.image}`} />
                                        </Button>
                                        <Col span={24}>{gift.name}</Col>
                                        <Col span={24}><img src={'/gold.png'} /> {gift.price} </Col>
                                    </Col>
                                </Row>
                            </Col>
                        ))
                    }
                    <Divider />
                   <div> {ref.templateTexteColorState.token_virtual}</div>  jetons restant
                </Row>
            </Modal>
        </>
    )
})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ShowAllGift);
