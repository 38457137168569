import { useState, forwardRef } from 'react';
import { connect } from 'react-redux';
import { SmileOutlined, SettingOutlined } from '@ant-design/icons';
import ModalEditMsg from './ModalEditMsg';
import { Popover, Row, Col } from 'antd';

const URL = process.env.REACT_APP_MODE_ENV === "development"
    ? process.env.REACT_APP_URL_SERVER_DEV
    : process.env.REACT_APP_URL_SERVER_PROD;

const LikeComments = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {
    const [modalEditMessage, setModalEditMessage] = useState(false)


    const reaction = (type) => {
        const idDb = user?.idDb; // ID de l'utilisateur qui réagit

        if (!idDb) {
            console.error("Erreur : idDb de l'utilisateur est requis pour réagir !");
            return;
        }

        // Copie du message pour éviter la modification directe des props
        const updatedMessage = { ...props.message };

        // S'assurer que `likeFrom` est un tableau (et non un objet ou undefined)
        if (!Array.isArray(updatedMessage.likeFrom)) {
            updatedMessage.likeFrom = updatedMessage.likeFrom ? [updatedMessage.likeFrom] : [];
        }

        // Recherche de l'index de l'objet avec le bon idDb
        const existingIndex = updatedMessage.likeFrom.findIndex((like) => like.idDb === idDb);

        if (existingIndex !== -1) {
            // ✅ Mise à jour de la réaction si l'ID existe déjà
            updatedMessage.likeFrom[existingIndex] = {
                ...updatedMessage.likeFrom[existingIndex],
                reaction: type,
            };
        } else {
            // ✅ Ajout d'une nouvelle réaction si l'ID n'existe pas
            updatedMessage.likeFrom.push({
                idDb,
                username: user?.username ?? "Anonyme",
                reaction: type,
            });
        }
        // Vérifier que originalMessage existe avant de modifier roomId
        if (!updatedMessage.originalMessage) {
            updatedMessage.originalMessage = {}; // Créer un objet si non défini
        }
        updatedMessage.originalMessage.roomId = user?.selectedRoom;
        updatedMessage.originalMessage.messageId = updatedMessage.messageId;
        // Envoi via WebSocket si disponible
        if (props.socket) {
            props.socket.emit("updateMessage", updatedMessage);
        }
    };
 
    const react = (
        <Row  gutter={3}>
            <Col>
                <Col className="hover-border-like-button">
                    <img
                        onClick={() => reaction('smile')}
                        style={{ height: '20px', cursor: 'pointer' }}
                        src={`${URL}/images/ico/smile.png`}
                        alt="smile"
                    />
                </Col>

                <Col className="hover-border-like-button">
                    <img
                        onClick={() => reaction('surprise')}
                        style={{ height: '20px', cursor: 'pointer' }}
                        src={`${URL}/images/ico/surprise.png`}
                        alt="surprise"
                    />
                </Col>

                <Col className="hover-border-like-button">
                    <img
                        onClick={() => reaction('sad')}
                        style={{ height: '20px', cursor: 'pointer' }}
                        src={`${URL}/images/ico/sad.png`}
                        alt="sad"
                    />
                </Col>

                <Col className="hover-border-like-button">
                    <img
                        onClick={() => reaction('angry')}
                        style={{ height: '20px', cursor: 'pointer' }}
                        src={`${URL}/images/ico/angry.png`}
                        alt="angry"
                    />
                </Col>

                <Col className="hover-border-like-button">
                    <img
                        onClick={() => reaction('thumbup')}
                        style={{ height: '20px', cursor: 'pointer' }}
                        src={`${URL}/images/ico/thumbup.png`}
                        alt="thumb up"
                    />
                </Col>
                <Col className="hover-border-like-button">
                    <img
                        onClick={() => reaction('heart')}
                        style={{ height: '20px', cursor: 'pointer' }}
                        src={`${URL}/images/ico/heart.png`}
                        alt="coeur"
                    />
                </Col>
            </Col>
        </Row>
    );

    const more = <div>more</div>;




    return (
        <>
            <div 
                style={{ 
                    display: 'flex', 
                    flexDirection: 'column', // Aligne les éléments verticalement
                    height: '100%',         // Assure que le conteneur occupe toute la hauteur disponible
                }}
            >
                {/* Contenu Principal */}
                <div style={{ flex: '1' }}>
                    {/* Insère ici ton contenu principal si nécessaire */}
                </div>
    
                {/* Icônes en bas */}
                <div style={{ marginTop: 'auto' }}>  {/* 🔥 Ajout du `marginTop: auto` pour forcer en bas */}
                    {props.visibleLikeButton && (
                        <>
                            <Row>
                                <Col>
                                    <Popover
                                        onOpenChange={(open) => {
                                            if (!open) {
                                                props.setVisibleLikeButton(false)
                                            }
                                        }}
                                        content={react}
                                    >
                                        <SmileOutlined style={{ fontSize: "15px", cursor: "pointer" }} />
                                    </Popover>
                                </Col>
                                <Col>
                                    {props.message.type !== 'messageSysteme' &&
                                     props.message.type !== 'messageBuyChat' &&
                                     props.message.bot !== true && (
                                        <div 
                                            style={{ cursor: 'pointer' }} 
                                            onClick={() => setModalEditMessage(!modalEditMessage)}
                                        >
                                            <SettingOutlined 
                                                style={{ paddingLeft: '5px', fontSize: "15px", cursor: "pointer" }} 
                                            />
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </>
                    )}
    
                    <ModalEditMsg 
                        setModalEditMessage={setModalEditMessage} 
                        message={props.message} 
                        modalEditMessage={modalEditMessage} 
                    />
                </div>
            </div>
        </>
    );
});

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(LikeComments);
