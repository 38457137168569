import { useState, useEffect, useRef, forwardRef } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Modal, Button, Input as AntInput, Typography, Divider, Card } from 'antd';
import EmojiPicker from 'emoji-picker-react';
import { replyUserMessage } from '../../../../endpoints/userMessage';

const { TextArea } = AntInput;
const { Title, Text } = Typography;
const { confirm } = Modal;  // ✅ Import du Modal.confirm()

const ModalReplyMessage = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {

    const [replyContent, setReplyContent] = useState('');
    const [subject, setSubject] = useState('');
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [message, setMessage] = useState('');
    const emojiPickerRef = useRef(null);

    useEffect(() => {
        if (props.message) {
            let parsedMessage = [];
            try {
                parsedMessage = JSON.parse(props.message.message).message;
            } catch (error) {
                console.error("Erreur lors du parsing du message :", error);
            }
            const firstMessageContent = parsedMessage[0]?.content || 'Aucun message disponible';
            setMessage(firstMessageContent);
            setSubject(`RE: ${props.message?.subject || 'Sans sujet'}`);
        }
    }, [props.message]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
                setShowEmojiPicker(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleReply = async () => {
        if (!replyContent.trim()) {
            confirm({
                title: 'Erreur',
                content: "Votre réponse ne peut pas être vide.",
                okType: 'danger',
                okText: 'OK'
            });
            return;
        }

        confirm({
            title: 'Confirmation',
            content: 'Votre réponse a été envoyée avec succès.',
            okType: 'primary',
            okText: 'OK'
        });

        props.setModalReplyToUser(false);
        addNewMessage(replyContent);
    };

    const close = () => {
        // ✅ Efface les champs du formulaire
        setReplyContent('');
        setSubject('');
        setShowEmojiPicker(false); // ✅ Ferme le sélecteur d'emoji s'il est ouvert
        setMessage(''); // ✅ Efface également le contenu du message reçu
    
        // ✅ Ferme le modal
        props.setModalReplyToUser(false);
    };

    const addEmoji = (emojiObject) => {
        setReplyContent(prev => prev + emojiObject.emoji);
        setShowEmojiPicker(false);
    };

    const addNewMessage = async (newMessageContent) => {
        const newMessage = {
            content: newMessageContent,
            date: new Date().toLocaleDateString('fr-FR'),
            edited_from: user?.username || 'Inconnu',
            for_user: newMessageContent.to_user,
            time: new Date().toLocaleTimeString('fr-FR')
          
        };

        let parsedMessage = [];

        try {
            parsedMessage = JSON.parse(props.message.message).message;
        } catch (error) {
            console.error("Erreur lors du parsing du message :", error);
        }

        const updatedMessage = {
            messageId: props.message?.id,
            message: [...parsedMessage, newMessage],
            fromUsername: props.message?.from_user || 'Inconnu',
            toUsername: props.message?.to_user || 'Inconnu',
            toId: props.message?.to_user_id || null,
            fromIdDb: props.message?.from_user_id || null,
            date: new Date().toLocaleDateString('fr-FR'),
            time: new Date().toLocaleTimeString('fr-FR')
        };
        await replyUserMessage(updatedMessage, user?.token);
    };

    return (
        <>
            <Modal
                destroyOnClose={true}
                title={`Répondre à ${props.message?.from_user || 'Inconnu'}`}
                open={props.modalReplyToUser}
                onCancel={close}
                footer={null}
            >
                <Card style={{ backgroundColor: '#f9f9f9', padding: '15px', borderRadius: '8px' }}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Text strong>À :</Text>
                            <AntInput
                                value={`@${props.message?.from_user || 'Inconnu'}`}
                                disabled
                                style={{ marginBottom: '8px' }}
                            />
                        </Col>

                        <Col span={24}>
                            <Text strong>Objet :</Text>
                            <AntInput
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                                placeholder="Objet de votre réponse"
                                style={{ marginBottom: '8px' }}
                            />
                        </Col>

                        <Col span={24}>
                            <Text strong>Votre réponse :</Text>
                            <TextArea
                                onChange={(e) => setReplyContent(e.target.value)}
                                placeholder="Écrivez votre message ici..."
                                rows={5}
                                style={{ marginBottom: '16px' }}
                                value={replyContent}
                            />

                            <Button
                                type="dashed"
                                onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                                style={{ marginBottom: '8px' }}
                            >
                                😊 Ajouter un emoji
                            </Button>

                            <div ref={emojiPickerRef}>
                                {showEmojiPicker && (
                                    <EmojiPicker
                                        onEmojiClick={addEmoji}
                                        theme="light"
                                        autoFocusSearch={false}
                                    />
                                )}
                            </div>
                        </Col>

                        <Col span={24}>
                            <Text strong>Message Reçu :</Text>
                            <div style={{
                                padding: '10px',
                                marginTop: '12px',
                                whiteSpace: 'pre-wrap',
                                maxHeight: '200px',
                                overflowY: 'auto',
                                backgroundColor: '#f5f5f5',
                                border: '1px solid #d9d9d9',
                                borderRadius: '8px'
                            }}>
                                {message}
                            </div>
                        </Col>

                        <Divider />
                        <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                            <Button
                                type="primary"
                                onClick={handleReply}
                                style={{ backgroundColor: '#4CAF50', borderColor: '#4CAF50' }}
                            >
                                Envoyer
                            </Button>

                            <Button onClick={close}>
                                Annuler
                            </Button>
                        </Col>
                    </Row>
                </Card>
            </Modal>
        </>
    );
});

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ModalReplyMessage);
