import { useContext, useState,forwardRef } from 'react';
import { connect } from 'react-redux';
import {Row, Col, Modal, Button, Input } from 'antd';
import { SocketContext } from '../../SocketContext';

const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

const ModalEditMsg = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {
    const socket = useContext(SocketContext);
    const [updateMessage, setUpdateMessage] = useState()
   
    const editMessageTexte = () => {

        const messageUpdated = {
            ...props.messageToEdit?.current,
            message: updateMessage,
            messageEditedFrom: user?.username,
            originalMessage: props.message,
            likeFrom: props.message.likeFrom
        };

        socket.emit("updateMessage", messageUpdated)
    }

    const deleteMessage = () => {
        socket.emit("deleteMessage", props.message)
    }
    const close = () => {
        props.setModalEditMessage(!props.modalEditMessage);
    };
    
    const handleOk = () => {
        props.setModalEditMessage(!props.modalEditMessage)
    }

    return (
        <>
            <Modal
              destroyOnClose={true}
                title="Modifier/supprimer le message"
                open={props.modalEditMessage}
                onOk={handleOk}
                onCancel={close}
            >
                <Row >
                    <Col span={18} style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>Envoyer par:<bold style={{ fontWeight: 'bold', textTransform: 'uppercase' }}> {props.message?.from}</bold> </span>
                        <span>server time: {props.message?.time} </span>
                        <span>socketId: {props.message?.socketId} </span>

                        <Input defaultValue={props?.message?.message} onChange={(e) => setUpdateMessage(e.target.value)} />
                        <span><Button primary onClick={editMessageTexte}>editer</Button>
                            <Button primary onClick={deleteMessage}>Supprimer</Button>
                        </span>
                    </Col>
                </Row>
            </Modal>
        </>
    );
});

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ModalEditMsg);
