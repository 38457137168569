import { connect } from 'react-redux';
import { Row, Col, Modal} from 'antd';

const ModalMessageFromServer = ({ user, ...props }) => {
   
    return (
        <>
            <Modal
                title="Message systeme"
                open={props.isModalOpen}
                onCancel={props.closeModal} 
                footer={null}
            >
                <Row >
                    <Col span={18} style={{ display: 'flex', flexDirection: 'column' }}>
                    
                     {props.message}
                    </Col>
                </Row>
            </Modal>
        </>
    );
}

const mapStateToProps = ({ user }) => ({ user })
export default connect(mapStateToProps)(ModalMessageFromServer);
