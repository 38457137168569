import React, { useRef, useEffect, useContext, useState, useCallback } from 'react';
import { Space, Layout } from 'antd';
import { connect } from 'react-redux';
import { SocketContext } from '../../components/tchat/SocketContext';
import sendRequest from '../../components/socket/requestModule';
import Nav from '../sysop/components/Nav';
import Client from './components/Client';
import Room from './components/Room';
import ModifyRoom from '../sysop/components/ModifyRoom';
import Log from './components/Log';
import UserOnline from './components/UserOnline';
import Memory from './components/Memory';
import ModifyClient from './components/ModifyClient';
import Connected from './components/Connected';
import RoomClass from './components/RoomClass';
import Contact from './components/Contact';
import IndexDb from './components/db_explorer/IndexDb'

const { Header, Content } = Layout;

const headerStyle = {
    textAlign: 'center',
    color: '#fff',
    height: 64,
    paddingInline: 10,
    lineHeight: '64px',
    backgroundColor: '#7dbcea',
};

const contentStyle = {
    textAlign: 'center',
    minHeight: '76vh',
    color: '#fff',
    backgroundColor: 'rgb(22, 22, 22)',
};

const Main = ({ app, testStore, designPage, user }) => {

    const socket = useContext(SocketContext);

    const [currentComponent, setCurrentComponent] = useState({ component: 'rooms', data: null });

    const Render = () => {
        switch (currentComponent.component) {
            case 'client':
                return <Client setCurrentComponent={setCurrentComponent} />;
            case 'modifyClient':
                return <ModifyClient setCurrentComponent={setCurrentComponent} currentComponent={currentComponent} />;
            case 'rooms':
                return <Room setCurrentComponent={setCurrentComponent} />;
            case 'modifyRoom':
                return <ModifyRoom currentComponent={currentComponent} />;
            case 'roomClass':
                return <RoomClass currentComponent={currentComponent} />;

            case 'db_explorer':
                return <IndexDb currentComponent={currentComponent} />;

            case 'log':
                return <Log currentComponent={currentComponent} />;
            case 'user_online':
                return <UserOnline currentComponent={currentComponent} />;
            case 'memory':
                return <Memory currentComponent={currentComponent} />;
            case 'connected':
                return <Connected currentComponent={currentComponent} />;
            case 'contact':
                return <Contact currentComponent={currentComponent} />;
            default:
                return null;
        }
    };

    return (
        <Space direction="vertical" style={{ width: '100%', height: '100vh', maxHeight: '100vh', overflowY: 'hidden' }} size={[0, 48]}>
            <Layout>
                <Header style={headerStyle}>
                    <Nav setCurrentComponent={setCurrentComponent} currentComponent={currentComponent} />
                </Header>

                <Content style={contentStyle}>
                    <Render />
                </Content>
            </Layout>
        </Space>
    );

};

const mapStateToProps = ({ user, message }) => ({ user, message });
export default connect(mapStateToProps)(Main);
