import { jsPanel } from 'jspanel4/es6module/jspanel';
import 'jspanel4/es6module/extensions/modal/jspanel.modal';
import 'jspanel4/dist/jspanel.min.css';
import InputEmoji from 'react-input-emoji'
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp } from '../../../action/action';
import { Radio, Tabs, Badge, Row, Col, Modal, Button, Input, Image, ColorPicker } from 'antd';
import { FileGifOutlined, VideoCameraAddOutlined, BgColorsOutlined, VideoCameraOutlined, BoldOutlined, ItalicOutlined, UnderlineOutlined, AudioOutlined, DesktopOutlined, ApiOutlined, UploadOutlined, SettingOutlined } from '@ant-design/icons';
import { useState, useEffect, useRef, Fragment, forwardRef, useContext, useMemo } from 'react';
import { createRoot } from 'react-dom/client';
import { EventEmitter } from '../../utility/Emitter'
import { SocketContext } from '../SocketContext';
import { startLocalWebcam, stopWebcam, checkWebcamAvailability } from '../../utility/startWebcamLocal';
import PrivateHtmlStructure from './PrivateHtmlStructure'
import { Provider } from 'react-redux';
import store from '../../../store/store';

const Jspanelwebcam = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {
    const [text, setText] = useState('')
    const [isTargetUserLeft, setTargetIsUserLeft] = useState(false);
    const socket = useContext(SocketContext);
    const panelId = props.targetPrivate.username;
    const existingJspanelElement = document.getElementById(panelId);
    const [testMessage, settest] = useState(Math.floor(Math.random() * 10000))
    const LocalvideoId = `myWebcamWith${props.targetPrivate.username}`
    const partnerVideoRef = {}
    partnerVideoRef[panelId] = useRef(null);

    const showMessage = (value) => {
        const newMessage = { key: Date.now(), message: value.message, from: value.from };
        //transmettre dans le  composant Jspanel
        EventEmitter().emit(testMessage, {
            message: newMessage
        })
    }

    const userLeftPrivate = (data) => {
        setTargetIsUserLeft(true)
    }

    //je devrais pas recevoir le message dans chaque composant créer??????
    useMemo(() => {
        if (socket) {
            socket.on('privateMessage', showMessage);
            socket.on('userLeftPrivate', userLeftPrivate);
            return () => {
                socket.off('privateMessage', showMessage);
                socket.off('userLeftPrivate', userLeftPrivate);
            }
        };
    }, [socket]);

    useEffect(() => {
        user?.addConsumerPrivate(null, props.targetPrivate.socketId, props.targetPrivate.producerTransports, 'video', 'test', socket)
        const consumer = props.targetPrivate.userConsumer
        const mediaTrack = consumer.track;
        if (partnerVideoRef[panelId].current && mediaTrack && props.targetPrivate.privateAllowUserIdAccessMedia.includes(user?.socketId)) {
            const videoElement = partnerVideoRef[panelId].current;
            const mediaStream = new MediaStream();
            mediaStream.addTrack(mediaTrack);
            videoElement.srcObject = mediaStream;
            videoElement.play();
        }
    }, [props.targetPrivate.videoEnabled, props.targetPrivate.userConsumer, props.targetPrivate.privateAllowUserIdAccessMedia]);

    useEffect(() => {
        EventEmitter().emit(panelId, {
            user: props.targetPrivate
        })

    }, [props.targetPrivate])

    useEffect(() => {
        if (props.targetPrivate.privateAllowUserIdAccessMedia.includes(user?.socketId)) {
            //transmettre dans le  composant Jspanel
         
           EventEmitter().emit(`privateAllowUserIdAccessMedia${props.targetPrivate.username}`, {
            allowMedia: true
            })

        } else {
            console.log("La valeur n'existe pas dans le tableau.");
        }
    }, [props.targetPrivate.privateAllowUserIdAccessMedia])



    const Video1 = () => {
        return (
            <video id={LocalvideoId} controls width="100%" height="100%" autoPlay></video>
        )
    }

    const Video2 = () => {
        return (
            <video ref={partnerVideoRef[panelId]} controls width="100%" height="100%"></video>
        )
    }

    const JspanelComponent = (props) => {

        const [userPrivate, setUser] = useState()
        const [convo, setConvo] = useState([])

        useEffect(() => {
            const emitter = EventEmitter().subscriber(panelId, data => {
                setUser(data?.user)
            })

            const emitterMessage = EventEmitter().subscriber(testMessage, data => {
                setConvo((prevConvo) => [...prevConvo, data?.message]);
            })

            const constraints = {
                video: true,
                audio: false
            };

            checkWebcamAvailability()
                .then(webcamAvailable => {
                    if (webcamAvailable) {
                        startLocalWebcam(LocalvideoId, constraints)
                            .then(() => {
                                console.log('Webcam started successfully');
                            })
                            .catch((error) => {
                                console.error('Webcam error: ' + error);
                            });
                    } else {
                        console.log('Aucune webcam disponible.');
                    }
                });

            return () => {
                emitterMessage.unsubscribe();
                emitter.unsubscribe();
            };
        }, []);

        return (
            <PrivateHtmlStructure  isTargetUserLeft={isTargetUserLeft} startLocalWebcam={startLocalWebcam} socket={socket} convo={convo} userPrivate={userPrivate} video1={props.video1} video2={props.video2} targetPrivate={props.targetPrivate} />
        )
    }

    const MyJspanel = () => {
        const targetPrivate = props.targetPrivate;
        const idJspanel = Date.now()
        if (!existingJspanelElement) {
            jsPanel.create({
                id: () => panelId,
                theme: "primary",
                headerTitle: `Discution privé avec ${props.targetPrivate?.username}`,
                position: "center-top 0 80",
                contentSize: "90vh 50vh",
                content: `<div style='height:100%;' id=${idJspanel}></div>`,
                resizeit: {
                    aspectRatio: 'content',
                    minHeight: 330
                },
                dragit: {
                    containment: [15, 20, 20]
                },
                callback: panel => {

                    const interval = setInterval(() => {
                        const element = document.getElementById(idJspanel)
                        if (element) {
                            const root = createRoot(document.getElementById(idJspanel));
                            root.render(
                                <Provider store={store}>
                                    <JspanelComponent
                                        targetPrivate={targetPrivate}
                                        localName={props.userprops?.username}
                                        target={props.targetPrivate?.videoEnabled ? 'true' : 'false'}
                                        video1={<Video1 />}
                                        video2={<Video2 />}

                                    />
                                </Provider>
                            );
                            clearInterval(interval)
                        }
                    }, 500);
                },
                onclosed: function () {
                    // Gérez l'événement de fermeture ici
                    socket.emit("stopConsume", props.targetPrivate.socketId, user?.socketId, 'video')
                }

            });
        } else {

        }
    };

    return (
        <MyJspanel />
    );
})

const mapStateToProps = ({ app, testStore, designPage, user }) => ({ app, testStore, designPage, user });
const mapDispatchToProps = dispatch => ({ dispatch });
export default connect(mapStateToProps, mapDispatchToProps)(Jspanelwebcam);