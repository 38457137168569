import { useEffect, useState, forwardRef } from "react";
import { connect } from "react-redux";
import { GiftOutlined } from '@ant-design/icons';
import { Alert } from 'antd';

const URL =
    process.env.REACT_APP_MODE_ENV === "development"
        ? process.env.REACT_APP_URL_SERVER_DEV
        : process.env.REACT_APP_URL_SERVER_PROD;

const ShowReactionLike = forwardRef(({ user, ...props }, ref) => {
    const [havePlay, setHavePlay] = useState(false);

    const playSound = (son) => {
        const audio = new Audio(son);
        audio.play().catch(err => console.error('Erreur de lecture du son :', err));
    };

    useEffect(() => {
        if (!havePlay && user?.username !== props?.msg?.giftType?.sendFromUsername) {
            playSound(`${URL}/systeme/image/gift/wav/${props?.msg?.giftType?.sound}`);
            setHavePlay(true); // ✅ Déplacement dans useEffect pour éviter les erreurs
        }
    }, [havePlay]); // 🔹 Dépendances nécessaires

    return (
        <>
            <Alert
                icon={
                    <>
                        <GiftOutlined style={{ color: '#ff4d4f', fontSize: '28px' }} />
                        <span style={{ marginLeft: '8px', fontWeight: 'bold', color: '#ff4d4f' }}>
                            {props?.msg?.giftType?.price} jetons
                        </span>
                    </>
                }
                description={
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                        <span style={{ flex: 1, textAlign: 'center' }}>
                            {props.msg.message}
                        </span>

                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <span style={{ fontSize: '10px', fontWeight: 'bold', color: '#52c41a' }}>
                                Cliquez ici ➡️
                            </span>

                            <img
                                src={`${URL}/systeme/image/gift/${props?.msg?.giftType?.image}`}
                                alt="Icône cadeau"
                                style={{ width: '40px', height: '40px', cursor: 'pointer' }}
                                onClick={() => playSound(`${URL}/systeme/image/gift/wav/${props?.msg?.giftType?.sound}`)}
                            />
                        </div>
                    </div>
                }
                type="success"
                showIcon
            />
        </>
    );
});

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ShowReactionLike);
