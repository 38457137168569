import { useState, useEffect, forwardRef } from 'react';
import { Modal, Row, Col, Input, Button, Select } from 'antd';
import { connect } from 'react-redux';
import { adminUpdateShoutcastApi } from '../../../../endpoints/admin';
import ModalShowRespondFromApi from './ModalShowRespondFromApi';

const ModalShowShoutcastInformation = forwardRef(({ user, setIsModalOpenFileInf, isModalOpenFileInfo }, ref) => {
    const [audioRef, setAudioRef] = useState(null);
    const [ip, setIp] = useState('');
    const [shoutcastPath, setShoutcastPath] = useState('');
    const [type, setType] = useState('');
    const [metadatatechnic, setMetadatatechnic] = useState('');
    const [audioKey, setAudioKey] = useState(0);
    const [modalRespondApi, setModalRespondApi] = useState(false);
    const [message, setMessage] = useState(null);

    // Mettre à jour les états avec les valeurs initiales lorsque la modal s'ouvre
    useEffect(() => {
        if (isModalOpenFileInfo.obj) {
            setIp(isModalOpenFileInfo.obj.ip || '');
            setShoutcastPath(isModalOpenFileInfo.obj.shoutcastpath || '/stream');
            setType(isModalOpenFileInfo.obj.type || 'shoutcast2');
            setMetadatatechnic(isModalOpenFileInfo.obj.Metadatatechnic || 'fallback');
        }
    }, [isModalOpenFileInfo]);

    // Gestion du lecteur audio
    useEffect(() => {
        if (audioRef) {
            if (isModalOpenFileInfo.show) {
                audioRef.play().catch(error => console.error("Erreur audio:", error));
            } else {
                audioRef.pause();
            }
        }
        return () => {
            if (audioRef) {
                audioRef.pause();
                audioRef.currentTime = 0;
            }
        };
    }, [isModalOpenFileInfo.show, audioRef]);

    // Gestion du changement des champs
    const handleInputChange = (value, field) => {
        if (field === "ip") {
            setIp(value);
        } else if (field === "shoutcastpath") {
            setShoutcastPath(value);
        } else if (field === "type") {
            setType(value);
        } else if (field === "metadatatechnic") {
            setMetadatatechnic(value);
        }
    };
    

    // Mettre à jour le shoutcast
    const updateShoutcast = () => {
        adminUpdateShoutcastApi(user?.token, user?.room, user?.socketId, ip,shoutcastPath,metadatatechnic,type, isModalOpenFileInfo.obj?.id)
            .then((resp) => {
                if (resp.permission) {
                    setAudioKey(prevKey => prevKey + 1);
                } else {
                    setMessage(resp.message);
                    setModalRespondApi(true);
                }
            })
            .catch(error => console.error("Erreur mise à jour :", error));
    };

    return (
        <>
            <ModalShowRespondFromApi message={message} modalRespondApi={modalRespondApi} setModalRespondApi={setModalRespondApi} />
            <Modal title="Informations sur le Shoutcast" style={{ textAlign: 'center' }} open={isModalOpenFileInfo.show} onOk={() => setIsModalOpenFileInf({ show: false, obj: null })} onCancel={() => setIsModalOpenFileInf({ show: false, obj: null })} destroyOnClose>
                
                {/* Lecture audio */}
                <Row style={{ textAlign: 'left' }}>
                    <Col span={6}>Écouter :</Col>
                    <Col span={12}>
                        <audio key={audioKey} ref={(audio) => setAudioRef(audio)} controls>
                            <source src={`${ip || isModalOpenFileInfo.obj?.ip}/stream`} type="audio/mpeg" />
                        </audio>
                    </Col>
                </Row>

                {/* Informations générales */}
                <Row style={{ textAlign: 'left' }}>
                    <Col span={6}>Nom :</Col>
                    <Col span={12}>{isModalOpenFileInfo.obj?.name}</Col>
                </Row>

                {/* IP */}
                <Row style={{ textAlign: 'left' }}>
                    <Col span={6}>IP :</Col>
                    <Col span={18} style={{ display: 'flex', alignItems: 'center' }}>
                        <Input value={ip} onChange={(e) => handleInputChange(e.target.value, "ip")} placeholder="Adresse IP" />
                    </Col>
                </Row>

                {/* Shoutcast Path */}
                <Row style={{ textAlign: 'left' }}>
                    <Col span={6}>Shoutcast Path :</Col>
                    <Col span={18} style={{ display: 'flex', alignItems: 'center' }}>
                        <Select value={shoutcastPath} onChange={(value) => handleInputChange(value, "shoutcastpath")} style={{ flex: 1 }}>
                            <Select.Option value="/stream">/stream</Select.Option>
                            <Select.Option value="/live">/live</Select.Option>
                            <Select.Option value="/radio">/radio</Select.Option>
                        </Select>
                    </Col>
                </Row>

                {/* Type */}
                <Row style={{ textAlign: 'left', marginTop: "10px" }}>
                    <Col span={6}>Type :</Col>
                    <Col span={18} style={{ display: 'flex', alignItems: 'center' }}>
                        <Select value={type} onChange={(value) => handleInputChange(value, "type")} style={{ flex: 1 }}>
                            <Select.Option value="shoutcast2">Shoutcast2</Select.Option>
                            <Select.Option value="icecast2">Icecast2</Select.Option>
                        </Select>
                    </Col>
                </Row>

                {/* Metadatatechnic */}
                <Row style={{ textAlign: 'left' }}>
                    <Col span={6}>Metadatatechnic :</Col>
                    <Col span={18} style={{ display: 'flex', alignItems: 'center' }}>
                        <Select value={metadatatechnic} onChange={(value) => handleInputChange(value, "metadatatechnic")} style={{ flex: 1, marginRight: "10px" }}>
                            <Select.Option value="fallback">Fallback</Select.Option>
                            <Select.Option value="directly">Directly</Select.Option>
                        </Select>
                        <Button onClick={updateShoutcast}>Update</Button>
                    </Col>
                </Row>

                {/* Informations sur l'envoi */}
                <Row style={{ textAlign: 'left' }}>
                    <Col span={6}>Envoyé par :</Col>
                    <Col span={12}>{isModalOpenFileInfo.obj?.senderName}</Col>
                </Row>

                <Row style={{ textAlign: 'left' }}>
                    <Col span={6}>Date envoyée :</Col>
                    <Col span={12}>{isModalOpenFileInfo.obj?.date}</Col>
                </Row>

            </Modal>
        </>
    );
});

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(ModalShowShoutcastInformation);
