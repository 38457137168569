import {  forwardRef } from 'react';
import { Alert } from 'antd';
import { ToolOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import Pay from './Pay';
import ReceivedGift from './ReceivedGift';

const MessageFromUser = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {

    const openPayPasserel = () => {
        window.open('https://videochat-html5.ca/register/', '_blank');
    }

    const MessageSysteme = () => {
        return (
            <>
                <div
                    onClick={props.msg.url !== null ? openPayPasserel : null}
                    className={props.msg.url !== null ? 'hover-div' : ''}
                    style={props.msg.url === null
                        ? { backgroundColor: '#00000012', margin: '5px', fontSize: '20px', padding: '4px', borderRadius: '4px', width: '100%', textAlign: 'center' }
                        : undefined
                    }
                >
                    {props.msg.gift ? (
                        <>
                            {/* composant cadeau */}
                            <ReceivedGift msg={props?.msg} />
                        </>
                    )
                        : (
                            <Alert
                                message={
                                    props?.msg?.url !== null
                                        ? <div><ToolOutlined /> {props?.msg?.message}</div>
                                        : <div>{props?.msg?.message}</div>
                                }
                                type="success"
                            />
                        )}
                </div >
            </>
        )
    }

    return (
        <div key={props.index + 1} style={{ display: 'flex', justifyContent: 'center' }} >
            <div key={props.index + 2} style={{ flex: '0 0 100%' }}>
                <MessageSysteme />
            </div>
            <Pay ref={ref} />
        </div>

    );
})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(MessageFromUser);