import React, { forwardRef, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Modal, message } from 'antd';
import { sendVoiceRecorded } from '../../../../endpoints/voiceRecord';

const VoiceRecorder = forwardRef(({ app, testStore, designPage, user, onSend, ...props }, ref) => {

    const [isRecording, setIsRecording] = useState(false);
    const [audioURL, setAudioURL] = useState(null);
    const mediaRecorderRef = useRef(null);
    const audioChunks = useRef([]);
    const audioRef = useRef(null);  // Référence pour l'élément audio

    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

            // Réinitialiser les données d'enregistrement
            audioChunks.current = [];
            mediaRecorderRef.current = new MediaRecorder(stream);

            mediaRecorderRef.current.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    audioChunks.current.push(event.data);
                }
            };

            mediaRecorderRef.current.onstop = () => {
                const audioBlob = new Blob(audioChunks.current, { type: 'audio/webm' });
                const url = URL.createObjectURL(audioBlob);
                setAudioURL(url);

                // 🎧 Lecture automatique dès que l'audio est prêt
                setTimeout(() => {
                    if (audioRef.current) {
                        audioRef.current.load();  // Recharge l'audio pour garantir sa lecture
                       // audioRef.current.play();  // Démarre la lecture automatiquement
                    }
                }, 300);

                audioChunks.current = [];
            };

            mediaRecorderRef.current.start();
            setIsRecording(true);
            message.success("🎙️ Enregistrement démarré !");
        } catch (error) {
            console.error('Erreur lors de l\'accès au microphone :', error);
            message.error('Erreur lors de l\'accès au microphone.');
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
            setIsRecording(false);
            message.info("🛑 Enregistrement arrêté.");
    
            // 🔥 Libérer les ressources du microphone
            const stream = mediaRecorderRef.current.stream;
            stream.getTracks().forEach(track => track.stop());  // Arrêter chaque piste audio
        }
    };
    

    const handleSend = async () => {
        try {
            if (!audioURL) {
                message.error("⚠️ Aucun enregistrement trouvé.");
                return;
            }
    
            const response = await fetch(audioURL);
            const audioBlob = await response.blob();
            const audioFile = new File([audioBlob], `enregistrement_${Date.now()}.webm`, { type: 'audio/webm' });
    
            const formData = new FormData();
            formData.append("audioFile", audioFile);  // 🔥 Important : le nom doit être "audioFile"
            formData.append("userInfos", JSON.stringify({
                userObj: user,
                room: user?.room
            }));
    
            await sendVoiceRecorded(formData, user?.token, user);
    
            message.success("✅ Enregistrement envoyé avec succès !");
            props.setModalVoiceRecord(false);
    
        } catch (error) {
            console.error("❌ Erreur lors de l'envoi :", error);
            message.error("❌ Erreur lors de l'envoi de l'enregistrement.");
        }
    };
    
    
    

    const handleOk = () => props.setModalVoiceRecord(false);
    const handleCancel = () => props.setModalVoiceRecord(false);

    return (
        <Modal
            title="Enregistrement Vocal"
            open={props.modalVoiceRecord}
            onOk={handleOk}
            onCancel={handleCancel}
        >
            <div style={{ padding: '20px', border: '1px solid #ddd', borderRadius: '8px', width: '100%' }}>
                <h3>Enregistrement vocal</h3>

                <button
                    onClick={isRecording ? stopRecording : startRecording}
                    style={{
                        backgroundColor: isRecording ? 'red' : 'green',
                        color: 'white',
                        padding: '10px 20px',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        marginBottom: '10px'
                    }}
                >
                    {isRecording ? "Arrêter l'enregistrement" : "Démarrer l'enregistrement"}
                </button>

                {audioURL && (
                    <div style={{ marginTop: '10px' }}>
                        <audio ref={audioRef} controls src={audioURL}></audio>
                        <button
                            onClick={handleSend}
                            style={{
                                backgroundColor: '#007bff',
                                color: 'white',
                                padding: '8px 15px',
                                border: 'none',
                                borderRadius: '4px',
                                cursor: 'pointer',
                                marginTop: '10px'
                            }}
                        >
                            Envoyer l'enregistrement
                        </button>
                    </div>
                )}
            </div>
        </Modal>
    );
})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(VoiceRecorder);
