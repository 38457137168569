import React, { useState, useRef, forwardRef } from 'react';

import { connect } from 'react-redux';

// import { uploadRoomLogo , uploadUserImage} from '../../../../endpoints/avatar'

const UploadFile = forwardRef(({ user, ...props }, ref) => {

    const [currentComponentObj, setCurrentComponentObj] = useState({ name: 'user' });
    const [visible, SetVisible] = useState(true)
    const [selectedFile, setSelectedFile] = useState(null);
    let sizeOfImage = 99999999999999;
    const mounteImagedRef = useRef(false)

  
    return (
        <>div{props.fileToSend}</>
    );
})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps)(UploadFile);
