import { useState, useContext, useEffect, useRef, Fragment, useMemo, forwardRef } from 'react';
import { connect } from 'react-redux';
import InputEmoji from 'react-input-emoji'
import { Col, Row, ColorPicker, Button, Select, Dropdown, Menu } from 'antd';
import Giphy from '../InputChat/GiphySearch'
import { SocketContext } from '../../SocketContext';
import {AudioOutlined, FontColorsOutlined, BoldOutlined, PictureOutlined, ItalicOutlined, UnderlineOutlined, FileGifOutlined } from '@ant-design/icons';
import SendImageToChat from './SendImageToChat';
import VoiceRecorder from './VoiceRecorder';

const InputChat = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {

  const socket = useContext(SocketContext);
  const [text, setText] = useState('')
  const [modalVisibleGiphySearch, setModalVisibleGiphySearch] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [colorHex, setColorHex] = useState('#1677ff');
  const [formatHex, setFormatHex] = useState('hex');
  const [modalSendImageChat, setmodalSendImageChat] = useState(null)
  const buttonRefEmoji = useRef(null);
  const [selectedEffect, setSelectedEffect] = useState({ value: '', colors: [] });
  const [fontSize, setFontSize] = useState(15);  // Par défaut à 15px
  const [modalVoiceRecord, setModalVoiceRecord] = useState(false);

  const OpenMondalSendImage = () => {
    setmodalSendImageChat(true)
  }

  const textEffects = [
    { label: 'Océan Magenta', value: 'gradient1', colors: ['#17acff', '#ff68f0'] },
    { label: 'Rose Flashy', value: 'gradient2', colors: ['#ff007f', '#ff66c4'] },
    { label: 'Glace Électrique', value: 'gradient3', colors: ['#00ffff', '#0066ff'] },
    { label: 'Flamme Brûlante', value: 'gradient4', colors: ['#ff4500', '#ff8c00'] },
    { label: 'Mystique Violet', value: 'gradient5', colors: ['#8a2be2', '#9370db'] },
    { label: 'Ciel d\'Orage', value: 'gradient6', colors: ['#4b6cb7', '#182848'] },
    { label: 'Soleil Coucher', value: 'gradient7', colors: ['#ff9a9e', '#fad0c4'] },
    { label: 'Émeraude Mystique', value: 'gradient8', colors: ['#00c6ff', '#0072ff'] },
    { label: 'Forêt Enchantée', value: 'gradient9', colors: ['#134E5E', '#71B280'] },
    { label: 'Lave Brûlante', value: 'gradient10', colors: ['#ff416c', '#ff4b2b'] },
    { label: 'Explosion Galactique', value: 'gradient11', colors: ['#00F260', '#0575E6'] },
    { label: 'Lagon Tropical', value: 'gradient12', colors: ['#43C6AC', '#191654'] },
    { label: 'Coucher de Soleil', value: 'gradient13', colors: ['#FDC830', '#F37335'] },
    { label: 'Bleu Néon', value: 'gradient14', colors: ['#12c2e9', '#c471ed', '#f64f59'] },
    { label: 'Framboise Éclatante', value: 'gradient15', colors: ['#D31027', '#EA384D'] },
    { label: 'Aurore Boréale', value: 'gradient16', colors: ['#00c3ff', '#ffff1c'] },
    { label: 'Magenta Royal', value: 'gradient17', colors: ['#FF00FF', '#8B008B'] },
    { label: 'Saphir Profond', value: 'gradient18', colors: ['#1A2980', '#26D0CE'] },
    { label: 'Glitch Digital', value: 'gradient19', colors: ['#3a1c71', '#d76d77', '#ffaf7b'] },
    { label: 'Ciel Pastel', value: 'gradient20', colors: ['#a18cd1', '#fbc2eb'] }
  ];
  const fontOptions = [
    { key: "Arial", label: "Arial", googlefont: null, fontName: "Arial, sans-serif", url: "" },
    { key: "SixtyfourConvergence", label: "Sixtyfour Convergence", googlefont: "Sixtyfour+Convergence", fontName: "Sixtyfour Convergence", url: "https://fonts.googleapis.com/css2?family=Sixtyfour+Convergence&display=swap" },
    { key: "EmblemaOne", label: "Emblema One", googlefont: "Emblema+One", fontName: "Emblema One", url: "https://fonts.googleapis.com/css2?family=Emblema+One&display=swap" },
    { key: "KalniaGlaze", label: "Kalnia Glaze", googlefont: "Kalnia+Glaze", fontName: "Kalnia Glaze", url: "https://fonts.googleapis.com/css2?family=Kalnia+Glaze&display=swap" },
    { key: "Honk", label: "Honk", googlefont: "Honk", fontName: "Honk", url: "https://fonts.googleapis.com/css2?family=Honk&display=swap" },
    { key: "RugeBoogie", label: "Ruge Boogie", googlefont: "Ruge+Boogie", fontName: "Ruge Boogie", url: "https://fonts.googleapis.com/css2?family=Ruge+Boogie&display=swap" },
    { key: "Frijole", label: "Frijole", googlefont: "Frijole", fontName: "Frijole", url: "https://fonts.googleapis.com/css2?family=Frijole&display=swap" },
    { key: "BebasNeue", label: "Bebas Neue", googlefont: "Bebas+Neue", fontName: "Bebas Neue", url: "https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap" },
    { key: "DancingScript", label: "Dancing Script", googlefont: "Dancing+Script", fontName: "Dancing Script", url: "https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap" },
    { key: "SpicyRice", label: "Spicy Rice", googlefont: "Spicy+Rice", fontName: "Spicy Rice", url: "https://fonts.googleapis.com/css2?family=Spicy+Rice&display=swap" },
    { key: "SourceCodePro", label: "Source Code Pro", googlefont: "Source+Code+Pro", fontName: "Source Code Pro", url: "https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap" },
    { key: "Pacifico", label: "Pacifico", googlefont: "Pacifico", fontName: "Pacifico", url: "https://fonts.googleapis.com/css2?family=Pacifico&display=swap" },
    { key: "Lobster", label: "Lobster", googlefont: "Lobster", fontName: "Lobster", url: "https://fonts.googleapis.com/css2?family=Lobster&display=swap" },
    { key: "ShadowsIntoLight", label: "Shadows Into Light", googlefont: "Shadows+Into+Light", fontName: "Shadows Into Light", url: "https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap" },
    { key: "ChakraPetch", label: "Chakra Petch", googlefont: "Chakra+Petch", fontName: "Chakra Petch", url: "https://fonts.googleapis.com/css2?family=Chakra+Petch&display=swap" },
    { key: "Micro5", label: "Micro 5", googlefont: "Micro+5", fontName: "Micro 5", url: "https://fonts.googleapis.com/css2?family=Micro+5&display=swap" },
    { key: "IndieFlower", label: "Indie Flower", googlefont: "Indie+Flower", fontName: "Indie Flower", url: "https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap" },
    { key: "Cinzel", label: "Cinzel", googlefont: "Cinzel", fontName: "Cinzel", url: "https://fonts.googleapis.com/css2?family=Cinzel&display=swap" },
    { key: "Orbitron", label: "Orbitron", googlefont: "Orbitron", fontName: "Orbitron", url: "https://fonts.googleapis.com/css2?family=Orbitron&display=swap" },
    { key: "Doto", label: "Doto", googlefont: "Doto", fontName: "Doto", url: "https://fonts.googleapis.com/css2?family=Doto&display=swap" },
    { key: "GreatVibes", label: "Great Vibes", googlefont: "Great+Vibes", fontName: "Great Vibes", url: "https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap" },
    { key: "RubikMonoOne", label: "Rubik Mono One", googlefont: "Rubik+Mono+One", fontName: "Rubik Mono One", url: "https://fonts.googleapis.com/css2?family=Rubik+Mono+One&display=swap" },
    { key: "Bangers", label: "Bangers", googlefont: "Bangers", fontName: "Bangers", url: "https://fonts.googleapis.com/css2?family=Bangers&display=swap" },
    { key: "Neucha", label: "Neucha", googlefont: "Neucha", fontName: "Neucha", url: "https://fonts.googleapis.com/css2?family=Neucha&display=swap" }
  ];

  const fontSizeOptions = [
    { value: 15, label: '15px' },
    { value: 16, label: '16px' },
    { value: 17, label: '17px' },
    { value: 18, label: '18px' },
    { value: 19, label: '19px' },
    { value: 20, label: '20px' },
    { value: 21, label: '21px' },
    { value: 22, label: '22px' },
    { value: 23, label: '23px' },
    { value: 24, label: '24px' },
    { value: 25, label: '25px' }
  ];

  //debut1
  const [selectedFont, setSelectedFont] = useState(fontOptions[0]);

  // Fonction pour vérifier si une police est chargée
  const isFontLoaded = async (fontName) => {
    if (document.fonts) {
      return document.fonts.check(`1em "${fontName}"`);
    }
    return false;
  };

  const loadFont = (font) => {
    if (!font?.url) return;  // 🔄 Vérifie si l'URL est valide

    const existingLink = document.getElementById('dynamic-font');
    if (existingLink) {
        existingLink.remove();
    }

    const link = document.createElement('link');
    link.id = 'dynamic-font';
    link.href = font.url;
    link.rel = 'stylesheet';

    link.onload = () => {
        const inputEmoji = document.querySelector('.react-input-emoji--input');
        if (inputEmoji) {
            inputEmoji.style.fontFamily = font.fontName;
        }
    };

    link.onerror = () => {
        console.error(`❌ Erreur lors du chargement de la police "${font.fontName}".`);
    };

    document.head.appendChild(link);  // ✅ Ajoute la police dans le `<head>`
};

  // Charger la police sélectionnée
  useEffect(() => {
    loadFont(selectedFont);
  }, [selectedFont]);

  const handleFontChange = ({ key }) => {
    const selected = fontOptions.find(font => font.key === key);
    if (selected) {
        setSelectedFont(selected);  // ✅ Mise à jour correcte
    }
};

  // Menu pour le Dropdown avec les polices affichées dans leur propre style
  // Correct fontMenu
  const fontMenu = (
    <Menu onClick={handleFontChange}>
      {fontOptions.map(font => (
        <Menu.Item key={font.key} style={{ fontFamily: font.fontName }}>
          {font.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  useEffect(() => {
    setTextUserStyle(prevStyle => ({
      ...prevStyle,
      fontSize: `${fontSize}px` // Assure-toi qu'il est bien inclus ici
    }));
  }, [fontSize]);

  //fin

  function handleOnEnter(text) {
    const regex = /(<([^>]+)>)/ig;
    const clean = text.replace(regex, '');
    const isoDate = new Date().toISOString();

    const messagePackage = {
      status: user?.status,
      time: new Date().toISOString(),
      message: clean,
      from: user?.username,
      for: null,
      type: 'text',
      room: user?.room,
      roomId: user?.selectedRoom,
      typeTab: user?.typeTab,
      avatar: user?.avatar,
      formatMessage: textUserStyle,
      roleColor: user?.roleColor,
      originalMessage: null,
      effect: selectedEffect.value,
      font: selectedFont,      // La valeur de l'effet
      gradientColors: selectedEffect?.colors // Les couleurs du dégradé
    };

    text.trim().length >= 1 && socket.emit("message", messagePackage);
    setImageUrl(null);
    setText('');
  }

  const hexString = useMemo(
    () => (typeof colorHex === 'string' ? colorHex : colorHex.toHexString()),
    [colorHex],
  );

  useEffect(() => {
    if (ref.UserColorText.current) {
      /*set input color text message  */
      setColorHex(ref.UserColorText.current)
    }
  }, [ref.UserColorText.current])


  useEffect(() => {
    const inputEmoji = document.querySelector('.react-input-emoji--input');
    if (inputEmoji) {
      setTimeout(() => {
        inputEmoji.style.fontFamily = selectedFont?.fontName || '';
        inputEmoji.style.fontSize = `${fontSize}px`;

        // Appliquer le dégradé de couleur s'il existe
        if (selectedEffect?.colors && selectedEffect.colors.length) {
          const gradientColors = selectedEffect.colors.join(', ');
          inputEmoji.style.background = `linear-gradient(90deg, ${gradientColors})`;
          inputEmoji.style.webkitBackgroundClip = 'text';
          inputEmoji.style.webkitTextFillColor = 'transparent'; // Rendre le texte transparent pour que le dégradé soit visible
        } else {
          inputEmoji.style.background = 'none'; // Supprime le dégradé si aucun n'est sélectionné
          inputEmoji.style.webkitTextFillColor = 'inherit'; // Rétablit la couleur normale du texte
        }
      }, 100);  // Petit délai pour s'assurer que la police est bien chargée
    }
  }, [selectedFont, fontSize, selectedEffect]);

  function debounceOnce(func, delay) {
    let timerId = null;
    return function (...args) {
      if (timerId === null) {
        func(...args);
        timerId = setTimeout(() => {
          timerId = null;
        }, delay);
      }
    };
  }

  let isDebounced = useRef(false);

  useEffect(() => {
    if (/^https?:\/\/.*\.(bmp|gif|jpe?g|png|svg|tiff?|webp)$/i.test(text)) {
      setImageUrl(text);
      handleOnEnter(text);
      setText('');
    } else if (!isDebounced.current && text) {
      isDebounced.current = true;
      socket.emit("typing", text, user?.selectedRoom);
      setTimeout(() => {
        isDebounced.current = false;
      }, 4000);
    }
  }, [text]);

  useEffect(() => {
    if (socket) {
        socket.on('setInputOff', setInputOff);
        socket.on('setInputOn', setInputOn);
    }

    // Nettoyage lors du démontage du composant
    return () => {
        if (socket) {
            socket.off('setInputOff', setInputOff);
            socket.off('setInputOn', setInputOn);
        }
    };
}, [socket]);


  const setInputOff = () => {
    setText('')
    setMax('0')
  }

  const setInputOn = () => {
    setMax(max)
  }

  const bold = useRef(false)
  const italic = useRef(false)
  const underline = useRef(false)

  const [textUserStyle, setTextUserStyle] = useState({
    bold: bold.current,
    italic: italic.current,
    underline: underline.current,
    colorText: colorHex,
    font: selectedFont?.fontName || '',
    fontSize: `${fontSize}px`
  });

 
  useEffect(() => {
    setTextUserStyle((prevStyle) => ({
        ...prevStyle,
        font: selectedFont?.fontName || '',  // ✅ Mise à jour correcte
        fontSize: `${fontSize}px`
    }));
}, [selectedFont, fontSize]); // 🔥 Ajoute bien les dépendances ici


  const [max, setMax] = useState(600)
  const [forceUpdateState, setForceUpdateState] = useState(1)
  const setTextUserStyleHandler = (value) => {

    switch (value) {
      case 'bold':
        bold.current = !bold.current
        break;
      case 'italic':
        italic.current = !italic.current
        break;
      case 'underline':
        underline.current = !underline.current

        break;
    }

    setTextUserStyle({
      class: selectedEffect,
      bold: bold.current,
      italic: italic.current,
      underline: underline.current,
      colorText: hexString,
      font: selectedFont?.fontName || '',
      fontSize: `${fontSize}px`  // ✅ Correction ici
    });
  }

  useEffect(() => {
    const myElement = document.getElementsByClassName('react-input-emoji--input')[0];
    myElement.style.color = hexString;

    setTextUserStyle({
      class: selectedEffect,
      bold: bold.current,
      italic: italic.current,
      underline: underline.current,
      colorText: hexString,
      font: selectedFont?.fontName || '',
      fontSize: `${fontSize}px`

    });
  }, [colorHex])

  useEffect(() => {
    const inputEmoji = document.querySelector('.react-input-emoji--input');
    if (inputEmoji) {
      inputEmoji.style.fontFamily = selectedFont?.fontName || '';
      inputEmoji.style.fontSize = `${fontSize}px`;  // ✅ Correction ici
    }
  }, [selectedFont, fontSize]);

  const handleButtonClick = () => {
    // Utilisez buttonRef.current pour accéder au bouton
    if (buttonRefEmoji.current) {
      // Déclenchez le clic sur le bouton
      buttonRefEmoji.current.click();
    }
  };

  const OpenMondalVoiceRecord = () =>{
    setModalVoiceRecord(true)
  }

  return (
    <Row >
      <Col span={24} style={{ display: 'flex', paddingLeft: '10px' }}>
        <Col ref={ref.ZoneToolsIcone} className='niceBox'>
          <Button size={'small'} onClick={() => setTextUserStyleHandler('bold')}><BoldOutlined style={{ color: !bold.current ? 'black' : 'green' }} /></Button>
          <Button size={'small'} onClick={() => setTextUserStyleHandler('italic')}><ItalicOutlined style={{ color: !italic.current ? 'black' : 'green' }} /></Button>
          <Button size={'small'} onClick={() => setTextUserStyleHandler('underline')}><UnderlineOutlined style={{ color: underline.current ? 'green' : 'black' }} /></Button>
          <Button size={'small'} onClick={() => setModalVisibleGiphySearch(true)}><FileGifOutlined style={{}} /></Button>

          <Dropdown
            overlay={(
              <Menu
                onClick={(e) => {
                  handleFontChange(e); // Appel correct ici
                }}
                style={{ maxHeight: '200px', overflowY: 'auto' }}
              >
                {fontOptions.map(font => (
                  <Menu.Item key={font.key} style={{ fontFamily: font.fontName }}>
                    {font.label}
                  </Menu.Item>
                ))}
              </Menu>
            )}
            placement="bottomLeft"
            trigger={['click']}
          >
            <Button size={'small'} icon={<FontColorsOutlined />}>
              {selectedFont?.label || "Sélectionner une police"}
            </Button>
          </Dropdown>

          <Col style={{ margin: '0px' }}>

            <Select
              style={{ height: '25px' }}
              // Ajustement de la hauteur ici
              value={selectedEffect.value}
              onChange={(value) => {
                const selected = textEffects.find(effect => effect.value === value);
                setSelectedEffect(selected || { value: '', colors: [] });
              }}
              placeholder="Sélectionner un effet"
            >
              <Select.Option value="" >Aucun effet</Select.Option>
              {textEffects.map(effect => (
                <Select.Option key={effect.value} value={effect.value}>
                  {effect.label}
                </Select.Option>
              ))}
            </Select>

          </Col>

          <Dropdown

            overlay={
              <Menu>

                {fontSizeOptions.map(size => (
                  <Menu.Item key={size.value} onClick={() => {
                    setFontSize(size.value);
                  }}>
                    {size.label}
                  </Menu.Item>
                ))}
              </Menu>
            }
            placement="bottomLeft"
            trigger={['click']}
          >
            <Button style={{ height: '25px', padding: 0 }}>
              Taille : {fontSize}px
            </Button>
          </Dropdown>

          <ColorPicker
            size={'small'}
            style={{ position: 'relative' }}
            format={formatHex}
            value={colorHex}
            onChange={setColorHex}
            onFormatChange={setFormatHex}
          />

          <Button size={'small'} onClick={() => OpenMondalSendImage()}><PictureOutlined style={{ color: !bold.current ? 'black' : 'green' }} /></Button>
          <Button size={'small'} onClick={() => OpenMondalVoiceRecord()}><AudioOutlined  style={{ color: !bold.current ? 'black' : 'green' }} /></Button>

          <span
            ref={buttonRefEmoji}
            onClick={handleButtonClick}
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '25px',
              border: '1px solid #d9d9d9',
              borderRadius: '6px',
              backgroundColor: '#ffffff',
              color: '#000000',
              cursor: 'pointer',
              transition: 'all 0.3s',
              boxShadow: '0 2px 0 rgba(0, 0, 0, 0.015)'
            }}
            onMouseEnter={(e) => e.currentTarget.style.borderColor = '#1890ff'}  // Effet au survol (bleu)
            onMouseLeave={(e) => e.currentTarget.style.borderColor = '#d9d9d9'}  // Retour à la couleur par défaut
          >
            😀
          </span>

          {/*hexString*/}
          {/* addiche composant Giphy  */}
          <Giphy setText={setText} modalVisibleGiphySearch={modalVisibleGiphySearch} setModalVisibleGiphySearch={setModalVisibleGiphySearch} />
        </Col>
      </Col>
      <Col xs={15} sm={17} lg={21} >
        <InputEmoji
          value={text}
          autoComplete="off"
          onChange={setText}
          cleanOnEnter
          autoCorrect="off"
          spellCheck={false}
          onEnter={handleOnEnter}
          placeholder={max == '0' ? 'Vous ne pouvez pas écrire de message' : 'Écrire un message'}
          maxLength={max}
          customButton={<FileGifOutlined style={{ fontSize: `${fontSize}px`, }} />}

          buttonRef={buttonRefEmoji}
        /* pickerPosition="top" */ // Exemple de propriété pour placer le sélecteur en haut
        />
      </Col>
      <Col xs={4} sm={7} lg={3} >
        <Button onClick={() => handleOnEnter(text)} className='boutonSendMessage'>Envoyer</Button>
        </Col>

      {/* component send image to chat */}
      <SendImageToChat modalSendImageChat={modalSendImageChat} setmodalSendImageChat={setmodalSendImageChat} />
  <VoiceRecorder user={user} modalVoiceRecord={modalVoiceRecord} setModalVoiceRecord={setModalVoiceRecord} />
    </Row>

  )
})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(InputChat);