import React, { useEffect, useState } from 'react';
import { Space, Layout, Row, Col, Table, Select, Flex, Progress } from 'antd';
import { Link } from "react-router-dom";
import Nav from '../components/pages/layout/Nav';
import { useParams } from 'react-router-dom';
import { valide_email } from '../endpoints/email_validation';

import '../css/register.css'

const { Header, Footer, Content } = Layout;
const actualPage = 'register';

const headerStyle = {
    textAlign: 'center',
    color: '#fff',
    height: 64,
    paddingInline: 10,
    lineHeight: '64px',
    backgroundColor: '#7dbcea',
};

const contentStyle = {
    textAlign: 'left',
    minHeight: '76vh',
    lineHeight: '60px',
    color: '#fff',
    backgroundColor: 'rgb(22, 22, 22)',
};

const footerStyle = {
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#7dbcea',
};

const EmailValidation = () => {

    const [percent, setPercent] = useState(0);
    let { token } = useParams();
    const [dataa, setDataa] = useState(null)
    const [message, Setmessage] = useState(null)
    const [showProgressBar, setShowProgressBar] = useState(false)
    const [showTable, setShowTable] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const resp = await valide_email(token);
                if (resp.progressing) {
                    setShowProgressBar(true)
                    animation(resp)
                }
                else {
                    Setmessage(resp);
                }

            } catch (error) {
                if (error.response) {
                    console.error(error.response.data.msg);
                    // Gérez le message d'erreur renvoyé par le serveur ici
                } else {
                    console.error("Erreur lors de la validation de l'email:", error.message);
                    // Gérez l'erreur ici
                }
            }
        };
        fetchData();
    }, []);

    const animation = (data) => {
        setInterval(() => {
            setPercent((prevPercent) => {
                const newPercent = prevPercent + 10;
                if (newPercent > 100) {

                    Setmessage(data);
                    setShowTable(true)
                    return 100;
                }
                return newPercent;
            });
        }, 1000);

    }

    const columns = [
        {
            title: 'Prénom',
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: 'Nom',
            dataIndex: 'lastName',
            key: 'lastName',
        },

        {
            title: 'email',
            dataIndex: 'email',
            key: 'email',
        },

        {
            title: 'Titre',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'status',
            dataIndex: 'status',
            key: 'status',
        },

    ];

    return (
        <Space direction="vertical" style={{ width: '100%', height: '100vh' }} size={[0, 48]}>
            <Layout>
                <Header style={headerStyle}>
                    <Nav pageSelected={actualPage} />
                </Header>
                <Content style={contentStyle}>
                    <h1 style={{ textAlign: 'center' }}>Validation de email</h1>
                    <h2 style={{ textAlign: 'center', color: message?.data?.status ? '#52c41a' : 'rgb(132, 32, 41)' }}>{message?.msg}</h2>
                    <Row style={{ textAlign: 'center', padding: '15px' }} >

                        {showProgressBar &&
                            <Col span={24}>
                                <Flex vertical gap="small">
                                    <Progress percent={percent} type="line" />
                                    <Progress
                                        percent={percent}
                                        type="circle"
                                        format={() => <span style={{ color: 'white' }}>{percent}</span>}
                                    />
                                </Flex>
                            </Col>
                        }
                        {message?.data &&

                            <Col span={24} style={{ paddingTop: '20px' }} >
                             
                                <Table dataSource={[message?.data]} columns={columns} pagination={false} />
                                <h3>
                                    Liens vers le salon :
                                    <Link to={`/tchat/${message?.data.createNumber}`} target={'_blank'}>
                                        {message?.data.title}
                                    </Link>
                                </h3>
                            </Col>
                        }
                    </Row>
                </Content>
                <Footer style={footerStyle}>Footer</Footer>
            </Layout>
        </Space>
    );
};

export default EmailValidation;
