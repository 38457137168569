import { axios } from "./axios_custom"

export const getUserMessage = ( data ) => {
    const token = data.usertoken
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const body = {
        data:'data'
    };
    return axios
        .post(`/getUserMessage`, body, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}

export const deleteUserMessage = async ( data ) => {
    const token = data.token
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const body = {
        data:data
    };
    return axios
        .post(`/deleteUserMessage`, body, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}


export const replyUserMessage = async ( data ,user_token ) => {
    const token = user_token
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const body = {
        data:data
    };
    return axios
        .post(`/replyUserMessage`, body, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}
