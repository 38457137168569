import { Space, Layout, Col, Row, Button } from 'antd';
import Nav from '../components/pages/layout/Nav';
import { Link, useLocation } from "react-router-dom";
import '../css/index.css'
const { Header, Footer, Content } = Layout;
const actualPage = 'home'

const headerStyle = {
    textAlign: 'center',
    color: '#fff',
    height: 64,
    paddingInline: 10,
    lineHeight: '64px',
    backgroundColor: '#7dbcea',
};

const contentStyle = {
    textAlign: 'center',
    minHeight: '76vh',

    color: '#fff',
    backgroundColor: 'rgb(22, 22, 22)',
};

const footerStyle = {
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#7dbcea',

};

const Home = () => {

    return (
        <Space direction="vertical" style={{ height: '100vh' }} size={[0, 48]}>
            <Layout>
                <Header style={headerStyle}>
                    <Nav pageSelected={actualPage} />
                </Header>
                <Content style={contentStyle}>

                    <Row className="containerHome" gutter={{ xs: 10, sm: 10, md: 10, lg: 35 }}>

                        <Col xs={24} sm={12} >
                            <div className="videochatText">
                                VIDÉO CHAT
                            </div>

                            <div className="zone1">
                                Voyez vos amis <span className="smileText">sourire  grâce au</span> <span className='chatVideoText'>chat vidéo</span>
                            </div>

                            <div className='slogan'>
                                Démarquez votre marque de la concurrence, donnez des super pouvoirs à votre équipe commerciale et suscitez un véritable plaisir client, le tout grâce au chat vidéo direct.
                            </div>

                            <Row className='zone3'>
                                <Col span={1} className='zone4'>
                                    <img src="/Tick-box.svg" loading="lazy" width="23" alt="Case" class="tick-box" />
                                </Col>

                                <Col span={11} className='zone5'>
                                    <div>Intégré aux sites Web et aux applications</div>
                                </Col>

                                <Col span={1} className='zone4'>
                                    <img src="./Tick-box.svg" loading="lazy" width="23" alt="Case" class="tick-box" />
                                </Col>

                                <Col span={11} className='zone5'>
                                    <div>À la demande et programmé</div>
                                </Col>

                                <Col span={24} className='demobtn'>
                                    <Button>
                                        <Link to={"/tchat/1001"} target={'_blank'}>
                                            {"Démo"}
                                        </Link>
                                    </Button>
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={24} sm={12} >

                            <div style={{ color: 'black' }} className="zone2">
                                <img className='zone2Image ant-image-img' src="./test.webp" alt="image" />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24} className='zone6'>
                            FONCTIONNALITÉS DE CHAT VIDÉO AMÉLIORÉES
                        </Col>

                        <Col span={24} className='zone7'>
                            Offrez une personnalisation maximale avec le chat vidéo 1-1
                        </Col>
                    </Row>

                    <Row className="containerHome" gutter={{ xs: 10, sm: 10, md: 10, lg: 35 }}>
                        <Col xs={24} sm={12} >
                            <div className="zone8">
                                Créez des expériences vidéo uniques
                            </div>
                            <div className='zone9'>
                                Interactions vidéo à la demande et programmées sur n’importe quel appareil depuis n’importe quel endroit.
                            </div>
                            <div className='zone9'>
                                Inclut des fonctionnalités telles que la vidéo 1-1 et multi-parties, les arrière-plans virtuels, le flou, l'enregistrement vidéo, la surveillance, etc., le tout entièrement configurable et intégré de manière transparente à votre site Web et à votre application.
                            </div>
                        </Col>

                        <Col xs={24} sm={12} >
                            <div style={{ color: 'black' }} className="zone2">
                                <img className='zone2Image ant-image-img' src="./video-experiences.png" alt="image" />
                            </div>
                        </Col>
                    </Row>


                    <Row className="containerHome" gutter={{ xs: 10, sm: 10, md: 10, lg: 35 }}>
                        <Col xs={24} sm={12} >
                            <div style={{ color: 'black' }} className="zone2">
                                <img style={{ width: '410px' }} className='zone2Image ant-image-img' src="./Video-chat-on-mobile.png" alt="image" />
                            </div>
                        </Col>

                        <Col xs={24} sm={12} >
                            <div className="zone8">
                                Engagez-vous plus rapidement, vendez mieux
                            </div>
                            <div className='zone9'>
                                Vos clients de grande valeur sont 4 fois plus susceptibles d'effectuer un achat lorsque vous proposez l'expérience en personne en ligne via le chat vidéo. De plus, les interactions sont également résolues 60 % plus rapidement.                            </div>
                            <div className='zone9'>
                                Inclut des fonctionnalités telles que la vidéo 1-1 et multi-parties, les arrière-plans virtuels, le flou, l'enregistrement vidéo, la surveillance, etc., le tout entièrement configurable et intégré de manière transparente à votre site Web et à votre application.
                            </div>
                        </Col>
                    </Row>

                    <Row className="containerHome" gutter={{ xs: 10, sm: 10, md: 10, lg: 35 }}>
                        <Col xs={24} sm={24}>
                            <div className='zone10'>
                                <div className='zone11'>
                                    « Talkative est une plateforme incroyable et très polyvalente. Ils offrent une connexion numérique avec une touche humaine.
                                </div>
                                <Row gutter={[10, 10]}>
                                    <Col xs={18} sm={18} className='zone12'>
                                        AOne ATM utilise le chat vidéo pour interagir en face-à-face avec des clients de grande valeur.
                                    </Col>
                                    <Col xs={6} sm={6}>
                                        <img className='zone12Image ant-image-img' src="/A-one-logo.png" alt="image" />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>

                </Content>
                <Footer style={footerStyle}>Footer</Footer>
            </Layout>
        </Space>
    );
};


export default Home
