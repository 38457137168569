import { axios } from './axios_custom';

export const verify_before_pay = async (token, room, socketId ,payloadShoutcast , payloadChat) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const requestData = {
        room,
        socketid: socketId,
         payloadShoutcast,
         payloadChat
    };
    return axios
        .post(`/verify_before_pay`, requestData, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors pay :", error);
            throw error;
        });
}

export const adminSetSelectedTemplateColor = async (themeColor, token, room) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const body = {
        themeColor:themeColor,
        room:room
    };
    return axios
        .post(`/adminSetSelectedTemplateColor`, body, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}



export const adminSendPlayerConfig = async ( token, room ,data ) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const body = {
        room:room,
        data:data
    };
    return axios
        .post(`/adminSendPlayerConfig`, body, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}


export const adminGetPlayerConfig = async ( token, room ) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const body = {
        room:room,
    };
    return axios
        .post(`/adminGetPlayerConfig`, body, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}

export const senUserdMessage = async ( message , fromUser ,toUser) => {
    const headers = {
        Authorization: `Bearer ${fromUser?.token}`,
    };
    const body = {
        message : message,
        fromUser: fromUser,
        toUser : toUser
    };
    return axios
        .post(`/senUserdMessage`, body, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de l'envois de message  :", error);
            throw error;
        });
}


export const adminTogglePunch = async ( token, room , punchState) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const body = {
        room:room,
        punch:punchState
    };
    return axios
        .post(`/adminTogglePunch`, body, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}


export const adminGetTemplate = async ( token, room) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const body = {
        room:room
    };
    return axios
        .post(`/adminGetTemplate`, body, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}



export const setSavedByUserTemplateUserSaved = async (selectedTemplate, token, room) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const body = {
        room:room,
        selectedTemplate : selectedTemplate
    };
    return axios
        .post(`/setSavedByUserTemplateUserSaved`, body, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}


export const setSavedByUserTemplate = async (selectedTemplate, token, room) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const body = {
        room:room,
        selectedTemplate : selectedTemplate
    };
    return axios
        .post(`/setSavedByUserTemplate`, body, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}



export const saveThemeByAdmin = async (themeColor, token) => {
    //fonction temporaire pour sauvegarder les themes noel , paque , red , yellow ect ect
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const body = {
        themeColor:themeColor,
        
    };
    return axios
        .post(`/saveThemeByAdmin`, body, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}


export const saveUserTemplateInBack = async (themeName, token) => {
    //fonction temporaire pour sauvegarder les themes noel , paque , red , yellow ect ect
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const body = {
        themeName:themeName,
        
    };
    return axios
        .post(`/saveUserTemplateInBack`, body, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}


export const deleteImageTemplate = async (id,user) => {
    const headers = {
        Authorization: `Bearer ${user.token}`,
    };
    const body = {
        id:id,
        room:user.room
    };
    return axios
        .post(`/deleteImageTemplate`, body, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}

export const getRoomById =  async (id,token) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const body = {
        id:id,
    };
    return axios
        .post(`/GetRoomByID`, body, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}

export const getListBan = async (token,room) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const body = {
        room:room,
    };
    return axios
        .post(`/getListBan`, body, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        }); 
}

export const adminGetAllUserApi = async (token, room, socketId) => {
    return axios.get(`/adminGetAllUser`, {
        headers: {
            Authorization: `Bearer ${token}`,
            room: room,
            socketid: socketId
        }
    })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête d'envoi de l'Avatar :", error);
            throw error;
        });
}

export const adminGetAllBotApi = async (token, room, socketId) => {
    return axios.get(`/adminGetAllBotApi`, {
        headers: {
            Authorization: `Bearer ${token}`,
            room: room,
            socketid: socketId
        }
    })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête d'envoi de la liste de message Bot :", error);
            throw error;
        });
}

export const adminGetAllShoutcastApi = async (token, room, socketId) => {
    return axios.get(`/adminGetAllShoutcast`, {
        headers: {
            Authorization: `Bearer ${token}`,
            room: room,
            socketid: socketId
        }
    })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête d'envoi de la liste de Shoutcast :", error);
            throw error;
        });
}

export const adminGetAllPunchImageApi = async (token, room, socketId) => {
    return axios.get(`/adminGetAllPunchImageApi`, {
        headers: {
            Authorization: `Bearer ${token}`,
            room: room,
            socketid: socketId
        }
    })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête d'envoi de l'Avatar :", error);
            throw error;
        });
}

export const adminGetAllRoomApi = async (token, room, socketId) => {
    return axios.get(`/adminGetAllRoom`, {
        headers: {
            Authorization: `Bearer ${token}`,
            room: room,
            socketid: socketId
        }
    })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête d'envoi de l'Avatar :", error);
            throw error;
        });
}

export const adminGetAllRoleApi = async (token, room, socketId) => {
    return axios.get(`/adminGetAllRole`, {
        headers: {
            Authorization: `Bearer ${token}`,
            room: room,
            socketid: socketId
        }
    })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête d'envoi des roles :", error);
            throw error;
        });
}

export const returnAllTemplateColors = async (token, room, socketId) => {
    return axios.get(`/returnAllTemplateColors`, {
        headers: {
            Authorization: `Bearer ${token}`,
            room: room,
            socketid: socketId
        }
    })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête d'envoi des roles :", error);
            throw error;
        });
}


export const adminGetAllTemplate = async (token, room, socketId) => {
    return axios.get(`/adminGetAllTemplate`, {
        headers: {
            Authorization: `Bearer ${token}`,
            room: room,
            socketid: socketId
        }
    })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête d'envoi des roles :", error);
            throw error;
        });
}

export const adminGetAllTemplateColor = async (token, room, socketId) => {
    return axios.get(`/adminGetAllTemplateColor`, {
        headers: {
            Authorization: `Bearer ${token}`,
            room: room,
            socketid: socketId
        }
    })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête d'envoi des roles :", error);
            throw error;
        });
}

export const getClientStatusService = async (token, room) => {
    return axios.get(`/getClientStatusService`, {
        headers: {
            Authorization: `Bearer ${token}`,
            room: room,
        }
    })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête d'envoi des roles :", error);
            throw error;
        });
}

export const adminDeleteShoutcastApi = async (token, room, socketId , target) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const requestData = {
        room,
        socketid: socketId,
        target : target
    };
    return axios
        .post(`/adminDeleteShoutcastApi`, requestData, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}

export const adminDeleteUserApi = (id,token , room) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const requestData = {
        id:id,
        room : room
    };
    return axios
        .post(`/adminDeleteUserApi`, requestData, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}

export const adminPunchDeleteImageApi = (token, room, socketId , target) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const requestData = {
        room,
        socketid: socketId,
        target : target
    };
    return axios
        .post(`/adminPunchDeleteImageApi`, requestData, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}

export const updateRole = async (data, token, room, socketId) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const requestData = {
        data,
        room,
        socketid: socketId
    };
    return axios
        .post(`/updateRole`, requestData, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}

export const updateBot = async (data, token, room, socketId) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const requestData = {
        data,
        room,
        socketid: socketId
    };
    return axios
        .post(`/updateBot`, requestData, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}

export const adminUpdateShoutcastApi =  async ( token, room, socketId , newip ,shoutcastPath,metadatatechnic,type, dbId) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const requestData = {
        dbId,
        newip,
        shoutcastPath,
        metadatatechnic,
        type,
        room,
        socketId
    };
    return axios
        .post(`/adminUpdateShoutcastApi`, requestData, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}

export const updateUser = async (data, token, room, socketId) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const requestData = {
        data,
        room,
        socketid: socketId
    };

    return axios
        .post(`/updateUser`, requestData, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}

export const updateRoom = async (data, token, room, socketId) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const requestData = {
        data,
        room,
        socketid: socketId
    };
    return axios
        .post(`/updateRoom`, data, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}

export const adminAddBotApi = async (data, token, room, socketId) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const requestData = {
        data,
        room,
        socketid: socketId
    };

    return axios
        .post(`/adminAddBotApi`, requestData, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête de Bot :", error);
            throw error;
        });
}

export const adminDeleteBot = async (id, token, room, socketId) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const requestData = {
        id,
        room,
        socketid: socketId
    };
    return axios
        .post(`/adminDeleteBot`, requestData, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête de Bot :", error);
            throw error;
        });
}

export const adminAddUserApi = async (data, token, room, socketId) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const requestData = {
        data,
        room,
        socketid: socketId
    };

    return axios
        .post(`/adminAddUserApi`, requestData, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}

export const adminAddShoutcastApi = async (data, token, room, username ,socketId) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const requestData = {
        username,
        data,
        room,
        socketid: socketId
    };

    return axios
        .post(`/adminAddShoutcastApi`, requestData, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}

export const adminAddRoomApi = async (data, token, room, socketId, formData) => {
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data', // N'oubliez pas d'ajouter le bon en-tête
    };

    
    // Ajoutez les données du formulaire à l'objet FormData
    for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
            formData.append(key, data[key]);
        }
    }

    //data non contenu dans le form mais neccessaire
    formData.append("token", token);
    formData.append("room", room);
    formData.append("socketId", socketId);

    return axios
        .post(`/adminAddRoomApi`, formData, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}

export const adminAddPunchImageApi = async (data, token, room, socketId, formData,username) => {
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data', // N'oubliez pas d'ajouter le bon en-tête
    };

    
    // Ajoutez les données du formulaire à l'objet FormData
    for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
            formData.append(key, data[key]);
        }
    }

    //data non contenu dans le form mais neccessaire
    formData.append("token", token);
    formData.append("room", room);
    formData.append("socketId", socketId);
    formData.append("username", username);

    return axios
        .post(`/adminAddPunchImageApi`, formData, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}

export const adminPunchSetActiveImageApi = async  ( token, room, socketId,username ,target) => {
    const headers = {
        Authorization: `Bearer ${token}`,
        room: room,
        'Content-Type': 'multipart/form-data',
    };
 
    let formData = new FormData();
    formData.append("token", token);
    formData.append("room", room);
    formData.append("socketId", socketId);
    formData.append("username", username);
    formData.append("target", target);

    return axios
        .post(`/adminPunchSetActiveImageApi`, formData, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            alert('Une erreur (454323) s\'est produite');
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}

export const adminSetActiveShoutcastApi = async ( token, room, socketId,username ,target) => {
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
    };
 
    let formData = new FormData();
    formData.append("token", token);
    formData.append("room", room);
    formData.append("socketId", socketId);
    formData.append("username", username);
    formData.append("target", target);

    return axios
        .post(`/adminSetActiveShoutcastApi`, formData, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            alert('Une erreur (454323) s\'est produite');
            console.error("Une erreur s'est produite lors de la requête adminSetActiveShoutcastApi :", error);
            throw error;
        });
}

export const adminSendImageTemplate = async ( token, room, socketId, formData) => {
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data', // N'oubliez pas d'ajouter le bon en-tête
    };

    //data non contenu dans le form mais neccessaire
    formData.append("token", token);
    formData.append("room", room);
    formData.append("socketId", socketId);

    return axios
        .post(`/adminSendImageTemplate`, formData, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}

export const adminSendLogoTemplate = async ( token, room, socketId, formData) => {
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data', // N'oubliez pas d'ajouter le bon en-tête
    };

    //data non contenu dans le form mais neccessaire
    formData.append("token", token);
    formData.append("room", room);
    formData.append("socketId", socketId);

    return axios
        .post(`/adminSendLogoTemplate`, formData, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}

export const adminSendColorTemplate = async ( token, room, socketId, formData) => {
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data', // N'oubliez pas d'ajouter le bon en-tête
    };

    //data non contenu dans le form mais neccessaire
    formData.append("token", token);
    formData.append("room", room);
    formData.append("socketId", socketId);

    return axios
        .post(`/adminSendColorTemplate`, formData, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}



export const adminAddRoleApi = async (data, token, room, socketId) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const requestData = {
        data,
        room,
        socketid: socketId,
       
    };

    return axios
        .post(`/adminAddRoleApi`, requestData, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}

export const service_pricing = async (token) => {
    return axios.get(`/service_pricing`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête d'envoi des roles :", error);
            throw error;
        });
}

export const service_tchat = async (token) => {
    return axios.get(`/plansChat`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête d'envoi des roles :", error);
            throw error;
        });
}

export const getservice_shoutcast = async (token) => {
    return axios.get(`/plansShoutcast`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête d'envoi des roles :", error);
            throw error;
        });
}

export const service_shoutcast = async(token) => {
    return axios.get(`/plansShoutcast`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête d'envoi des roles :", error);
            throw error;
        });
}

export const getServiceExpiration = async (token) => {
    return axios.get(`/getServiceExpiration`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête getServiceExpiration :", error);
            throw error;
        });
}

export const pay = async (skuShoutcast, skuTchat, token) => {
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
    };

    const data = {
        skuShoutcast,
        skuTchat,
    };

    return axios
        .post(`/pay`, data, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
};
