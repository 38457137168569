import { useState, useEffect, forwardRef } from 'react';
import { Button, Table, message } from 'antd';
import { connect } from 'react-redux';
import { deleteImageTemplate as deleteImageTemplateAPI } from '../../../../../endpoints/admin';

const URL = process.env.REACT_APP_MODE_ENV === "development"
    ? process.env.REACT_APP_URL_SERVER_DEV
    : process.env.REACT_APP_URL_SERVER_PROD;

const ListeUploadedFile = forwardRef(({ user, ...props }, ref) => {

    const [uploadedFiles, setUploadedFiles] = useState(props.imageTemplateFromServer);

    // **Mise à jour de l'état lorsqu'un nouveau template est chargé**
    useEffect(() => {
        setUploadedFiles(props.imageTemplateFromServer);
    }, [props.imageTemplateFromServer]);

    // Fonction pour supprimer un fichier
    const handleDeleteImage = (id, user) => {
        deleteImageTemplateAPI(id, user)
            .then(() => {
                message.success('Image supprimée avec succès.');
                // Mise à jour immédiate de l'état après suppression
                setUploadedFiles((prevFiles) => prevFiles.filter(file => file.id !== id));
               // Appel de la fonction `test` après suppression
            })
            .catch(err => {
                console.error('Erreur lors de la suppression de l\'image:', err);
                message.error('Erreur lors de la suppression de l\'image.');
            });
    };

    // Définition des colonnes de la table
    const columns = [
        {
            title: 'Image',
            dataIndex: 'file_name',
            key: 'file_name',
            render: (file_name) => (
                <img
                    src={`${URL}/uploads/images/${file_name}`}
                    alt="Image"
                    style={{ width: '50px', height: '50px' }}
                />
            ),
        },
        {
            title: 'Utilisateur',
            dataIndex: 'from_user',
            key: 'from_user',
        },
        {
            title: 'Sélectionner',
            dataIndex: 'file_name',
            key: 'select_action',
            render: (file_name) => (
                <Button
                    onClick={() => props.fileSelected(file_name)}
                    style={{ backgroundColor: '#28a745', color: '#ffffff' }}
                    type="default"
                >
                    Sélectionner
                </Button>
            ),
        },
        {
            title: 'Supprimer',
            dataIndex: 'id',
            key: 'delete_action',
            render: (id) => (
                <Button
                    onClick={() => handleDeleteImage(id, user)}
                    style={{ backgroundColor: '#dc3545', color: '#ffffff' }}
                    type="default"
                >
                    Supprimer
                </Button>
            ),
        },
    ];

    return (
        <Table
            dataSource={uploadedFiles.map((item, index) => ({ ...item, key: index.toString() }))}
            columns={columns}
            pagination={{ pageSize: 10 }}
        />
    );
});

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(ListeUploadedFile);
