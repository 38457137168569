import { axios } from "../../../endpoints/axios_custom";

export const getAllRoom = (token) => {
    return axios.get(`/getAllRoom`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête getAllRoom sysop :", error);
            throw error;
        });
}

export const getAllRoomDbOwner = (token) => {
    return axios.get(`/getAllRoomDbOwner`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête getAllRoom sysop :", error);
            throw error;
        });
}

export const getAllUserInRoom = (data, token) => {
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
    };

    const body = {
        roomId: data // Paramètre supplémentaire
    };

    return axios
        .post('/getAllUserInRoom', body, {
            headers: headers
        })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}


export const getRoomClass = (data, token) => { 
    const headers = {
        Authorization: `Bearer ${token}`
    };

    const params = {
        // Ajoutez le paramètre supplémentaire ici
        id: data
    };

    return axios
        .get(`/getRoomClass`, {
            params: data,
            headers: headers,
            params: params
        })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}


