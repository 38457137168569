import React, { forwardRef, useEffect, useState, useRef } from 'react';
import { Button, Modal, Input, Typography, Divider, message as antdMessage } from 'antd';
import { connect } from 'react-redux';
import { senUserdMessage } from '../../../../endpoints/admin';
import EmojiPicker from 'emoji-picker-react';
import frenchStrings from 'react-timeago/lib/language-strings/fr';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';

const formatter = buildFormatter(frenchStrings);

const { TextArea } = Input;
const { Title, Text } = Typography;

const ModalnotifyUser = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const emojiPickerRef = useRef(null); // Référence pour détecter les clics à l'extérieur

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
                setShowEmojiPicker(false); // Ferme le sélecteur d'émojis si clic à l'extérieur
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleEmojiClick = (emojiData) => {
        setMessage((prevMessage) => prevMessage + emojiData.emoji); // Ajout de l'émoji dans le message
    };

    const handleOk = async () => {
        if (!title.trim()) {
            antdMessage.warning("Le titre ne peut pas être vide.");
            return;
        }
    
        if (!message.trim()) {
            antdMessage.warning("Le message ne peut pas être vide.");
            return;
        }
    
        try {
            await senUserdMessage(message, user, props?.sendNotify);
    
            // ✅ Effacer les champs du formulaire
            setMessage('');
            setTitle('');
            setShowEmojiPicker(false); // ✅ Fermer le sélecteur d'emoji
    
            antdMessage.success("Message envoyé avec succès !");
            props.setIsModalOpenNotifyUser(false);
        } catch (error) {
            console.error("Erreur lors de l'envoi du message :", error);
            antdMessage.error("Erreur lors de l'envoi du message. Veuillez réessayer.");
        }
    };

    const handleCancel = () => {
        // ✅ Efface les champs du formulaire
        setMessage('');
        setTitle('');
        setShowEmojiPicker(false); // ✅ Ferme le sélecteur d'emoji s'il est ouvert
    
        // ✅ Ferme le modal
        props.setIsModalOpenNotifyUser(false);
    };

    return (
        <Modal
            width={'50%'}
            title={
                <>
                    <Title level={4} style={{ textAlign: 'center', marginBottom: '0' }}>
                        ✉️ Envoyer un message à :
                        <span style={{ color: '#1890ff' }}>
                            {props?.sendNotify?.username?.toUpperCase() || 'Inconnu'}
                        </span>
                    </Title>
                    <Divider />
                </>
            }
            style={{ textAlign: 'center' }}
            open={props.isModalOpenNotifyUser}
            onOk={handleOk}
            onCancel={handleCancel}
            okText="Envoyer"
            cancelText="Annuler"
        >

            {/* Champ affichant le nom (non modifiable) */}
            <Input
                value={`@ ${props?.sendNotify?.username?.toUpperCase() || 'Inconnu'}`}
                disabled
                style={{
                    width: '100%',
                    marginBottom: '12px',
                    padding: '10px',
                    backgroundColor: '#f5f5f5',
                    color: '#000',
                    fontWeight: 'bold',
                    borderRadius: '8px',
                    borderColor: '#d9d9d9'
                }}
            />

               <Input
                value={`@ ${props?.sendNotify?.username?.toUpperCase() || 'Inconnu'}`}
                disabled
                style={{
                    width: '100%',
                    marginBottom: '12px',
                    padding: '10px',
                    backgroundColor: '#f5f5f5',
                    color: '#000',
                    fontWeight: 'bold',
                    borderRadius: '8px',
                    borderColor: '#d9d9d9'
                }}
            />

            {/* Champ Titre */}
            <Input
                placeholder="Titre du message"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                style={{
                    width: '100%',
                    marginBottom: '12px',
                    padding: '10px',
                    borderRadius: '8px',
                    borderColor: '#d9d9d9'
                }}
            />

            {/* Champ Message */}
            <TextArea
                style={{
                    width: '100%',
                    height: '200px',
                    borderRadius: '8px',
                    padding: '10px',
                    borderColor: '#d9d9d9',
                    marginBottom: '10px'
                }}
                placeholder="Écrire votre message ici..."
                allowClear
                onChange={(e) => setMessage(e.target.value)}
                value={message}
            />

            {/* Bouton Emoji */}
            <div style={{ display: 'flex', gap: '10px' }}>
                <Button
                    onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                    style={{
                        backgroundColor: '#1890ff',
                        color: 'white',
                        border: 'none'
                    }}
                >
                    😊 Ajouter des émojis
                </Button>
            </div>

            {/* Emoji Picker avec référence pour fermeture automatique */}
            {showEmojiPicker && (
                <div ref={emojiPickerRef} style={{ marginTop: '10px' }}>
                    <EmojiPicker
                        onEmojiClick={handleEmojiClick}
                        autoFocusSearch={false}  // Désactive le focus automatique sur la barre de recherche
                    />
                </div>
            )}
        </Modal>
    );
});

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ModalnotifyUser);
