import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import { SocketContext } from '../../../../components/tchat/SocketContext';
import sendRequest from '../../../../components/socket/requestModule';
import { Device } from 'mediasoup-client';
import ShowWebcam from './ShowWebcam';

const CreateConsumer = ({ roomName }) => {
    const socket = useContext(SocketContext);
    const [userDataFromBack, setUserDataFromBack] = useState([]);
    const device = useRef(null);
    const consumerTransport = useRef(null);

    useEffect(() => {
        if (socket) {
            init(roomName);
          
        }
        consumerTransport.current = null
    }, [socket,roomName]);

 

    const init = async (roomName) => {
        try {
            const routerRtpCapabilities = await sendRequest(socket, 'getRouterRtpCapabilities', {});
            device.current = new Device();
            await device.current.load({ routerRtpCapabilities });

            if (!consumerTransport.current) {
                const params = await sendRequest(socket, 'createConsumerTransportSysop', roomName);
                consumerTransport.current = device.current.createRecvTransport(params);
                console.log('consumerTransport ready');
            }
            setupTransportListeners(roomName);

            const currentInfo = await sendRequest(socket, 'getCurrentRemoteInfoSysop', { localId: socket.id });
            const remoteUsers = currentInfo.remoteUsers.map(user => ({
                id: user.socketId,
                username: user.username,
                kind: 'video',
                stream: null,
                isLocal: false
            }));

            setUserDataFromBack(remoteUsers);

            for (const rId of currentInfo.remoteVideoIds) {
                addConsumer(consumerTransport.current, rId, null, 'video');
            }
        } catch (error) {
            console.error(`[connect] [getCurrentRemoteInfo] Error: ${error}`);
        }
    };

    const setupTransportListeners = useCallback((room) => {
        if (consumerTransport.current) {
            consumerTransport.current.on('connect', async ({ dtlsParameters }, callback, errback) => {
                try {
                    const parameters = {
                        dtlsParameters,
                        roomParameters: { room: room }  // Ajout d'un autre objet
                    };
                    await sendRequest(socket, 'connectConsumerTransportSysop', { parameters  });
                    callback();
                } catch (err) {
                    errback(err);
                }
            });

            consumerTransport.current.on('connectionstatechange', (state) => {
                switch (state) {
                    case 'connecting':
                        console.log('consumerTransport connecting...');
                        break;
                    case 'connected':
                        console.log('consumerTransport connected.');
                        break;
                    case 'failed':
                        console.log('consumerTransport connection failed.');
                        consumerTransport.current.close();
                        break;
                    default:
                        break;
                }
            });
        } else {
            console.error('[setupTransportListeners] consumerTransport not initialized (application will likely crash)');
        }
    }, [socket]);

    const addConsumer = useCallback(async (transport, remoteSocketId, prdId, trackKind) => {
        try {
            const { rtpCapabilities } = device.current;
            const data = await sendRequest(socket, 'consumeAddSysop', {
                rtpCapabilities,
                remoteId: remoteSocketId,
                kind: trackKind
            });

            const { producerId, id, kind, rtpParameters } = data;
            const consumer = await transport.consume({
                id,
                producerId,
                kind,
                rtpParameters
            });

            setUserDataFromBack(prevState => prevState.map(user => {
                if (user.id === remoteSocketId) {
                    return {
                        ...user,
                        _track: consumer.track // Updated here
                    };
                }
                return user;
            }));

            if (kind === 'video') {
                await sendRequest(socket, 'resumeAddSysop', { remoteId: remoteSocketId, kind });
                console.log('resumeAddSysop OK');
            }

        } catch (error) {
            console.error(`[addConsumer] Error adding consumer: ${error}`);
        }
    }, [socket]);

    return (
        <>
            <Row >
                {userDataFromBack.map(user => (
                    <ShowWebcam key={user.id} userDataFromBack={user} />
                ))}
            </Row>
        </>
    );
};

const mapStateToProps = ({ user, message }) => ({ user, message });
export default connect(mapStateToProps)(CreateConsumer);
