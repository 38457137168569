import React, { useContext, useState } from 'react';
import { Modal, Button, Typography, Table } from 'antd';
import { connect } from 'react-redux';
import { SocketContext } from '../../../components/tchat/SocketContext';
import sendRequest from '../../../components/socket/requestModule';

const { Text } = Typography;

const URL =
    process.env.REACT_APP_MODE_ENV === 'development'
        ? process.env.REACT_APP_URL_SERVER_DEV
        : process.env.REACT_APP_URL_SERVER_PROD;

const ModalShoutcastRender = ({ setModalShoutcast, modalShoutcast }) => {
    const socket = useContext(SocketContext);
    const [messages, setMessages] = useState(null);

    const handleOk = () => {
        setModalShoutcast(false);
    };

    const handleCancel = () => {
        setModalShoutcast(false);
    };

    const handlerMessage = () => {
        // Logic to handle message sending
    };

    return (
        <Modal
            width={600}
            visible={modalShoutcast} // Use 'visible' instead of 'open' for Modal
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button key="cancel" onClick={handleCancel}>
                    Annuler
                </Button>,
                <Button key="send" type="primary" onClick={handlerMessage}>
                    Envoyer
                </Button>,
            ]}
        >
            Modal Shoutcast
        </Modal>
    );
};

export default ModalShoutcastRender;
