import { setUserApp } from "../../../action/action";
import sendRequest from "../../socket/requestModule";

export const join_room = async (props, fpHash) => {
    if (!props.socket || !props.socket.id) {
        console.warn("⚠️ socket.id est indisponible, attente...");
        return;
    }

    props?.dispatch(setUserApp({
        ...props.user, // ✅ Conserve les autres données utilisateur
        socketId: props.socket.id,
    }));

    sendRequest(props.socket, 'join_room', { 
        username: props.user?.username, 
        roomId: props.user?.selectedRoom, 
        room: props.room, 
        fingerPrint: fpHash, 
        token: props.user?.token 
    })
    .then(async response => {
        setTimeout(async () => {
            //auto open cam on join room
            //await enableLocalVideo()
        }, 5000);
    })
    .catch(error => {
        alert(error + ' error');
    });
};
