import { useState, useContext, useEffect, useRef, Fragment, useMemo, createRef, forwardRef } from 'react';
import { connect } from 'react-redux';
import { SocketContext } from '../../SocketContext';
import { Modal } from "antd";
import imageCompression from 'browser-image-compression';
import { sendImageChat } from '../../../../endpoints/sendImageChat'

const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

const SendImageToChat = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {

    const socket = useContext(SocketContext);
    const [selectedFile, setSelectedFile] = useState(null);
    let sizeOfImage = 99999999999999;
    const mounteImagedRef = useRef(false)

    const OpenMondalSendImageOk = () => {
        props.setmodalSendImageChat(false);
    };
    const OpenMondalSendImageCancel = () => {
        props.setmodalSendImageChat(false);
    };

    const handleFileSelect = (event) => {

        const data = event.target.files[0]
        if (data.type === 'image/png' || data.type === 'image/jpeg' || data.type === 'image/webp') {
            const fileName = data.name
            const compress = async data => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    //message reader.error
                }
                await imageCompression(data, { maxSizeMB: 1, useWebWorker: true }).then(res => {
                    if ((res.size / 1024).toFixed(0) === sizeOfImage && sizeOfImage > 400) {
                        //retourne un message d'erreur
                    }
                    if (sizeOfImage >= 400) {
                        res.name = fileName
                        mounteImagedRef.current = true
                        setSelectedFile(res)

                    } else {
                        reader.readAsDataURL(res)
                    }
                    sizeOfImage = (res.size / 1024).toFixed(0);
                })
            }
            compress(data)
            event.target.value = ''
        }
        else if (data.type === 'image/gif') {
            mounteImagedRef.current = true
            setSelectedFile(data)
        }
        else {
           // props?.dispatch(setMessageApp({ type: 'error', message: 'fichier refuser', notView: true }));
        }
    }

    const handleSubmit = async () => {
        const formData = new FormData();
        formData.append("file", selectedFile);
        try {
            sendImageChat(user?.selectedRoom, user?.token, user?.room, user?.socketId, formData).then(resp => {
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (mounteImagedRef.current) {
            handleSubmit()
            mounteImagedRef.current = false
        }
    }, [selectedFile])

    return (
        <>
            <Modal title="Envoyer une image" open={props.modalSendImageChat} onOk={OpenMondalSendImageOk} onCancel={OpenMondalSendImageCancel}>
                <input onChange={handleFileSelect} name='file' id="file-input" type="file" accept="image/png, image/jpeg" />
            </Modal>
        </>
    )
})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SendImageToChat);
