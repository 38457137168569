import React, { useState, useEffect, forwardRef ,useContext } from 'react';
import { Col, Row, Table, Typography, Select, Button } from 'antd';
import { connect } from 'react-redux';
import { service_shoutcast, service_tchat, pay ,getServiceExpiration} from '../../../../endpoints/admin';
import { SocketContext } from '../../SocketContext';

const { Title } = Typography;
const { Option } = Select;

const Pay = forwardRef(({ user, ...props }, ref) => {
    const socket = useContext(SocketContext);
    const [shoutcastExpiration , setShoutcastExpiration ] = useState([]);
    const [tchatExpiration , setTchatExpiration ] = useState([]);
    const [pricingData, setPricingData] = useState([]);
    const [tchatData, setTchatData] = useState([]);
    const [dataSource, setDataSource] = useState([
      
        {
            key: '2',
            service: 'tchat',
            terme: '',
            price: '0.00',
            description: 'Description placeholder',
        },
    ]);

    useEffect(() => {
        if (socket) {
            socket.on('reponsePaypalPayement', updateComponent);
        }

    }, [socket]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                //  setShoutcastExpiration(pricingResult.shoutcast_date_expiration)
                const serviceExpiration = await getServiceExpiration(user?.token);
                setShoutcastExpiration(serviceExpiration.data.shoutcast_date_expiration)
                setTchatExpiration(serviceExpiration.data.tchat_date_expiration)
                const pricingResult = await service_shoutcast(user?.token);
                const tchatResult = await service_tchat(user?.token);
                setPricingData(pricingResult.data);
                setTchatData(tchatResult.data);
            
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, [user?.token]);

    const updateComponent = async () => {
        try {
            const serviceExpiration = await getServiceExpiration(user?.token);
            setShoutcastExpiration(serviceExpiration.data.shoutcast_date_expiration)
            setTchatExpiration(serviceExpiration.data.tchat_date_expiration)
            
            // Réinitialiser les champs de sélection
            resetSelectionFields();
        } catch (error) {
            console.error('Error updating component:', error);
        }
    }
    
    const resetSelectionFields = () => {
        const newDataSource = dataSource.map(item => ({
            ...item,
            sku: '',
            price: '0.00',
            description: 'Description placeholder',
        }));
        setDataSource(newDataSource);
    };

    const handleTermChange = (value, record) => {
        const newDataSource = dataSource.map(item => {
            if (item.key === record.key) {
                if (value === 0) {
                    return {
                        ...item,
                        sku: '',
                        price: '0.00',
                        description: 'Description placeholder',
                    };
                }
                const selectedItem = record.service === 'shoutcast'
                    ? pricingData.find(term => term.id === value)
                    : tchatData.find(term => term.id === value);
                return {
                    ...item,
                    sku: selectedItem.sku,
                    price: selectedItem.price,
                    description: selectedItem.description,
                };
            }
            return item;
        });
        setDataSource(newDataSource);
    };

    const calculateTotalPrice = () => {
        return dataSource.reduce((total, item) => total + parseFloat(item.price), 0).toFixed(2);
    };

    const handlePayment = async () => {
        const shoutcastPlan = dataSource.find(item => item.service === 'shoutcast').sku;
        const tchatPlan = dataSource.find(item => item.service === 'tchat').sku;

        if (!shoutcastPlan && !tchatPlan) {
            console.error('Both shoutcastPlan and tchatPlan are missing.');
            return;
        }

        try {
            const response = await pay(
                shoutcastPlan,
                tchatPlan,
                user?.token
            );
            const { approval_url } = response;
            window.open(approval_url, '_blank');
        } catch (error) {
            console.error('Payment initiation error:', error);
        }
    };

    const handleReset = (record) => {
        const newDataSource = dataSource.map(item => {
            if (item.key === record.key) {
                return {
                    ...item,
                    sku: '',
                    price: '0.00',
                    description: 'Description placeholder',
                };
            }
            return item;
        });
        setDataSource(newDataSource);
    };

    const columns = [
        {
            title: 'Service',
            dataIndex: 'service',
            key: 'service',
        },
        {
            title: 'sku',
            dataIndex: 'sku',
            key: 'sku',
            render: (text, record) => (
                <div>
                    <Select
                        value={record.sku || undefined}
                        onChange={(value) => handleTermChange(value, record)}
                        style={{ width: 120, marginRight: '10px' }}
                    >
                        <Option key="1" value={0}>None</Option>
                        {(record.service === 'tchat' ? pricingData : tchatData)
                            .map(item => (
                                <Option key={item.id} value={item.id}>
                                    {item.description}
                                </Option>
                            ))
                        }
                    </Select>
                    <Button onClick={() => handleReset(record)}>Reset</Button>
                </div>
            ),
        },
        {
            title: 'Prix',
            dataIndex: 'price',
            key: 'price',
            render: (text, record) => (
                <span style={{ color: record.service === 'total' ? 'red' : 'inherit', fontWeight: record.service === 'total' ? 'bold' : 'normal' }}>
                    ${parseFloat(text).toFixed(2)}
                </span>
            ),
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        }
    ];

    return (
        <>
            <Row>
                <Col span={24}>
                    expiration du tchat : {tchatExpiration}
                </Col>
           
                <Col span={24}>
                    expiration du shoutcast : {shoutcastExpiration}
                </Col>
            </Row>
            <Row>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <Title level={2}>Faire un paiement</Title>
                    <Table dataSource={dataSource} columns={columns} pagination={false} />
                </Col>
            </Row>
            <Row>
                <Col span={24} style={{ textAlign: 'right', marginTop: '20px' }}>
                    <Title level={4}>Total: ${calculateTotalPrice()}</Title>
                </Col>
            </Row>
            <Row>
                <Col span={24} style={{ textAlign: 'center', marginTop: '20px' }}>
                    <Button type="primary" onClick={handlePayment}>Pay with PayPal</Button>
                </Col>
            </Row>
        </>
    );
});

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(Pay);
