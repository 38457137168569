
import { axios } from '../../../endpoints/axios_custom'

export const getIp = () => {
    return axios.get(`/getIp`, {})
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête d'envoi de l'Avatar :", error);
            throw error;
        });
}

export const sysop_auth = async (data) => {
    return axios.post(`/sysop_auth`, data)
        .then(({ data }) => data)
        .catch((error) => {
            if (error.response && error.response.status === 429) {
                alert( error.response.data);
            } else {
                console.error("Une erreur s'est produite lors de la requête d'authentification :", error);
            }
            throw error;
        });
}



