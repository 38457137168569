import { Space, Layout, Row, Col, Form, Input, Button, Typography } from 'antd';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp } from '../../action/action';
//import { getIp } from '../sysop/enpoints/login';
import { sysop_auth } from '../sysop/enpoints/login'

const { Header, Footer, Content } = Layout;
const actualPage = 'admin page';

const { Title } = Typography;

const headerStyle = {
    textAlign: 'center',
    color: '#fff',
    height: 64,
    paddingInline: 10,
    lineHeight: '64px',
    backgroundColor: '#7dbcea',
};

const contentStyle = {
    textAlign: 'center',
    minHeight: '76vh',
    lineHeight: '120px',
    color: '#fff',
    backgroundColor: '#108ee9',
};

const footerStyle = {
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#7dbcea',
};

const Login = ({ app, testStore, designPage, user, ...props }) => {
    const success = (messagev) => {
        //gerer le success
    };

    useEffect(() => { 
    }, [])

    const onFinish = async (values) => {
        sysop_auth(values).then(resp => {
            if (resp.token) {
                // success(resp.detail)
                //setLoading(false);
                console.log("Connexion réussie !.");
                    props?.dispatch(setUserApp({ InitSysopState: 'main-sysop', username: 'sysop', token: resp.token }))
            }
            else {
                //  warning(resp.detail)
                // setLoading(false);
            }
        })
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Échec de la soumission du formulaire:", errorInfo);
    };

    const setRegister = () => {
        //   props?.dispatch(setUserApp({ InitUserState: 'register' }))
    }

    const setRecover = () => {
        //  props?.dispatch(setUserApp({ InitUserState: 'recover' }))
    }

    return (
        <>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: 'center',
                    backgroundColor: 'rgb(23, 23, 23)',
                    height: '100vh',
                    flexDirection: 'column'
                }}
            >
                <div >
                    <Title style={{ color: 'white' }}>Welcome Sysop</Title>
                </div>
                <div style={{ margin: '20px', padding: '50px', border: ' 0.5px solid', borderRadius: '16px', backgroundColor: 'rgb(0, 0, 10)' }}>
                    <div >
                        <Form
                            name="loginForm"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            labelCol={{ span: 12 }}
                        >
                            <Form.Item
                                label={<span style={{ color: 'white' }}>Operator Name</span>}
                                labelCol={{ span: 12 }}
                                labelAlign="left"
                                name="username"
                                rules={[
                                    { required: true, message: "Veuillez saisir votre nom d'utilisateur!" }
                                ]}
                            >
                                <Input autoComplete="current-password" />
                            </Form.Item>

                            <Form.Item
                                labelAlign="left"
                                label={<span style={{ color: 'white' }}>Password</span>}
                                labelCol={{ span: 12 }}
                                name="password"
                                rules={[
                                    { required: true, message: "Veuillez saisir votre mot de passe!" }
                                ]}
                            >
                                <Input.Password autoComplete="current-password" />
                            </Form.Item>

                            <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
                                <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                                    Login
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
                <div>restricted area</div>
            </div>
        </>
    );
};

const mapStateToProps = ({ user, message }) => ({ user, message });
export default connect(mapStateToProps)(Login);
