import React, { useEffect } from 'react';
import { connect } from 'react-redux';

const MessageFromB = ({ messageFromBot, handleMessage, user }) => {
    useEffect(() => {

        if (Array.isArray(messageFromBot) && messageFromBot.length > 0) {
            messageFromBot.forEach((messagePackage) => {
                runMessageBot(messagePackage);
            });
        }

        return clearTimers;

    }, [messageFromBot]);


    const runMessageBot = (messagePackage) => {
        //set interval en Milliseconde
        messagePackage.message_interval = messagePackage.message_interval * 1000;
        const MessageId = crypto.randomUUID(); // ✅ ID généré à chaque nouvel appel
        let currentDate = new Date();
        let formattedDate = currentDate.toISOString();
        let state;
        let message =
        {
           
            time: formattedDate,
            message: messagePackage.message,
            from: messagePackage.name,
            colorLeft: messagePackage.colorLeft,
            colorRight: messagePackage.colorRight,
            for: null,
            type: "bot",
            room: user?.room,
            url: messagePackage.url ? messagePackage.url : null,
            bot: true,
            roomId: messagePackage.roomIndex, //--------destination tab
            typeTab: "room",
            avatar: "uploads/63734f4f44707077eb0a2b231e208cbb.jpg",
            formatMessage: {
                "bold": false,
                "italic": false,
                "underline": true,
                "colorText": "#1677ff"
            },
            roleColor: "#d5352a",
            messageId:MessageId,
            socketId: null
        }

        if (messagePackage.message_interval >= 1 && messagePackage.message_active !== '0' && handleMessage) {
            messagePackage.timerId = setInterval(() => {
                handleMessage(message);
            }, messagePackage.message_interval);
        } else {
            //   console.log(messagePackage.message);
        }
    };

    const clearTimers = () => {
        if (messageFromBot) {
            messageFromBot.forEach((message) => {
                clearInterval(message.timerId);
            });
        }
    };

    return null;
};

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps)(MessageFromB);
