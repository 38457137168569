import { Form, Input, Select, Button, Row, Col, Collapse, Divider } from 'antd';
import { SketchPicker } from 'react-color';
import { useState, useEffect, forwardRef } from 'react';
import Player from '../../elements/player/Player';
import { connect } from 'react-redux';
import { EventEmitter } from '../../../utility/Emitter'

import { adminGetPlayerConfig, adminSendPlayerConfig } from '../../../../endpoints/admin';

const { Option } = Select;
const { Panel } = Collapse;

const ModifyPlayer = forwardRef(({ user, ...props }, ref) => {
    const [selectedFont, setSelectedFont] = useState('');
    const [form] = Form.useForm();
    const [data, setData] = useState(null);
    const [reload, setReload] = useState(false);


    const [couleurs, setCouleurs] = useState({
        bordercolor: '#ffffff',
        fontcolor: '#ffffff',
        highlightcolor: '#5d00eb',
        backgroundcolor: '#03ffb8'
    });


    const fontOptions = {
        "Sixtyfour Convergence": {
            googlefont: "Sixtyfour+Convergence",
            url: "Sixtyfour+Convergence:400"
        },
        "Emblema One": {
            googlefont: "Emblema One",
            url: "Emblema+One:400"
        },
        "Kalnia Glaze": {
            googlefont: "Kalnia Glaze",
            url: "Kalnia+Glaze:400"
        },
        "Honk": {
            googlefont: "Honk",
            url: "Honk:400"
        },
        "Ruge Boogie": {
            googlefont: "Ruge+Boogie",
            url: "Ruge+Boogie:400"
        },

        "Ruge Frijole": {
            googlefont: "Frijole",
            url: "Frijole:400"
        },
        "Bebas Neue": {
            googlefont: "Bebas+Neue",
            url: "Bebas+Neue:400"
        },
        "Dancing Script": {
            googlefont: "Dancing+Script",
            url: "Dancing+Script:400"
        },
        "Spicy Rice": {
            googlefont: "Spicy+Rice",
            url: "Spicy+Rice:400"
        },
        "Source Code Pro": {
            googlefont: "Source+Code+Pro",
            url: "Source+Code+Pro:400"
        },
        "Pacifico": {
            googlefont: "Pacifico",
            url: "Pacifico:400"
        },
        "Lobster": {
            googlefont: "Lobster",
            url: "Lobster:400"
        },
        "Shadows Into Light": {
            googlefont: "Shadows+Into+Light",
            url: "Shadows+Into+Light:400"
        },
        "Chakra Petch": {
            googlefont: "Chakra+Petch",
            url: "Chakra+Petch:400"
        },
        "Micro 5": {
            googlefont: "Micro+5",
            url: "Micro+5:400"
        },
        "Indie Flower": {
            googlefont: "Indie+Flower",
            url: "Indie+Flower:400"
        },
        "Cinzel": {
            googlefont: "Cinzel",
            url: "Cinzel:400"
        },
        "Orbitron": {
            googlefont: "Orbitron",
            url: "Orbitron:400"
        },
        "Doto": {
            googlefont: "Doto",
            url: "Doto:400"
        },
        "Great Vibes": {
            googlefont: "Great+Vibes",
            url: "Great+Vibes:400"
        },
        "Rubik Mono One": {
            googlefont: "Rubik+Mono+One",
            url: "Rubik+Mono+One:400"
        },
        "Bangers": {
            googlefont: "Bangers",
            url: "Bangers:400"
        },
        "Neucha": {
            googlefont: "Neucha",
            url: "Neucha:400"
        }
    };

    const [affichePicker, setAffichePicker] = useState({});

    const basculerPicker = (cle) => {
        setAffichePicker((prev) => ({ ...prev, [cle]: !prev[cle] }));
    };

    const changerCouleur = (cle, couleur) => {
        const nouvelleCouleur = couleur.hex;
        setCouleurs((prev) => ({ ...prev, [cle]: nouvelleCouleur }));
        form.setFieldsValue({ [cle]: nouvelleCouleur });
    };

    useEffect(() => {
        const masquerPickers = () => setAffichePicker({});
        document.addEventListener('mouseup', masquerPickers);
        return () => document.removeEventListener('mouseup', masquerPickers);
    }, []);

    useEffect(() => {
        // Création de l'élément <link>
        const link = document.createElement("link");
        link.rel = "stylesheet";
        link.href = "https://fonts.googleapis.com/css2?family=Sixtyfour+Convergence&display=swap"; // URL de ton CSS

        // Ajouter l'élément dans le <head>
        document.head.appendChild(link);

        // Nettoyage lors du démontage du composant
        return () => {
            document.head.removeChild(link);
        };
    }, []); // [] pour que le hook s'exécute une seule fois (comme componentDidMount)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const resp = await adminGetPlayerConfig(user?.token, user?.room);
                if (resp) {
                    setData(resp.data);

                    setCouleurs({
                        color1: resp.data.color1,
                        color2: resp.data.color2,
                        color3: resp.data.color3,
                        color4: resp.data.color4,
                        bordercolor: resp.data.bordercolor || '#ffffff',
                        fontcolor: resp.data.fontcolor || '#ffffff',
                        highlightcolor: resp.data.highlightcolor || '#5d00eb',
                        backgroundcolor: resp.data.backgroundcolor || '#03ffb8'
                    });

                    form.setFieldsValue({
                        ...resp,
                        radioname: resp.data.radioname,
                        bordercolor: resp.data.bordercolor || '#ffffff',
                        fontcolor: resp.data.fontcolor || '#ffffff',
                        highlightcolor: resp.data.highlightcolor || '#5d00eb',
                        backgroundcolor: resp.data.backgroundcolor || '#03ffb8',
                        color1: resp.data.color1,
                        color2: resp.data.color2,
                        color3: resp.data.color3,
                        color4: resp.data.color4,
                        visualizerOpacity: resp.data.visualizerOpacity,
                        visualizerGhostBlur: resp.data.visualizerGhostBlur,
                        visualizerBlur: resp.data.visualizerBlur,
                        visualizerGhost: resp.data.visualizerGhost,
                        volume: resp.data.volume,
                        autoplay: resp.data.autoplay ? 'oui' : 'non',
                        multicolorvisualizer: resp.data.multicolorvisualizer ? 'oui' : 'non',
                    });
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des données :', error);
            }
        };
        fetchData();
    }, [user?.token, user?.room]);

    const handleSubmit = async (valeurs) => {
        const toutesLesValeurs = { ...valeurs, ...couleurs };
        console.log("Toutes les valeurs du formulaire :", toutesLesValeurs);
        await adminSendPlayerConfig(user?.token, user?.room, toutesLesValeurs);
        // Force le player dans ce composant a recharger
        //setReload(prev => !prev);
       // EventEmitter().emit('togglePlayer', {})

         if (props.socket) {
            props.socket.emit("reloadPlayer",user?.room);
          }
    };


    return (
        <>
            <Player key={reload} style={{ height: '80px' }} />

            <Form
                layout="vertical"
                className="formulaire-parametres-radio"
                form={form}
                onFinish={handleSubmit}
            >
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="Paramètres de base" key="1" forceRender>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Nom de la radio" name="radioname">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Panel>

                    <Panel header="Paramètres du thème" key="3" forceRender>
                        <Row gutter={16}>
                            {[
                                { value: 'fontcolor', name: 'Couleur du texte' },
                                { value: 'highlightcolor', name: 'Couleur de surbrillance' },
                                { value: 'backgroundcolor', name: 'Couleur de fond' },
                                { value: 'color1', name: 'Couleur de l’onde 1' },
                                { value: 'color2', name: 'Couleur de l’onde 2' },
                                { value: 'color3', name: 'Couleur de l’onde 3' },
                                { value: 'color4', name: 'Couleur de l’onde 4' }
                            ].map(({ value, name }) => (
                                <Col span={6} key={value}>
                                    <Form.Item label={name} name={value}>
                                        <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                                            <Button onClick={() => basculerPicker(value)}>Choisir la couleur</Button>
                                            <div
                                                style={{
                                                    width: 40,
                                                    height: 40,
                                                    backgroundColor: couleurs[value] || '#ffffff', // Gestion de la couleur par défaut
                                                    border: '2px solid #000',
                                                    marginLeft: 10
                                                }}
                                            />
                                            {affichePicker[value] && (
                                                <div style={{ position: 'absolute', top: '100%', left: 0, zIndex: 10 }}>
                                                    <SketchPicker
                                                        color={couleurs[value]}
                                                        onChange={(couleur) => changerCouleur(value, couleur)}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </Form.Item>
                                </Col>
                            ))}
                            <Col>
                                <Form.Item label="Police Google Font" name="googlefont">
                                    <Select
                                        onChange={(value) => {
                                            form.setFieldsValue({
                                                googlefont: fontOptions[value].googlefont,
                                                url: fontOptions[value].url
                                            });
                                        }}
                                        placeholder="Sélectionnez une police"
                                    >
                                        {Object.keys(fontOptions).map((font) => (
                                            <Option key={font} value={font}>{font}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider />
                        <Col span={12}>
                            <Form.Item label="multicolorvisualizer" name="multicolorvisualizer">
                                <Select defaultValue="false">
                                    <Option value="true">Oui</Option>
                                    <Option value="false">Non</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Row gutter={16}>
                            <Col span={6}>
                                <Form.Item label="Opacité du visualiseur" name="visualizerOpacity">
                                    <Select defaultValue="1.0">
                                        {Array.from({ length: 11 }, (_, i) => (
                                            <Option key={i} value={(1 - i / 10).toFixed(1)}>
                                                {(1 - i / 10).toFixed(1)}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item label="Flou du visualiseur" name="visualizerBlur">
                                    <Select defaultValue="0">
                                        {Array.from({ length: 11 }, (_, i) => (
                                            <Option key={i} value={i}>{i}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item label="Effet fantôme" name="visualizerGhost">
                                    <Select defaultValue="1.0">
                                        {Array.from({ length: 11 }, (_, i) => (
                                            <Option key={i} value={(1 - i / 10).toFixed(1)}>
                                                {(1 - i / 10).toFixed(1)}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item label="Effet flou fantôme" name="visualizerGhostBlur">
                                    <Select defaultValue="0">
                                        {Array.from({ length: 11 }, (_, i) => (
                                            <Option key={i} value={i}>{i}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Panel>

                    <Panel header="Paramètres avancés" key="4" forceRender>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Volume" name="volume">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Lecture automatique" name="autoplay">
                                    <Select defaultValue="false">
                                        <Option value="true">Oui</Option>
                                        <Option value="false">Non</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Panel>
                </Collapse>

                <Form.Item>
                    <Button type="primary" htmlType="submit">Soumettre</Button>
                </Form.Item>
            </Form>
        </>
    );
});

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ModifyPlayer);
