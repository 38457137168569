import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { Row, Col, Modal } from 'antd';
import { connect } from 'react-redux';
import { SocketContext } from '../../../components/tchat/SocketContext';
import { Device } from 'mediasoup-client';

const ModalWebcam = ({ ...props }) => {
    const socket = useContext(SocketContext);

    useEffect(() => {
        if (props.modalTrack) {
            const modalVideoElement = props.videoRefs.current[`${props.userDataFromBack.id}-modal`];
            if (modalVideoElement && props.modalTrack instanceof MediaStreamTrack) {
                const mediaStream = new MediaStream([props.modalTrack]);
                console.log(`Assigning stream to modal video element with id: ${props.userDataFromBack.id}-modal`);
                modalVideoElement.srcObject = mediaStream;
                modalVideoElement.onloadedmetadata = () => {
                    modalVideoElement.play().catch(error => {
                        console.error(`Error playing modal video with id: ${props.userDataFromBack.id}-modal`, error);
                    });
                };
            }
        }
    }, [props.modalTrack, props.userDataFromBack.id]);

    return (
        <>
            <Modal
                title={<p>{props.userDataFromBack.username}</p>}
                open={props.isModalOpen}
                onOk={props.handleOk}
                onCancel={props.handleCancel}
                footer={null}
            >
                <video
                    id={`${props.userDataFromBack.id}-modal`}
                    autoPlay
                    playsInline
                    muted={props.userDataFromBack.isLocal}
                    ref={(videoElement) => {
                        props.videoRefs.current[`${props.userDataFromBack.id}-modal`] = videoElement;
                    }}
                    style={{ width: '100%', height: '100%', backgroundColor: 'black' }}
                ></video>
            </Modal>
        </>
    );
};

const mapStateToProps = ({ user, message }) => ({ user, message });
export default connect(mapStateToProps)(ModalWebcam);
