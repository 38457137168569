import { useState, useEffect, forwardRef } from 'react';
import { connect } from 'react-redux';
import { Modal, Table, Popconfirm, message, Button, Typography } from 'antd';
import { getMessage, APIdeleteMessage, APIdeleteAllMessage, APIreportMessage } from '../../../../endpoints/notification';
import ModalReadMessage from './ModalReadMessage';

const { Text, Title } = Typography;

const ModalShowMessage = forwardRef(({ user, ...props }, ref) => {
    const [data, setData] = useState([]);
    const [modalReadMessage, setModalReadMessage] = useState(false);
    const [messageToRead, setMessageToRead] = useState();

    const handleOk = () => props.setModalShowMessage(false);
    const handleCancel = () => props.setModalShowMessage(false);

    const deleteNotification = async (id) => {
        setData((prevData) => {
            const updatedData = prevData.filter(item => item.id !== id);
            props.setTotalMessage(updatedData.length); // ✅ Utiliser la donnée mise à jour
            return updatedData;
        });
    
        try {
            await APIdeleteMessage({ token: user?.token, deleteId: id });
            message.success('Notification supprimée avec succès !');
        } catch (error) {
            console.error('Erreur lors de la suppression de la notification :', error);
            message.error('Erreur lors de la suppression de la notification.');
        }
    };
    
    const deleteAllMessage = async () => {
        if (data.length === 0) {
            message.warning("Il n'y a aucune notification à supprimer.");
            return;
        }

        try {
            await APIdeleteAllMessage({ token: user?.token, userId: user?.iDdb });
            props.setTotalMessage(0)
            setData([]);
            message.success('Toutes les notifications ont été supprimées avec succès !');
        } catch (error) {
            console.error('Erreur lors de la suppression des notifications :', error);
            message.error('Erreur lors de la suppression des notifications.');
        }
    };

    const reportMessage = async (record) => {
        try {
            await APIreportMessage({
                token: user?.token,
                messageId: record.id,
                reason: 'Contenu inapproprié ou abusif',
                reportedBy: user?.username
            });
            message.success('Message signalé avec succès à l\'administrateur.');
        } catch (error) {
            console.error('Erreur lors du signalement du message :', error);
            message.error('Erreur lors du signalement du message.');
        }
    };

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const data = await getMessage({ usertoken: user?.token, user: user?.room });
                const parsedData = data.map(item => {
                    let parsedMessage = [];

                    try {
                        const messageObject = JSON.parse(item.message);  // ✅ Conversion en objet
                        parsedMessage = messageObject.message || [];     // ✅ Extraction du tableau `messages`
                    } catch (error) {
                        console.error(`Erreur lors du parsing du message pour l'ID ${item.id} :`, error);
                    }

                    return {
                        ...item,
                        messages: parsedMessage  // ✅ Stocke les messages correctement formatés
                    };
                });
                setData(parsedData);

            } catch (error) {
                console.error('Erreur lors de la récupération des notifications :', error);
            }
        };

        if (props.modalShowMessage) {
            fetchNotifications();
        }
    }, [props.modalShowMessage]);

    const readMessage = (message) => {
        let formattedMessage = '';

        try {
            const parsedMessage = JSON.parse(message.message);

            if (parsedMessage.message && Array.isArray(parsedMessage.message)) {
                formattedMessage = parsedMessage.message.map(msg =>
                    `🗨️ ${msg.content} - (${msg.date} à ${msg.time})\n`
                ).join('\n');

                formattedMessage += `\n📩 De : ${parsedMessage.fromUsername} ➡️ À : ${parsedMessage.toUsername}`;
            } else {
                formattedMessage = 'Aucun message disponible.';
            }

        } catch (error) {
            console.error('Erreur lors du parsing du message :', error);
            formattedMessage = 'Erreur lors du formatage du message.';
        }

        setMessageToRead({ ...message, formattedMessage });
        setModalReadMessage(true);
    };

    const columns = [
        {
            title: 'De 🎁',
            dataIndex: 'from_user_id',
            key: 'from_user_id',
        },
        {
            title: 'Messages',
            dataIndex: 'messages',
            key: 'messages',
            render: (messages) => (
                messages.map((msg, index) => (
                    <div key={index}>
                       { msg.edited_from == user?.username ?
                            <Text>{msg.edited_from} envoyer le : {msg.date} à {msg.time}</Text>
                       :
                            <Text>de :{msg.edited_from} recus le : {msg.date} à {msg.time}</Text>
                        }
                    </div>
                ))
            ),
            width: 400,
        },
        {
            title: 'Action',
            width: 400,
            dataIndex: '',
            key: 'x',
            render: (_, record) => (
                <>
                    <Button
                        style={{
                            backgroundColor: 'blue',
                            color: 'white',
                            border: 'none',
                            marginRight: '10px'
                        }}
                        onClick={() => readMessage(record)}
                    >
                        Lire le message
                    </Button>

                    <Popconfirm
                        title="Êtes-vous sûr de vouloir supprimer cette notification ?"
                        onConfirm={() => deleteNotification(record.id)}
                        okText="Oui"
                        cancelText="Non"
                    >
                        <Button
                            style={{
                                backgroundColor: 'red',
                                color: 'white',
                                border: 'none',
                                marginRight: '10px'
                            }}
                        >
                            Supprimer
                        </Button>
                    </Popconfirm>

                    <Popconfirm
                        title="Voulez-vous signaler ce message à l'administrateur ?"
                        onConfirm={() => reportMessage(record)}
                        okText="Oui"
                        cancelText="Non"
                    >
                        <Button
                            style={{
                                backgroundColor: 'orange',
                                color: 'white',
                                border: 'none'
                            }}
                        >
                            Dénoncer
                        </Button>
                    </Popconfirm>
                </>
            ),
        }
    ];

    return (
        <>
            <Modal
                width={900}
                title="Vos messages"
                open={props.modalShowMessage}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Popconfirm
                        title="Voulez-vous vraiment tout supprimer ?"
                        onConfirm={deleteAllMessage}
                        okText="Oui"
                        cancelText="Non"
                    >
                        <Button
                            danger
                            style={{
                                backgroundColor: 'red',
                                color: 'white',
                                border: 'none'
                            }}
                        >
                            Effacer tout
                        </Button>
                    </Popconfirm>,
                    <Button type="primary" onClick={handleOk}>Fermer</Button>
                ]}
            >
                <div style={{ maxWidth: '800px', overflow: 'auto' }}>
                    <Table dataSource={data} columns={columns} />
                </div>
            </Modal>

            <ModalReadMessage
                message={messageToRead}
                modalReadMessage={modalReadMessage}
                setModalReadMessage={setModalReadMessage}
            />
        </>
    );
});

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ModalShowMessage);
