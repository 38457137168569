import React, { useState, useEffect, useRef, Fragment, useContext, forwardRef } from 'react';
import { Button, Col, Row, Space, Modal } from 'antd';
import { MenuUnfoldOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp } from '../../../action/action';
import { SocketContext } from '../../../components/tchat/SocketContext';

import ListeUser from './components/ListeUser';
import ListeRoom from './components/ListeRoom';
import ListeRole from './components/ListeRole';
import Design from './components/Design';
import ModifyUser from './components/ModifyUser';
import AddUser from './components/AddUser';
import AddRole from './components/AddRole';
import AddRoom from './components/AddRoom';
import Punch from './components/Punch';
import ModifyRoom from './components/ModifyRoom';
import ModifyRole from './components/ModifyRole';
import MenuLayout from '../adminMenu/components/layout/Menu';
import useRefs from '../ref/useRefs';
import AddPunch from './components/AddPunch';
import DeleteImagePunch from './components/DeleteImagePunch';
import ListeShoutcast from './components/ListeShoutcast';
import AddShoutcast from './components/AddShoutcast';
import ListeBot from './components/ListeBot';
import ModifyBot from './components/ModifyBot';
import AddBot from './components/AddBot';
import General from './components/General';
import Pay from './components/Pay'
import ModifyPlayer from './components/ModifyPlayer';
const MainAdminModal = forwardRef(({ user, ...props }, ref) => {

    const [currentComponentObj, setCurrentComponentObj] = useState({ name: 'user' });
    const [forceKeyChange, setForceKeyChange] = useState();


    const [visible, SetVisible] = useState(true)
    const refs = useRefs();
    const socket = useContext(SocketContext);

    useEffect(() => {

        setTimeout(() => {
            //ouvre le panneau d'admin 
            //   handleOpenAdminMenu('ListeBot') 
        }, 5000);

        const handleOpenAdminMenu = (panelName) => {
            props.setAdminPanelVisible(true)
            setCurrentComponentObj({ name: panelName })
            setForceKeyChange(panelName)
        };

        if (socket) {
            socket.on("openAdminMenu", handleOpenAdminMenu);

            // Définir la fonction de nettoyage pour supprimer l'écouteur d'événements
            return () => {
                socket.off("openAdminMenu", handleOpenAdminMenu);
            };
        }
    }, [socket]);


    const renderCurrentComponent = () => {
        switch (currentComponentObj.name) {
            case 'general':
                return <General socket={socket} setCurrentComponentObj={setCurrentComponentObj} />;

            case 'user':
                return <ListeUser setCurrentComponentObj={setCurrentComponentObj} />;

            case 'editUser':
                return <ModifyUser ref={refs} setCurrentComponentObj={setCurrentComponentObj} currentComponentObj={currentComponentObj} />;

            case 'addUser':
                return <AddUser ref={refs} />;

            case 'addRoom':
                return <AddRoom ref={refs} />;

            case 'Room':
                return <ListeRoom setCurrentComponentObj={setCurrentComponentObj} />;

            case 'editRoom':
                return <ModifyRoom ref={refs} setCurrentComponentObj={setCurrentComponentObj} currentComponentObj={currentComponentObj} />;

            case 'editRole':
                return <ModifyRole ref={refs} setCurrentComponentObj={setCurrentComponentObj} currentComponentObj={currentComponentObj} />;

            case 'addRole':
                return <AddRole ref={refs} setCurrentComponentObj={setCurrentComponentObj} currentComponentObj={currentComponentObj} />;

            case 'Role':
                return <ListeRole ref={ref} setCurrentComponentObj={setCurrentComponentObj} currentComponentObj={currentComponentObj} />;

            case 'Design':
                return <Design ref={ref} setCurrentComponentObj={setCurrentComponentObj} />;

            case 'Punch':
                return <Punch ref={ref} setCurrentComponentObj={setCurrentComponentObj} />;

            case 'ModifyPlayer':
                return <ModifyPlayer socket={socket} ref={ref} setCurrentComponentObj={setCurrentComponentObj} />;

            case 'addPunch':
                return <AddPunch ref={ref} setCurrentComponentObj={setCurrentComponentObj} />;

            case 'addBot':
                return <AddBot ref={ref} setCurrentComponentObj={setCurrentComponentObj} />;

            case 'DeletePunch':
                return <DeleteImagePunch ref={ref} setCurrentComponentObj={setCurrentComponentObj} />;

            case 'Shoutcast':
                return <ListeShoutcast ref={ref} setCurrentComponentObj={setCurrentComponentObj} />;

            case 'addShoutcast':
                return <AddShoutcast ref={ref} setCurrentComponentObj={setCurrentComponentObj} />;

            case 'ListeBot':
                return <ListeBot ref={ref} setCurrentComponentObj={setCurrentComponentObj} />;

            case 'editBot':
                return <ModifyBot ref={ref} setCurrentComponentObj={setCurrentComponentObj} currentComponentObj={currentComponentObj} />;

            case 'pay':
                return <Pay ref={ref} setCurrentComponentObj={setCurrentComponentObj} currentComponentObj={currentComponentObj} />;
            default:
                return null;
        }
    };

    const onClose = () => {
        props.setAdminPanelVisible(false)
    }

    return (
        <>
            <Modal
                styles={{ body: { marginTop: 0 } }}
                width={0.7 * window.innerWidth}
                maxHeight={200} 
                open={props.adminPanelVisible}
                onCancel={onClose}
                footer={null}
                destroyOnClose={true}
            >
                <MenuLayout ref={ref} setCurrentComponentObj={setCurrentComponentObj} forceKeyChange={forceKeyChange} />
                <div style={{ overflowY: 'auto' }}>
                    {renderCurrentComponent()}
                </div>
            </Modal>
        </>
    );
})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(MainAdminModal);
