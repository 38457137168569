import { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Row, Image } from "antd";
import InputEmoji from 'react-input-emoji'
import { VideoCameraOutlined, StepForwardFilled , AudioOutlined } from '@ant-design/icons';
import { EventEmitter } from '../../utility/Emitter'
import '../../../sass/components/chat.css'; // Ajoutez cette ligne pour importer le fichier CSS
const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

const PrivateHtmlStructure = ({ app, testStore, designPage, user, ...props }) => {
    const [text, setText] = useState('')
    let mywebcam = useRef(false)
    const buttonRefEmoji = useRef(null);
    const colorr = 'red'
    const [userAllowMeAccessIsMedia , setUserAllowMeAccessIsMedia] = useState(false)

    function handleOnEnter(text) {

        const regex = /(<([^>]+)>)/ig;
        const clean = text.replace(regex, '');
        var date = new Date().toLocaleString("en-US", { timeZone: "America/Montreal" });
        var dateObj = new Date(date);
        const isoDate = dateObj.toISOString();
        let room
        user?.typeTab === 'user' ? room = user?.selectedRoom : room = Number(user?.selectedRoom)

        let messagePackage = {
            status: user?.status,
            time: isoDate,
            message: clean,
            from: user?.username,
            for: props.targetPrivate.socketId,
            type: 'text',
            room: user?.room,
            roomId: room,
            typeTab: 'user',
            avatar: user?.avatar,
            formatMessage: null,
            roleColor: user?.roleColor
        }
        text.trim().length >= 1 && props.socket.emit("privateMessage", messagePackage);
    }

    const handlerCam = () => {
        EventEmitter().emit('EnableLocalVideo', 
            props.targetPrivate,
        )

        const constraints = {
            video: true,
            audio: false
        };

        if (!mywebcam.current) {
            props.startLocalWebcam(`myWebcamWith${props.targetPrivate.username}`, constraints)
        }
        mywebcam.current = !mywebcam.current

    }

    const emitterMessage = EventEmitter().subscriber(`privateAllowUserIdAccessMedia${props.targetPrivate.username}`, data => {
        setUserAllowMeAccessIsMedia(data.allowMedia)
    })

    const handleButtonClick = () => {
        // Utilisez buttonRef.current pour accéder au bouton
        /*  if (buttonRefEmoji.current) {
            // Déclenchez le clic sur le bouton
            buttonRefEmoji.current.click();
          }
          */
    };
    

    return (
        <>
            <Row id='chat' style={{ height: '95%', margin: '5px' }}>
                <Col span={16} style={{ height: '90%', padding: '3px' }}>
                    <Row style={{ height: '84%' }}>
                        <Col span={24} style={{ padding: '5px', height: '100%', borderRadius: '5px', border: '1px solid grey' }}>
                            <Row style={{ height: '100%' }}>
                                <Col span={24} style={{ overflowY: 'auto', padding: '5px', backgroundColor: 'white', height: '100%' }}>
                                    {
                                        props.convo.map((obj, key) => ((
                                            <div key={key} >{obj.from}: {obj.message} </div>
                                        ))
                                        )
                                    }

                                </Col>
                                <div style={{ color: 'red', left: '25%', position: 'absolute', bottom: -15 }}>
                                    {props.isTargetUserLeft ? (
                                        <p>L'utilisateur a quitté la conversation.</p>
                                    ) : (
                                        <p>Conversation active.</p>
                                    )}
                                </div>
                                <div style={{ color: 'red', left: '25%', position: 'absolute', bottom: -5 }}>
                                    {userAllowMeAccessIsMedia ? (
                                        <p>L'utilisateur vous a autoriser sa webcam.</p>
                                    ) : (
                                        <p>aucune autorisation webcam .</p>
                                    )}
                                </div>
                                
                            </Row>
                        </Col>
                    </Row>
                    {/* Fin du div */}
                    <Row>
                        <Col span={24} style={{ height: '25px', background: 'white', margin: '5px' }}>
                            {/*}
                            <Button size={'small'}><BoldOutlined style={{ fontSize: '20px' }} /></Button>
                            <Button size={'small'}><ItalicOutlined style={{ fontSize: '20px' }} /></Button>
                            <Button size={'small'}><UnderlineOutlined style={{ fontSize: '20px' }} /></Button>
                            <Button size={'small'}><FileGifOutlined style={{ fontSize: '20px' }} /></Button>
                            <Button size={'small'}> <BgColorsOutlined style={{ fontSize: '20px' }} /> </Button>
                            {*/}
                            <Button size={'small'}> <AudioOutlined style={{ fontSize: '20px' , color:'red' }} /> </Button>
                            <Button size={'small'}> <VideoCameraOutlined onClick={handlerCam} style={{ fontSize: '20px' }} /> </Button>
                            <Button size={'small'} style={{}} onClick={handleButtonClick}  ><span ref={buttonRefEmoji}>😊</span></Button>
                        </Col>
                    </Row>
                    <Row >
                        <Col span={24}>
                            <InputEmoji
                                size={50}
                                value={''}
                                onChange={text}
                                cleanOnEnter
                                onEnter={handleOnEnter}
                                placeholder={'Écrire un message'}
                                buttonRef={buttonRefEmoji}

                            />
                        </Col>
                    </Row>
                </Col>
                {/* Right side */}

                <Col span={8} style={{ padding: '2px' }}>
                    <Col span={24} style={{ backgroundColor: 'white', height: '50%', padding: '5px', borderRadius: '5px', border: '1px solid grey' }}>
                        {props.video1}
                        <div style={{ position: 'absolute', top: '5px', color: 'white', left: '11px' }}>{user?.username}</div>
                    </Col>
                    <Col span={24} style={{textAlign:'center', marginTop: '5px', backgroundColor: 'white', height: '50%', padding: '5px', borderRadius: '5px', border: '1px solid grey' }}>
                        {props.userPrivate?.videoEnabled || props.targetPrivate.videoEnabled ? props.video2 :
                            <Image
                            style={{textAlign:'center'}}
                                width={125}
                                src={`${URL}/${props.targetPrivate.avatar}`}
                            />
                        }
                      
                        
                        <div style={{ color: 'black', left: '11px',cursor:'pointer' }}><Button style={{ backgroundColor: 'transparent', margin: '5px 5px 0 0' }} disabled={false} border icon={<StepForwardFilled  style={{color:'green',height:'15px' }}  /> } onClick={()=>alert()}></Button> regarder {props.targetPrivate?.username}</div>
                    </Col>
                </Col>
            </Row>
        </>
    );
}


const mapStateToProps = ({ app, testStore, designPage, user }) => ({ app, testStore, designPage, user });
const mapDispatchToProps = dispatch => ({ dispatch });
export default connect(mapStateToProps, mapDispatchToProps)(PrivateHtmlStructure);
