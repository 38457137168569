import { useState, useEffect,forwardRef } from 'react';
import { connect } from 'react-redux';
import { EventEmitter } from '../../../utility/Emitter';
import { getNotification, APIdeleteNotification } from '../../../../endpoints/notification';
import { Modal, Table, Popconfirm, message, Button } from 'antd';

const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

const ModalNotification = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {
    const [data, setData] = useState([]);

    const handleOk = () => {
        props.setIsModalOpenNotification(false);
    };

    const handleCancel = () => {
        props.setIsModalOpenNotification(false);
    };

    const deleteNotification = async (id) => {
        setData((prevData) => {
            const updatedData = prevData.filter(item => item.id !== id);
            props.setTotalNotification(updatedData.length); // ✅ Utiliser la donnée mise à jour
            EventEmitter().emit('totalNotification', { total: updatedData.length });
            EventEmitter().emit('totalNotificationPanel', { total: updatedData.length });
            return updatedData;
        });
    
        try {
            await APIdeleteNotification({ token: user?.token, deleteId: id });
            message.success('Notification supprimée avec succès !');
        } catch (error) {
            console.error('Erreur lors de la suppression de la notification :', error);
            message.error('Erreur lors de la suppression de la notification.');
        }
    };
    
    const deleteAllNotifications = async () => {
        if (data.length === 0) {
            message.warning("Il n'y a aucune notification à supprimer.");
            return;
        }

        try {
            const ids = data.map(item => item.id);

            // Mise à jour immédiate côté client
            setData([]);

            EventEmitter().emit('totalNotification', { total: 0 });
            EventEmitter().emit('totalNotificationPanel', { total: 0 });

            // Suppression côté serveur
            for (const id of ids) {
                await APIdeleteNotification({ token: user?.token, deleteId: id });
                props.setTotalNotification(0)
            }

            message.success('Toutes les notifications ont été supprimées avec succès !');
        } catch (error) {
            console.error('Erreur lors de la suppression des notifications :', error);
            message.error('Erreur lors de la suppression des notifications.');
        }
    };

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const data = await getNotification({
                    usertoken: user?.token,
                    user: user?.room,
                    Iddb: user?.IdDb
                });

                setData(data.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des notifications :', error);
            }
        };

        fetchNotifications();
    }, [props.isModalOpenNotification]);

    const columns = [
        {
            title: 'cadeau 🎁',
            dataIndex: 'giftName',
            key: 'giftName',
        },
        {
            title: 'de',
            dataIndex: 'sendFromUsername',
            key: 'sendFromUsername',
        },
        {
            title: 'Jeton',
            dataIndex: 'token',
            key: 'token',
        },
        {
            title: 'Date',
            dataIndex: 'date_notification',
            key: 'date_notification',
        },
        {
            title: '',
            dataIndex: '',
            key: 'x',
            render: (_, record) => (
                <Popconfirm
                    title="Êtes-vous sûr de vouloir supprimer cette notification ?"
                    onConfirm={() => deleteNotification(record.id)}
                    okText="Oui"
                    cancelText="Non"
                >
                    <a style={{ color: 'red', cursor: 'pointer' }}>Supprimer</a>
                </Popconfirm>
            ),
        },
    ];

    return (
        <Modal
            width={800}
            title="Notifications"
            open={props.isModalOpenNotification}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Popconfirm
                    title="Voulez-vous vraiment tout supprimer ?"
                    onConfirm={deleteAllNotifications}
                    okText="Oui"
                    cancelText="Non"
                >
                    <Button
                        danger
                        style={{
                            backgroundColor: 'red',
                            color: 'white',
                            border: 'none'
                        }}
                    >
                        Supprimer tout
                    </Button>
                </Popconfirm>,
                <Button type="primary" onClick={handleOk}>Fermer</Button>
            ]}
        >
            <Table dataSource={data} columns={columns} />
        </Modal>
    );
});

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ModalNotification);
