import React, { useState, useEffect, useRef, Fragment, useContext, forwardRef } from 'react';
import { Button, Col, Row, Space, Modal, Input, Select, Avatar, Form, Upload, Switch } from 'antd';
import { Spin } from 'antd';
import { HomeOutlined, UploadOutlined, LockOutlined, LoadingOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp } from '../../../../action/action';
import { updateBot, adminGetAllRoomApi } from '../../../../endpoints/admin'
import { compressImage } from '../../chatUtils/CompressImage'
const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;
const { Option } = Select;

const ModifyBot = forwardRef(({ user, ...props }, ref) => {

    const [spiner, setSpiner] = useState(false)
    const [fileList, setFileList] = useState();
    const [room, setRoom] = useState()
    const [selectedRoom, setSelectedRoom] = useState(null);


    const onFinish = async (values) => {
        try {
            setSpiner(true);
            values.id = props.currentComponentObj.object.id
            await updateBot(values, user?.token, user?.room, user?.socketId);

            // L'attente peut ne pas être nécessaire, en fonction de la logique de votre application
            setTimeout(() => {
                setSpiner(false);
            }, 3000);
        } catch (error) {
            console.error("Une erreur s'est produite lors de l'envoi du formulaire :", error);
            setSpiner(false);
            // Gérer l'erreur comme nécessaire
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const customRequest = ({ file, onSuccess, onError }) => {
        onSuccess(true, file);
    }

    useEffect(() => {

        adminGetAllRoomApi(user?.token, user?.room, user?.socketId).then(data => {
            setRoom(data.room);
        });

    }, []);

    const onChange = (value) => {
        console.log("Room sélectionnée :", value);
        setSelectedRoom(value);
    };

    return (
        <div style={{ width: '100%' }}>
            <Form
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                style={{
                    marginTop: '10px',
                }}

                initialValues={{
                    name: props.currentComponentObj.object.name,
                    message: props.currentComponentObj.object.message,
                    active: props.currentComponentObj.object.active,
                    message_interval: props.currentComponentObj.object.message_interval,
                    roomIndex: props.currentComponentObj.object.roomName

                }}

                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ offset: 0, span: 16 }}
                    label="name"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your title!',
                        },
                    ]}
                >
                    <Input defaultValue={props.currentComponentObj.object.name} />
                </Form.Item>

                <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ offset: 0, span: 16 }}
                    label="message"
                    name="message"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your message!',
                        },
                    ]}
                >
                    <Input defaultValue={props.currentComponentObj.object.message} />
                </Form.Item>

                <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ offset: 0, span: 16 }}
                    label="delais"
                    name="message_interval"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your delais!',
                        },
                    ]}
                >
                    <Input defaultValue={props.currentComponentObj.object.message_interval} />
                </Form.Item>

                <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ offset: 0, span: 16 }}
                    name="roomIndex"
                    label="room"
                >
                    <Select
                        defaultValue={props.currentComponentObj.object.roomName}
                        labelInValue // Permet de stocker un objet { key, label } au lieu d'une simple valeur
                        value={selectedRoom}
                        placeholder="Sélectionnez une room"
                        onChange={(value) => setSelectedRoom({ id: value.key, title: value.label })}
                    >
                        {room?.map((r) => (
                            <Select.Option key={r.id} value={r.id}>
                                {r.title}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>


                <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ offset: 0, span: 16 }}
                    name="active"
                    label="Active" valuePropName={props.currentComponentObj.object.active != 0 ? 'checked' : 'undefined'}
                >
                    <Switch defaultChecked={props.currentComponentObj.object.active == 1} />
                </Form.Item>



                <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 24,
                    }}
                >
                    {
                        spiner
                        &&
                        <Spin
                            indicator={
                                <LoadingOutlined
                                    style={{
                                        fontSize: 24,
                                    }}
                                    spin
                                />
                            }
                        />
                    }
                    <Button type="primary" htmlType="submit" disabled={spiner}>
                        Envoyer
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ModifyBot);
