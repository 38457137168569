import { axios } from "../../../endpoints/axios_custom";

//ne retourne pas de reponse voir code updateClient
export const getAllClient = async (token) => {
    return axios.get(`/getAllClient`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête getAllClient sysop :", error);
            throw error;
        });
}

export const updateClient = async (data, token) => {
    try {
        const response = await axios.post(`/updateClient`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        return response.data; // Retourner les données de la réponse
    } catch (error) {
        console.error("Une erreur s'est produite lors de la requête updateClient sysop :", error);
        throw error;
    }
}

//????? //ne retourne pas de reponse voir code updateClient
export const sysopUpdateRoom = async (data, token) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    return axios
        .post(`/sysopUpdateRoom`, data, { headers })
        .then(({ resp }) => resp)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête updateRoom :", error);
            throw error;
        });
}
