import React, { useEffect, useState } from 'react';
import { Modal, Typography } from 'antd';
import { connect } from 'react-redux';
const { Text } = Typography;

const MessageFromSysop = ({ user, sysopMessage, ...props }) => {

    const [sysopMessageModal, setSysopMessageModal] = useState(false)

    useEffect(()=>{
        if(sysopMessage){
            setSysopMessageModal(true)
        }
       
       },[sysopMessage])

    const handleOk = () => {
        setSysopMessageModal(false);
    };

    const handleCancel = () => {
        setSysopMessageModal(false);
    };

    useEffect(()=>{
        if(sysopMessage){
        }
    },[sysopMessage])
    return (
        <>
            <Modal
                width={600}   // Définir la largeur et la hauteur du modal
                open={sysopMessageModal}
                onOk={handleOk}
                onCancel={handleCancel}
                title={`Message de l'administrateur réseau`}
            >
                {sysopMessage}
            </Modal>
        </>
    );
};

const mapStateToProps = ({ user, message }) => ({ user, message });
export default connect(mapStateToProps)(MessageFromSysop);
