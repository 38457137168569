import ImgCrop from 'antd-img-crop';
import { useState, useEffect, useRef, Fragment, useContext, forwardRef } from 'react';
import { Input, Button, Col, Row, Space, Modal, ColorPicker, Divider, Select, message, Upload, Avatar, Tooltip, Empty } from 'antd';
import { FileGifOutlined, VideoCameraAddOutlined, BoldOutlined, ItalicOutlined, UnderlineOutlined, AudioOutlined, DesktopOutlined, ApiOutlined, UploadOutlined, SettingOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp } from '../../../../action/action';
import { saveUserTemplateInBack, adminSendImageTemplate, adminSendColorTemplate, adminGetAllTemplate, adminGetAllTemplateColor, adminSendLogoTemplate } from '../../../../endpoints/admin'
import { RgbaColorPicker } from "react-colorful";
import ListeUploadedFile from './layout/ListeUploadedFile';
import ModalTemplateTheme from './layout/ModalTemplateTheme';
import SelectTemplate from './SelectTemplate';
import '../../../../css/designAdminPannel.css';

const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

const Design = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {
  const { Option } = Select;
  const [saveName, setSaveName] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenLogo, setIsModalOpenLogo] = useState(false);
  const [isModalTemplateTheme, setIsModalTemplateTheme] = useState(false);
  const [okButtonProps, SetOkButtonProps] = useState(false)
  // choix attribuer a la zone selectionner , une seul dois etre envoyer
  const [selectedOptionColor, setSelectedOptionColor] = useState('');
  const [fileList, setFileList] = useState([]);
  const [colorWithDegrade, setColorWithDegrade] = useState();
  const [simpleColor, setSimpleColor] = useState()
  const [imageSelected, setImageSelected] = useState()
  const [choiceSelected, setChoiceSelected] = useState(null)
  const [serverMessage, setServeurMessage] = useState()
  const [imageTemplateFromServer, setImageTemplateFromServer] = useState([])
  const [themeColor, setThemeColor] = useState()
  const [reload, setReload] = useState(false);

  //zone selectionner
  const [templateTarget, setTemplateTarget] = useState()
  //ref 
  const refMap = {
    middleRef: useRef(),
    center: useRef(),
    userLIst: useRef(),
    bottonBox: useRef(),
    selectionOption: useRef(),
    logo: useRef()
  };

  useEffect(() => {
    if (refMap.middleRef.current && ref.ZoneMiddleComponent.current) {
      const sourceStyle = ref.ZoneMiddleComponent.current.style;
      const targetStyle = refMap.middleRef.current.style;

      for (let i = 0; i < sourceStyle.length; i++) {
        const propertyName = sourceStyle.item(i);
        targetStyle.setProperty(propertyName, sourceStyle.getPropertyValue(propertyName));
      }
    }
  }, [ref]);

  useEffect(() => {

    if (refMap.center.current && ref.scrollContainerRef.current) {
      const sourceStyle = ref.scrollContainerRef.current.style;
      const targetStyle = refMap.center.current.style;

      for (let i = 0; i < sourceStyle.length; i++) {
        const propertyName = sourceStyle.item(i);
        if (propertyName !== 'height') {
          targetStyle.setProperty(propertyName, sourceStyle.getPropertyValue(propertyName));
        }
      }
    }
  }, [ref]);

  useEffect(() => {
    if (refMap.userLIst.current && ref.zoneUserList.current) {
      const sourceStyle = ref.zoneUserList.current.style;
      const targetStyle = refMap.userLIst.current.style;

      for (let i = 0; i < sourceStyle.length; i++) {
        const propertyName = sourceStyle.item(i);

        // Ignore la copie des propriétés "max-width" et "min-width"
        if (propertyName !== 'max-width' && propertyName !== 'min-width') {
          targetStyle.setProperty(propertyName, sourceStyle.getPropertyValue(propertyName));
        }
      }
    }
  }, [ref]);

  useEffect(() => {
    if (refMap.bottonBox.current && ref.bottonBox.current) {
      const sourceStyle = ref.bottonBox.current.style;
      const targetStyle = refMap.bottonBox.current.style;

      for (let i = 0; i < sourceStyle.length; i++) {
        const propertyName = sourceStyle.item(i);
        targetStyle.setProperty(propertyName, sourceStyle.getPropertyValue(propertyName));
      }
    }
  }, [ref]);

  useEffect(() => {
    if (refMap.selectionOption.current && ref.ZoneToolsIcone.current) {
      const sourceStyle = ref.ZoneToolsIcone.current.style;
      const targetStyle = refMap.selectionOption.current.style;

      for (let i = 0; i < sourceStyle.length; i++) {
        const propertyName = sourceStyle.item(i);
        targetStyle.setProperty(propertyName, sourceStyle.getPropertyValue(propertyName));
      }
    }
  }, [ref]);

  useEffect(() => {
    if (refMap.logo.current) {
      const sourceStyle = ref.logo.current.style;
      const targetStyle = refMap.logo.current.style;

      for (let i = 0; i < sourceStyle.length; i++) {
        const propertyName = sourceStyle.item(i);
        targetStyle.setProperty(propertyName, sourceStyle.getPropertyValue(propertyName));
      }
    }
  }, [ref]);

  useEffect(() => {
    adminGetAllTemplateColor(user?.token, user?.room, user?.socketId).then(data => {
    })
      .catch((error) => {
        setServeurMessage(error.error)
      });


    adminGetAllTemplate(user?.token, user?.room, user?.socketId).then(data => {
      setImageTemplateFromServer(data.image)
    })
      .catch((error) => {
        setServeurMessage(error.error)
      });

  }, [selectedOptionColor])

  const showModal = (data) => {

    setIsModalOpen(true);
    setTemplateTarget(data)
  };


  const handleOk = () => {
    const formData = new FormData();
    if (colorWithDegrade) {
      refMap[templateTarget].current.style.backgroundColor = 'transparent'
      refMap[templateTarget].current.style.backgroundImage = 'none'
      refMap[templateTarget].current.style.backgroundImage = `linear-gradient(to right, rgba(${colorWithDegrade?.r}, ${colorWithDegrade?.g}, ${colorWithDegrade?.b}, ${colorWithDegrade?.a}), rgba(0, 0, 0, 0))`
      formData.append(choiceSelected, JSON.stringify(colorWithDegrade));
      formData.append('templateTarget', templateTarget);
    }

    if (simpleColor) {
      refMap[templateTarget].current.style.backgroundColor = 'transparent'
      refMap[templateTarget].current.style.backgroundImage = 'none'
      refMap[templateTarget].current.style.background = simpleColor;
      formData.append(choiceSelected, simpleColor);
      formData.append('templateTarget', templateTarget);
    }

    if (imageSelected) {
      formData.append(choiceSelected, imageSelected);
      formData.append('templateTarget', templateTarget);
    }

    if (fileList.length > 0) {
      alert('send file')
    }

    adminSendColorTemplate(user?.token, user?.room, user?.socketId, formData).then((resp) => {
      setServeurMessage(resp.message)
    });

    resetAllValue()
  }

  const resetAllValue = () => {
    setIsModalOpen(false);
    setFileList([]);
    setColorWithDegrade()
    setSelectedOptionColor(null)
    SetOkButtonProps(true)
    setImageSelected()
  }

  const resetInitialStateColor = () => {
    setFileList([]);
    setColorWithDegrade()
    setSimpleColor()
    SetOkButtonProps(true)
    setImageSelected()
  }

  const handleCancel = () => {
    resetAllValue()
  };

  const handleSelectChange = (value) => {

    setChoiceSelected(value)
    resetInitialStateColor()
    setSelectedOptionColor(value);
  };

  const customRequest = ({ file, onSuccess, onError }) => {
    const formData = new FormData();
    formData.append('file', file);
    adminSendImageTemplate(user?.token, user?.room, user?.socketId, formData).then((resp) => {
      setServeurMessage(resp.msg)
      onSuccess(true, file);
      setChoiceSelected('image')
    })
      .catch((error) => {
        setServeurMessage(error.error)
      });

  }

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const onChangeSimpleColor = (value) => {
    setSimpleColor(value.toHexString())
    SetOkButtonProps(false)
    setChoiceSelected('simpleColor')
  }
  const ColorWithDegrade = (value) => {
    setColorWithDegrade(value)
    SetOkButtonProps(false)
  }

  const fileSelected = (value) => {
    setImageSelected(value)
    setChoiceSelected('fileSelected')
    SetOkButtonProps(false)
  }

  const handleOkLogo = () => {
    setIsModalOpenLogo(false);
  }

  const handleCancelLogo = () => {
    setIsModalOpenLogo(false);
  };

  const showModalLogo = (data) => {
    setIsModalOpenLogo(true);
  };

  const onChangeLogo = ({ fileList: newFileList }) => {
    SetOkButtonProps(false)
    setFileList(newFileList);
  };

  const customRequestLogo = ({ file, onSuccess, onError }) => {
    const formData = new FormData();
    formData.append('file', file);
    adminSendLogoTemplate(user?.token, user?.room, user?.socketId, formData).then((resp) => {
      setServeurMessage(resp.msg)
    })
      .catch((error) => {
        setServeurMessage(error.error)
      });
    onSuccess(true, file);
    setChoiceSelected('image')
  }

  const setSelectedTemplateColor = (themeColor) => {
    setIsModalTemplateTheme(true)
    //open Modal and whait for user respond * are you sure ? YES or NO
    setThemeColor(themeColor)
  }
  const forceReload = () => {
    setReload(prev => !prev); // Inverse la valeur de reload
};

  const saveUserTemplate = () => {
    // Vérifie si saveName n'est pas vide avant d'envoyer la requête
    if (saveName !== "") {
      saveUserTemplateInBack(saveName, user?.token, user?.room)
        .then((resp) => {
          props.setCurrentComponentObj({ name: 'Design' })
          forceReload()
          setSaveName('')
          console.log("Réponse du serveur :", resp);
          // setServeurMessage(resp.msg); // Si tu veux afficher un message à l'utilisateur
        })
        .catch((error) => {
          console.error("Erreur lors de l'enregistrement :", error);
        });
    } else {
      console.warn("Le champ du template est vide !");
    }
  }
  

  return (
    <>
      <ModalTemplateTheme themeColor={themeColor} isModalTemplateTheme={isModalTemplateTheme} setIsModalTemplateTheme={setIsModalTemplateTheme} />
      <Row style={{}}>
        <Col style={{ textAlign: 'center', paddingTop: '10px', backgroundColor: 'white', color: 'black' }} span={24} >
          <h2> Design setting</h2>
        </Col >

        <Col span={4}>
          <Row style={{ paddingBottom: '15px' }}>
            <Col span={8}>
              <Button onClick={() => setSelectedTemplateColor('red')} style={{ backgroundColor: 'red' }}></Button>
            </Col>
            <Col style={{ textAlign: 'left' }} span={14}>
              Rouge
            </Col>
          </Row>
        </Col>

        <Col span={4}>
          <Row>
            <Col span={8}>
              <Button onClick={() => setSelectedTemplateColor('blue')} style={{ backgroundColor: 'blue' }}></Button>
            </Col>
            <Col style={{ textAlign: 'left' }} span={14}>
              Bleu clair
            </Col>
          </Row>
        </Col>

        <Col span={4}>
          <Row>
            <Col span={8}>
              <Button onClick={() => setSelectedTemplateColor('lavender')} style={{ backgroundColor: 'lavender' }}></Button>
            </Col>
            <Col style={{ textAlign: 'left' }} span={14}>
              Lavende
            </Col>
          </Row>
        </Col>

        <Col span={4}>
          <Row>
            <Col span={8}>
              <Button onClick={() => setSelectedTemplateColor('green')} style={{ backgroundColor: 'green' }}></Button>
            </Col>
            <Col style={{ textAlign: 'left' }} span={14}>
              Vert
            </Col>
          </Row>
        </Col>

        <Col span={4}>
          <Row>
            <Col span={8}>
              <Button onClick={() => setSelectedTemplateColor('black')} style={{ backgroundColor: 'black' }}></Button>
            </Col>
            <Col style={{ textAlign: 'left' }} span={14}>
              Noir
            </Col>
          </Row>
        </Col>

        <Col span={4}>
          <Row>
            <Col span={8}>
              <Button onClick={() => setSelectedTemplateColor('beige')} style={{ backgroundColor: 'beige' }}></Button>
            </Col>
            <Col style={{ textAlign: 'left' }} span={14}>
              beige
            </Col>
          </Row>
        </Col>

        <Col span={4}>
          <Row>
            <Col span={8}>
              <Button onClick={() => setSelectedTemplateColor('christmas')} style={{ backgroundColor: 'grey' }}></Button>
            </Col>
            <Col style={{ textAlign: 'left' }} span={14}>
              Christmas
            </Col>
          </Row>
        </Col>

        <Col span={4}>
          <Row>
            <Col span={8}>
              <Button onClick={() => setSelectedTemplateColor('easter')} style={{ backgroundColor: 'yellow' }}></Button>
            </Col>
            <Col style={{ textAlign: 'left' }} span={14}>
              Paque
            </Col>
          </Row>
        </Col>
      </Row >
      <Row>
        
          <SelectTemplate reload={reload} setReload={setReload}/>
        
        </Row>
      <Row ref={refMap['middleRef']} style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px', border: '1px solid #000', cursor: 'pointer' }}>

        <Tooltip title="Changer le logo">
          <Col style={{

          }} ref={refMap['logo']} span={7} onClick={showModalLogo} >
          </Col>
        </Tooltip>

        <Tooltip title="Changer la couleur de cette zone">
          <Col span={4} onClick={() => showModal('middleRef')}>
            __
          </Col>
        </Tooltip>
        <Col style={{}} span={4} onClick={() => alert('setting')}>
          <Button
            style={{
              margin: 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%'
            }}
            border>{user?.username}
            <SettingOutlined />
          </Button>
        </Col>
        <Tooltip title="Changer la couleur de cette zone">
          <Col span={4} onClick={() => showModal('middleRef')}>__
          </Col>
        </Tooltip>
        <Col>
          <Row style={{ height: '100%' }}  >
            <Col style={{ height: '100%' }} span={6} onClick={() => alert('icone')} >
              <Button style={{

              }} icon={<VideoCameraAddOutlined />} />
            </Col>
            <Col span={6} onClick={() => alert('icone')} >
              <Button style={{

              }}
                icon={<AudioOutlined style={{ color: 'black' }} />} />
            </Col>
            <Col span={6} onClick={() => alert('icone')} >
              <Button style={{

              }}
                icon={<DesktopOutlined style={{ color: 'black' }} />} />
            </Col>
            <Col span={6} onClick={() => alert('icone')} >
              <Button style={{
                marginRight: '10px'

              }}
                icon={<ApiOutlined style={{ color: 'black' }} />} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>

        <Col span={18} >
          <Row>
            <Tooltip title="salon couleur">
              <Col className='border mon-element' span={4}>
                Votre salon X
              </Col>
            </Tooltip>

            <Col style={{ border: '1px solid grey', borderRadius: '5px 5px 5px 5px' }} span={1}>
              +
            </Col>
          </Row>
        </Col>
        <Col style={{ borderRadius: '5px 5px 5px 5px', }} span={6} >
          <Row className='border mon-element' >
            <Tooltip title="Changer la couleur background ">
              <Col span={12}>Salon</Col>
            </Tooltip>
            <Col span={12}>Utilisateur</Col>
          </Row>
        </Col>
      </Row>
      <Row >
        <Col className='border mon-element' ref={refMap['center']} style={{ cursor: 'pointer' }} span={20} >
          <Row style={{ paddingTop: '5px' }}>
            <Col style={{}} span={2}>
              <Avatar
                style={{
                  backgroundColor: '#87d068',
                  marginTop: '5px'
                }}
                src={`${URL}/${user.avatar}`}
              />
            </Col>
            <Col style={{ textAlign: 'left', fontSize: '12px' }} span={14}>
              <Tooltip title="Username">
                <Col className=' mon-element' onClick={() => alert('username')}>
                  {user?.username} :
                </Col>
              </Tooltip>
              <Tooltip title="Changer la couleur du texte">
                <Col style={{ fontSize: '12px' }} onClick={() => alert('Message texte from user')} span={24}>
                  Ici vous pouvez changer vos couleurs :)
                </Col>
              </Tooltip>
            </Col>
            <Tooltip title="Changer la couleur du texte">
              <Col style={{ alignSelf: 'flex-end', fontSize: '10px', textAlign: 'right' }} onClick={() => alert('Moments')} span={9}>
                il y a quelques secondes
              </Col>
            </Tooltip>
            <Col style={{ alignSelf: 'flex-end', textAlign: 'right' }} onClick={() => alert('setting icone')} span={1}>
              <SettingOutlined />
            </Col>
            <Tooltip title="Changer la couleur du background">
              <Col className='mon-element' style={{ height: '330px' }} onClick={() => showModal('center')} span={24}>
              </Col>
            </Tooltip>
          </Row>
          <Row className='border mon-element'>
            <Col ref={refMap['bottonBox']} onClick={() => showModal('bottonBox')} style={{ height: '60px', cursor: 'pointer' }} span={24} >
            </Col>
            <Col className='border mon-element' ref={refMap['selectionOption']} onClick={() => showModal('selectionOption')} style={{ position: 'absolute', left: '5px', bottom: '50px', cursor: 'pointer', backgroundColor: 'white', margin: '5px', padding: '5px' }} span={8} >
              <BoldOutlined style={{ fontSize: '20px' }} />
              <ItalicOutlined style={{ fontSize: '20px' }} />
              <UnderlineOutlined style={{ fontSize: '20px' }} />
              <FileGifOutlined style={{ fontSize: '20px' }} />
              <ColorPicker
                style={{ height: '20px' }}
              />
            </Col>
            <Col className='border' style={{ paddingLeft: '15px', color: 'grey', textAlign: 'left', borderRadius: '15px', left: '10px', bottom: '20px', position: 'absolute', height: '30px', cursor: 'pointer', backgroundColor: 'white', marginLeft: '15px', width: '85%' }} span={20} >
              Écrire un message
            </Col>
          </Row>
        </Col>
        <Tooltip title="Changer la couleur de la liste username">
          <Col className='border mon-element' ref={refMap['userLIst']} onClick={() => showModal('userLIst')} style={{ cursor: 'pointer', height: '480px' }} span={4} >
            <Row style={{ margin: '10px 0 0 5px' }}>
              <Col span={4}>
                <Avatar
                  style={{
                    backgroundColor: '#87d068',
                  }}
                  src={`${URL}/${user.avatar}`}
                />
              </Col >
              <Col style={{ textAlign: 'left', padding: '4px 10px 0 5px' }} span={20}>
                {user?.username}
              </Col>
            </Row>
          </Col>
        </Tooltip>
        <Row>
          <Col>
            <Tooltip title="Faire une sauvegarde de votre template ">
              <Button onClick={() => saveUserTemplate()} style={{ marginTop: '5px' }}>
                Sauvegarder
              </Button>
            </Tooltip>
          </Col>
          <Col style={{ paddingTop: '5px' }}>
         
            <Input
              value={saveName}
              onChange={(e) => setSaveName(e.target.value)}
              placeholder="Le nom de votre template"
            />
          </Col>
        </Row>
      </Row>

      <Modal okButtonProps={{ disabled: okButtonProps }} okText="Valider" title={`Editeur de couleur - ${templateTarget && templateTarget.toUpperCase()} -`} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <p>Choisir l'option pour la zone selectionner...</p>

        <Select style={{ width: 200 }} value={selectedOptionColor} onChange={handleSelectChange}>
          <Option value="selectImage">Image de fond</Option>
          <Option value="selectColor">Couleur de fond</Option>
          <Option value="selectColorDegrade">Couleur Degradé de fond</Option>
          <Option value="sendImage">Envoyer image</Option>
        </Select>

        {selectedOptionColor === "selectColor" && (
          <Row style={{ paddingTop: '50px' }}>
            <ColorPicker onChange={onChangeSimpleColor} size={'large'} />
            <Divider />
          </Row>
        )
        }

        {selectedOptionColor === "selectImage" && (
          <Row style={{ paddingTop: '50px' }}>
            <ListeUploadedFile setSelectedOptionColor={setSelectedOptionColor} setChoiceSelected={setChoiceSelected} imageTemplateFromServer={imageTemplateFromServer} fileSelected={fileSelected} />
          </Row>
        )
        }

        {selectedOptionColor === "selectColorDegrade" && (
          <Row style={{ paddingTop: '50px' }}>
            <Col span={12} >
              <RgbaColorPicker color={colorWithDegrade} onChange={ColorWithDegrade} />
            </Col>
            <Col span={12} >
              <div style={{ width: '200px', height: '200px', backgroundImage: `linear-gradient(to right, rgba(${colorWithDegrade?.r}, ${colorWithDegrade?.g}, ${colorWithDegrade?.b}, ${colorWithDegrade?.a}), rgba(0, 0, 0, 0))` }}>
              </div>
              <p>Couleur dégradé sélectionnée:</p>
            </Col>
            <Divider />
          </Row>
        )}

        {selectedOptionColor === "sendImage" && (
          <Row style={{ paddingTop: '50px' }}>
            <Upload
              customRequest={customRequest}
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
            {serverMessage}
            <Divider />
          </Row>
        )
        }
      </Modal>

      <Modal okButtonProps={{ disabled: okButtonProps }} okText="fermer" title={`Envoyer un logo`} open={isModalOpenLogo} onOk={handleOkLogo} onCancel={handleCancelLogo}>
        <Upload
          customRequest={customRequestLogo}
          action=""
          listType="picture-card"
          fileList={fileList}
          onChange={onChangeLogo}
          onPreview={onPreview}
        >
          {fileList.length < 1 && '+ Upload'}
        </Upload>
        {serverMessage}
      </Modal>
    </>
  );
})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Design);