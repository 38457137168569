import { useState, useEffect, forwardRef, useContext } from 'react';
import { connect } from 'react-redux';
import { EventEmitter } from '../../../utility/Emitter'
import { SocketContext } from '../../SocketContext';

const LOCATION_PHP_LUNA_FILE = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_PHP_FILE_PLAYER_DEV : process.env.REACT_APP_URL_PHP_FILE_PLAYER_PROD;

const Player = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {

   const socket = useContext(SocketContext);
   const [shoutcast, setShoutcast] = useState(null);
   const [update, forceUpdate] = useState(new Date());

   const emitterMessage = EventEmitter().subscriber('togglePlayer', async (/* none */) => {
      try {
         forceUpdate(new Date())
      } catch (err) {
         console.error(err);
      }
   });

   useEffect(() => {
      if (socket) {
          socket.on('updateShoutcast', updateShoutcast);
          socket.on('reloadPlayer', updateShoutcast);
      }
  
      setShoutcast(props.room);
  
      // 🔹 Nettoyage des écouteurs lors du démontage ou si `socket` change
      return () => {
          if (socket) {
              socket.off('updateShoutcast', updateShoutcast);
              socket.off('reloadPlayer', updateShoutcast);
          }
      };
  }, [socket]);
  
   const updateShoutcast = (data) => {
      forceUpdate(new Date())
   }

   return (
      <iframe key={update} src={`${LOCATION_PHP_LUNA_FILE}?id=1001`} frameBorder="0" allowtransparency="true" width="100%" height="73px" scrolling="no" />
   )
})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Player);