import React, { useRef, useEffect, useContext, useState } from 'react';
import { Row, Col, Modal } from 'antd';
import { connect } from 'react-redux';
import { SocketContext } from '../../../../components/tchat/SocketContext';
import ModalWebcam from '../ModalWebcam';

const ShowWebcam = ({ userDataFromBack }) => {
    const socket = useContext(SocketContext);
    const videoRefs = useRef({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalTrack, setModalTrack] = useState(null);

    const showModal = () => {
        setModalTrack(userDataFromBack._track);
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
        setModalTrack(null);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setModalTrack(null);
    };

    useEffect(() => {
        if (userDataFromBack && userDataFromBack._track) {
            const videoElement = videoRefs.current[userDataFromBack.id];
            if (videoElement && userDataFromBack._track instanceof MediaStreamTrack) {
                const mediaStream = new MediaStream([userDataFromBack._track]);
                console.log(`Assigning stream to video element with id: ${userDataFromBack.id}`);
                videoElement.srcObject = mediaStream;
                videoElement.onloadedmetadata = () => {
                    videoElement.play().catch(error => {
                        console.error(`Error playing video with id: ${userDataFromBack.id}`, error);
                    });
                };
            } else {
                console.error(`Invalid MediaStreamTrack for cam id: ${userDataFromBack.id}`);
            }
        }
    }, [userDataFromBack]);


    return (
        <>
        { userDataFromBack._track &&
 <Col style={{ marginLeft: '5px', height: '100%' }} key={userDataFromBack.id} span={4}>
                <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <p>{userDataFromBack.username}</p>
                    <video
                        onClick={showModal}
                        id={userDataFromBack.id}
                        autoPlay
                        playsInline
                        muted={userDataFromBack.isLocal}
                        ref={(videoElement) => {
                            videoRefs.current[userDataFromBack.id] = videoElement;
                        }}
                        style={{ width: '100px', height: '100px', backgroundColor: 'black' }}
                    ></video>
                </div>
            </Col>
        }
           

            <ModalWebcam handleCancel={handleCancel} handleOk={handleOk} isModalOpen={isModalOpen}  modalTrack={modalTrack} videoRefs={videoRefs} userDataFromBack={userDataFromBack} />
        </>
    );
};

const mapStateToProps = ({ user, message }) => ({ user, message });
export default connect(mapStateToProps)(ShowWebcam);
