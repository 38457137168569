import { useState, useEffect, forwardRef } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Typography, Divider, Row, Col, Popconfirm, message, Input } from 'antd';
import { APIreportMessage } from '../../../../endpoints/notification';

import ModalReplyMessage from './ModalReplyMessage';

const { Title, Text } = Typography;

const ModalUserReadMessage = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {
    const [messageContent, setMessageContent] = useState('');
    const [modalReplyToUser, setModalReplyToUser] = useState(false);

    // Met à jour l'état `messageContent` à chaque changement de message
    useEffect(() => {
        if (props.message) {
            try {
                const parsedMessage = JSON.parse(props.message.message);
    
                if (parsedMessage.message && Array.isArray(parsedMessage.message)) {
                    const formattedMessage = parsedMessage.message.map(msg =>
                        `🗨️ ${msg.edited_from} - (${msg.date} à ${msg.time})\n${msg.content}`
                    ).join('\n\n');  // ➡️ Ajout de 2 sauts de ligne pour aérer
    
                    setMessageContent(
                        `${formattedMessage}\n\n📩 De : @${parsedMessage.fromUsername} ➡️ À : @${parsedMessage.toUsername}`
                    );
                } else {
                    setMessageContent('Aucun message disponible.');
                }
            } catch (error) {
                console.error('Erreur lors du parsing du message :', error);
                setMessageContent('Erreur lors du formatage du message.');
            }
        }
    }, [props.message]);
    

    const handleReply = () => {
        setModalReplyToUser(true);
    };

    const deleteMessage = () => {
        if (window.confirm("Voulez-vous vraiment supprimer ce message ?")) {
            props.setModalReadMessage(false);
        }
    };

    const reportMessage = async () => {
        try {
            await APIreportMessage({
                token: user?.token,
                messageId: props.message?.id,
                reason: 'Contenu inapproprié ou abusif',
                reportedBy: user?.username
            });
            message.success('Message signalé avec succès à l\'administrateur.');
        } catch (error) {
            console.error('Erreur lors du signalement du message :', error);
            message.error('Erreur lors du signalement du message.');
        }
    };

    const close = () => {
        props.setModalReadMessage(false);
    };

    const handleOk = () => {
        props.setModalReadMessage(false);
    };

    return (
        <>
            <Modal
                destroyOnClose={true}
                title={`Message de ${props.message?.from_user || 'Inconnu'}`}
                open={props.modalReadMessage}
                onOk={handleOk}
                onCancel={close}
            >
                <Row>
                    <Col span={24} style={{ display: 'flex', flexDirection: 'column' }}>

                        {/* Titre du message (Lecture seule) */}
                        <Input
                            value={props.message?.subject || 'Sans sujet'}
                            disabled
                            style={{
                                marginBottom: '8px',
                                backgroundColor: '#f5f5f5',
                                color: '#000',
                                fontWeight: 'bold',
                                borderRadius: '8px',
                                borderColor: '#d9d9d9',
                                cursor: 'not-allowed'  // Apparence plus intuitive pour montrer qu'il est non modifiable
                            }}
                        />

                        <Divider />

                        {/* Contenu du message */}
                        <div
                            style={{
                                width: '100%',
                                padding: '10px',
                                backgroundColor: '#f5f5f5',
                                borderRadius: '8px',
                                marginBottom: '16px',
                                whiteSpace: 'pre-wrap'
                            }}
                        >
                            {messageContent}
                        </div>

                        {/* Boutons d'actions */}
                        <div style={{ display: 'flex', gap: '10px' }}>
                            <Button
                                type="primary"
                                style={{
                                    backgroundColor: 'blue',
                                    color: 'white',
                                    border: 'none'
                                }}
                                onClick={handleReply}
                            >
                                Répondre
                            </Button>

                            <Button
                                danger
                                style={{
                                    backgroundColor: 'red',
                                    color: 'white',
                                    border: 'none'
                                }}
                                onClick={deleteMessage}
                            >
                                Supprimer
                            </Button>

                            <Popconfirm
                                title="Voulez-vous vraiment signaler ce message ?"
                                onConfirm={reportMessage}
                                okText="Oui"
                                cancelText="Non"
                            >
                                <Button
                                    style={{
                                        backgroundColor: 'orange',
                                        color: 'white',
                                        border: 'none'
                                    }}
                                >
                                    Dénoncer
                                </Button>
                            </Popconfirm>
                        </div>
                    </Col>
                </Row>
            </Modal>

            {/* Modal de réponse */}
            <ModalReplyMessage
                modalReplyToUser={modalReplyToUser}
                setModalReplyToUser={setModalReplyToUser}
                message={props.message}
            />
        </>
    );
});

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ModalUserReadMessage);
